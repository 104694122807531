import { serverHost } from '../../../config'
import ApiService from '../../../services/api-service'
import { StudentCourseServiceInterface } from './interfaces/student-course-service-interface'
import CourseInfoV2Dto from '../models/service/CourseInfoV2Dto'
import ModuleDto from '../models/service/ModuleDto'

const Url = {
  ROOT: `${serverHost}/api/v2/student/course`,
}

const requestStudentCourseController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const StudentCourseService: StudentCourseServiceInterface = class StudentCourseService {
  static getStudentCourseByCourseId = (courseId: number) => {
    return requestStudentCourseController<CourseInfoV2Dto>(`${courseId}`)
  }

  static getStudentCourseModuleByCourseId = (courseId: number) => {
    return requestStudentCourseController<ModuleDto[]>(`${courseId}/modules`)
  }
}
