import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import StudentNotificationsService from '../../../../../../services/student-services/student-notifications-service'
import NotificationModalDialog from './notification-modal-dialog'
import { StudentWebsocketNotificationService } from '../../../../../../services/student-services/student-websocket-notification-service'
import { AuthService } from '../../../../../../services/auth-service'

const service = new StudentNotificationsService()
const websocketService = new StudentWebsocketNotificationService()

const NotificationBlock = () => {
  const { t } = useTranslation()
  const [unimportantNotifications, setUnimportantNotifications] = useState([])
  const [openedNotification, setOpenedNotification] = useState({})
  const [importantNotifications, setImportantNotifications] = useState([])
  const [showedNotificationBlock, setShowedNotificationBlock] = useState(false)

  const currentUserId = AuthService.currentUserValue()?.id

  const onMessageReceive = notificationList => {
    notificationList.forEach(notification => {
      if (notification.important) {
        setImportantNotifications(oldArray => [...oldArray, notification])
      } else {
        setUnimportantNotifications(oldArray => [...oldArray, notification])
      }
    })
  }

  useEffect(() => {
    if (currentUserId) {
      websocketService.init(onMessageReceive, currentUserId)
      return () => {
        websocketService.terminate()
      }
    }
    return () => {}
  }, [])

  useEffect(() => {
    if (importantNotifications.length > 0 && Object.keys(openedNotification).length === 0) {
      const nextNotification = importantNotifications[0]
      setOpenedNotification(nextNotification)
    }
  }, [importantNotifications, openedNotification])

  const handleClickOutSide = event => {
    const notificationBlock = document.getElementById(`notify-message-wrap`)
    const path = event.path || (event.composedPath && event.composedPath())

    if (!path.includes(notificationBlock)) {
      const notifyBtn = document.getElementById(`notify-btn`)

      if (!path.includes(notifyBtn)) {
        setShowedNotificationBlock(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('click', event => {
      handleClickOutSide(event)
    })

    return () => {
      window.removeEventListener('click', event => {
        handleClickOutSide(event)
      })
    }
  }, [])

  const handleOpenNotification = notification => {
    setOpenedNotification(notification)
  }

  const deleteNotification = (notificationArray, setNotificationArray, id) => {
    const index = notificationArray.findIndex(element => element.id === id)
    const newArray = [...notificationArray.slice(0, index), ...notificationArray.slice(index + 1)]
    setNotificationArray(newArray)
    return index
  }

  const handleCloseNotification = notification => {
    const { id } = notification
    if (notification.important) {
      deleteNotification(importantNotifications, setImportantNotifications, id)
    } else {
      deleteNotification(unimportantNotifications, setUnimportantNotifications, id)
    }
    setOpenedNotification({})
    setShowedNotificationBlock(false)
  }

  const setStudentGeneralNotificationAsRead = () => {
    const { id } = openedNotification
    service.setStudentGeneralNotificationAsRead(id).then(() => handleCloseNotification(openedNotification))
  }

  const isNotificationModalOpen = Object.keys(openedNotification).length !== 0
  return (
    <div className="notify-wrap">
      <div
        className="notify-btn"
        id="notify-btn"
        onClick={() => {
          setShowedNotificationBlock(!showedNotificationBlock)
        }}
      >
        <i className="mdi mdi-bell" />
        <span className="notify-counter">{unimportantNotifications.length}</span>
      </div>
      {showedNotificationBlock ? (
        <div
          id="notify-message-wrap"
          className="notify-message-wrap"
          // onMouseLeave={() => handleCloseNotificationBlockAsCursor()}
        >
          {unimportantNotifications.length === 0 ? (
            <div className="notify-message-head">{t('NoNewNotifications')}</div>
          ) : (
            <div className="notify-message-head">Новые уведомления</div>
          )}
          {unimportantNotifications.map(notification => (
            <span className="notify-message" key={notification.id}>
              <span>
                <span className="notify-message-pic">
                  <i className="mdi mdi-school" />
                </span>
                <span className="notify-text-wrap cursor-pointer" onClick={() => handleOpenNotification(notification)}>
                  <span className="message-title">{notification.title}</span>
                </span>
              </span>
              <span
                className="mdi mdi-close cursor-pointer"
                style={{ float: 'right' }}
                onClick={() => {
                  service.setStudentGeneralNotificationAsRead(notification.id).then(() => {
                    setShowedNotificationBlock(false)
                  })
                }}
              />
            </span>
          ))}
        </div>
      ) : null}
      <NotificationModalDialog
        notification={openedNotification}
        isOpen={isNotificationModalOpen}
        onClose={() => setStudentGeneralNotificationAsRead()}
      />
    </div>
  )
}

export default NotificationBlock
