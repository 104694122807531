import React from 'react'
import RectLoader from '../RectLoader'

const ModulesLoader = () => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, moduleIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <table className="lesson-table" key={moduleIndex}>
          <thead>
            <tr>
              <td colSpan={3}>
                <RectLoader backgroundColor="#e3e2e2" foregroundColor="#cbcaca" width={300} height={25} />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="chapter-disabled">
                <RectLoader width={400} height={20} />
              </td>
            </tr>

            <tr>
              <td className="chapter-disabled">
                <RectLoader width={200} height={20} />
              </td>
            </tr>
          </tbody>
        </table>
      ))}
    </>
  )
}

export default ModulesLoader
