import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'

class FakeStudentCourseTaskInfoService {
  sourceURL

  constructor() {
    this.sourceURL = `${serverHost}/api/fake/protect/user/taskInfo`
  }

  sendMentorAnswer = (studentCourseTaskInfoId, studentAnswerId, mentorComment, right) => {
    const queryOption = {
      method: 'POST',
      body: JSON.stringify({
        mentorComment,
        right,
      }),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(
      `${this.sourceURL}/${studentCourseTaskInfoId}/answer/${studentAnswerId}/mentorAnswer`,
      queryOption
    )
  }
}

export default FakeStudentCourseTaskInfoService
