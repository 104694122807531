import React from 'react'
import ContentLoader from '../ContentLoader'

const CourseInfoLoader = () => {
  return (
    <ContentLoader width={1180} height={113}>
      <rect x="0" y="0" rx="4" ry="4" width="700" height="15" />
      <rect x="0" y="20" rx="4" ry="4" width="767" height="15" />
      <rect x="0" y="40" rx="4" ry="4" width="350" height="15" />

      <rect x="800" y="0" rx="4" ry="4" width="40" height="40" />
      <rect x="860" y="0" rx="4" ry="4" width="100" height="15" />
      <rect x="860" y="25" rx="4" ry="4" width="200" height="15" />

      <rect x="800" y="65" rx="4" ry="4" width="40" height="40" />
      <rect x="860" y="65" rx="4" ry="4" width="100" height="15" />
      <rect x="860" y="90" rx="4" ry="4" width="200" height="15" />
    </ContentLoader>
  )
}

export default CourseInfoLoader
