import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'

class UserModuleService {
  sourceURL

  constructor() {
    this.sourceURL = `${serverHost}/api/user/module`
  }

  getIdByCourseIdAndPosition = (courseId, modulePosition) => {
    const queryOption = {
      method: 'GET',
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(
      `${this.sourceURL}/getIdByCourseIdAndPosition/${modulePosition}?courseId=${courseId}`,
      queryOption
    )
  }

  chaptersCount = moduleId => {
    const url = `${this.sourceURL}/${moduleId}/size`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(url, queryOption)
  }
}

export default UserModuleService
