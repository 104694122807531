import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UserProfileService from 'src/services/common-services/user-profile-service'
import UserCommentService from 'src/services/common-services/user-comment-service'
import { AuthService } from 'src/services/auth-service'
import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import { addItemByIndex, deleteItemByIndex } from 'src/utils/ArraysUtils'
import { useReactAlert } from 'src/hooks/useReactAlert'
import CommentsRender from './comments-render'
import { convertResponseImageUrl } from '../../../../../utils/convertResponseImageUrl'
import { UserDto } from '../../../../../model/user-dto/user-dto'
import PreModerCommentsService from '../../../../../services/premoder-comments-service'
import './comments-block.scss'

const profileService = new UserProfileService()

const defaultComment = { id: 0, text: `` }

type CommentsBlockProps = {
  courseTaskId: number
  statusPage: string
  principalRole: RoleEnum
  isCourseComments: boolean
}

export type User = UserDto

export type TaskComment = {
  id: number
  likes: number
  mainComment: boolean | null
  preModerate: boolean
  text: string
  user: User
  date: number
  dependentComments: TaskComment[]
  commentsLikes: User[]
}

export type Avatars = {
  [id: string]: string
}

const CommentsBlock: React.FC<CommentsBlockProps> = ({ courseTaskId, statusPage, principalRole, isCourseComments }) => {
  const { catchErrorAlert } = useReactAlert()
  const commentService = new UserCommentService(isCourseComments)

  const authUser = AuthService.currentUserValue()

  const { t } = useTranslation()
  const [taskComments, setTaskComments] = useState<TaskComment[]>([])
  const [avatars, setAvatars] = useState<Avatars>({})
  const [openedAnswerForm, setOpenedAnswerForm] = useState(0)
  const [editComment, setEditComment] = useState(defaultComment)
  const [showDependsComments, setShowDependsComment] = useState(0)
  const [isLoaded, setIsLoaded] = useState(true)
  const [deleteCommentId, setDeleteCommentId] = useState(0)

  const preModerCommentsService = new PreModerCommentsService()

  const getTaskComments = (currentCourseTaskId: number) => {
    if (currentCourseTaskId !== 0) {
      commentService
        .getTaskComments(currentCourseTaskId)
        .then((comments: TaskComment[]) => setTaskComments(comments))
        .catch(e => catchErrorAlert(e))
    }
  }

  const updateTaskComments = (updatedComment: TaskComment, commentIndex: number) => {
    setTaskComments(prevState => [
      ...prevState.slice(0, commentIndex),
      updatedComment,
      ...prevState.slice(commentIndex! + 1),
    ])
  }

  const handleSubmitComment = (commentId: number, mainIndex: number, mainCommentId?: number) => {
    const isMainComment = taskComments.find((comment: TaskComment) => comment.id === commentId)
    if (isMainComment) {
      const updatedComment = { ...isMainComment, preModerate: !isMainComment.preModerate }
      updateTaskComments(updatedComment, mainIndex)
    } else {
      const mainComment = taskComments.find((comment: TaskComment) => comment.id === mainCommentId)
      const dependentComment = mainComment!.dependentComments.find((comment: TaskComment) => comment.id === commentId)
      const dependentCommentIndex = mainComment!.dependentComments.findIndex(
        (comment: TaskComment) => comment.id === commentId
      )

      const updatedDependentComment = { ...dependentComment, preModerate: !dependentComment?.preModerate }
      const updatedDependentCommentList = [
        ...mainComment!.dependentComments.slice(0, dependentCommentIndex),
        updatedDependentComment,
        ...mainComment!.dependentComments.slice(dependentCommentIndex + 1),
      ]
      const updatedMainComment = { ...mainComment, dependentComments: updatedDependentCommentList } as TaskComment

      updateTaskComments(updatedMainComment, mainIndex!)
    }

    preModerCommentsService.submitById(commentId).catch(error => catchErrorAlert(error))
  }

  const handleDeleteComment = (commentId: number, mainCommentId?: number, mainIndex?: number) => {
    const isMainComment = taskComments.find((comment: TaskComment) => comment.id === commentId)
    if (isMainComment) {
      const updatedComments = taskComments.filter((comment: TaskComment) => comment.id !== commentId)
      setTaskComments(updatedComments)
    } else {
      const mainComment = taskComments.find((comment: TaskComment) => comment.id === mainCommentId)
      const updatedDependedComments = mainComment!.dependentComments.filter(
        (comment: TaskComment) => comment.id === mainCommentId
      )
      const updatedMainComment = { ...mainComment, dependentComments: [...updatedDependedComments] } as TaskComment
      updateTaskComments(updatedMainComment, mainIndex!)
    }

    preModerCommentsService.deleteById(commentId).catch(error => catchErrorAlert(error))
  }

  useEffect(() => {
    getTaskComments(courseTaskId)
  }, [courseTaskId, deleteCommentId])

  useEffect(() => {
    taskComments.forEach(({ user: { id, avatarUrl } }) => {
      setAvatars(prevState => {
        const { ...newAvatars } = prevState
        newAvatars[id] = avatarUrl ? convertResponseImageUrl(avatarUrl) : '/images/avatar.png'
        return newAvatars
      })
    })
  }, [taskComments.length])

  useEffect(() => {
    setIsLoaded(false)
  }, [avatars])

  const addNewComment = (text: string) => {
    commentService.sendComment(courseTaskId, text, 0).then(newComment => {
      setTaskComments([newComment, ...taskComments])
    })
  }

  const updateComment = (text: string, commentId: number) => {
    commentService.updateComment(text, commentId).then(() => {
      const commentIdx = taskComments.findIndex(el => el.id === commentId)
      setTaskComments(
        taskComments.map((taskComment, index) => {
          if (index === commentIdx) {
            return { ...taskComment, text }
          }
          return taskComment
        })
      )
      setEditComment(defaultComment)
    })
  }

  const deleteComment = (commentId: number) => {
    commentService.deleteComment(commentId).then(() => {
      const commentIdx = taskComments.findIndex(el => el.id === commentId)
      if (commentIdx === 0) {
        setTaskComments([...taskComments.slice(1)])
      } else {
        setTaskComments([...taskComments.slice(0, commentIdx - 1), ...taskComments.slice(commentIdx + 1)])
      }
      setDeleteCommentId(commentId)
    })
  }

  const updateAnswerComment = (text: string, commentId: number, mainCommentId: number) => {
    commentService.updateComment(text, commentId).then(() => {
      setTaskComments(
        taskComments.map(taskComment => {
          if (taskComment.id === mainCommentId) {
            const mainComment = taskComment
            const { dependentComments } = mainComment
            return {
              ...mainComment,
              dependentComments: dependentComments.map(dependTaskComment => {
                if (dependTaskComment.id === commentId) {
                  return { ...dependTaskComment, text }
                }
                return dependTaskComment
              }),
            }
          }

          return taskComment
        })
      )

      setEditComment(defaultComment)
    })
  }

  const addAnswerToComment = (text: string) => {
    commentService.sendAnswerToComment(openedAnswerForm, text).then(response => {
      const commentIdx = taskComments.findIndex(el => el.id === openedAnswerForm)
      setTaskComments(
        taskComments.map((taskComment, index) => {
          if (commentIdx === index) {
            const newDependsComments = [...taskComment.dependentComments, response]
            return {
              ...taskComment,
              dependentComments: newDependsComments,
            }
          }
          return taskComment
        })
      )
      setOpenedAnswerForm(0)
    })
  }

  const changeLike = (commentIndex: number) => {
    const targetComment = taskComments[commentIndex]
    if (targetComment && authUser) {
      const userIdx = targetComment.commentsLikes.map(({ id }) => id).indexOf(authUser.id)
      if (userIdx !== -1) {
        commentService.deleteLike(targetComment.id).then(likes => {
          const newTargetComment = {
            ...targetComment,
            likes,
            commentsLikes: deleteItemByIndex(targetComment.commentsLikes, userIdx),
          }
          const newComments = addItemByIndex(
            deleteItemByIndex(taskComments, commentIndex),
            commentIndex,
            newTargetComment
          )
          setTaskComments(newComments)
        })
      } else {
        commentService.putLike(targetComment.id).then(likes => {
          const { email, firstName, lastName, id } = authUser
          const newTargetComment = {
            ...targetComment,
            likes,
            commentsLikes: [...targetComment.commentsLikes, { email, firstName, lastName, id }],
          }
          const newComments = addItemByIndex(
            deleteItemByIndex(taskComments, commentIndex),
            commentIndex,
            newTargetComment
          )
          setTaskComments(newComments)
        })
      }
    }
  }

  const changeAnswerCommentLike = (CommentIndex: number, mainCommentId: number) => {
    const mainCommentIdx = taskComments.findIndex(el => el.id === mainCommentId)
    const mainComment = taskComments[mainCommentIdx]
    if (mainComment && authUser) {
      const { dependentComments } = mainComment
      const targetComment = dependentComments[CommentIndex]
      if (targetComment) {
        const userIdx = targetComment.commentsLikes.map(({ id }) => id).indexOf(authUser.id)
        if (userIdx !== -1) {
          commentService.deleteLike(targetComment.id).then(likes => {
            const newTargetComment = {
              ...targetComment,
              likes,
              commentsLikes: deleteItemByIndex(dependentComments, userIdx),
            }

            const newDependentComments = addItemByIndex(
              deleteItemByIndex(dependentComments, CommentIndex),
              CommentIndex,
              newTargetComment
            )

            const newMainComment = {
              ...mainComment,
              dependentComments: newDependentComments,
            }

            setTaskComments([
              ...taskComments.slice(0, mainCommentIdx),
              newMainComment,
              ...taskComments.slice(mainCommentIdx + 1),
            ])
          })
        } else {
          commentService.putLike(targetComment.id).then(likes => {
            const newTargetComment = {
              ...targetComment,
              likes,
              commentsLikes: [...targetComment.commentsLikes, authUser],
            }
            const newDependentComments = addItemByIndex(
              deleteItemByIndex(dependentComments, CommentIndex),
              CommentIndex,
              newTargetComment
            )

            const newMainComment = {
              ...mainComment,
              dependentComments: newDependentComments,
            }

            setTaskComments([
              ...taskComments.slice(0, mainCommentIdx),
              newMainComment,
              ...taskComments.slice(mainCommentIdx + 1),
            ])
          })
        }
      }
    }
  }

  if (isLoaded) {
    return null
  }

  return (
    <CommentsRender
      changeAnswerCommentLike={changeAnswerCommentLike}
      changeLike={changeLike}
      addNewComment={addNewComment}
      taskComments={taskComments}
      authUser={authUser}
      avatars={avatars}
      setOpenedAnswerForm={setOpenedAnswerForm}
      openedAnswerForm={openedAnswerForm}
      editComment={editComment}
      setEditComment={setEditComment}
      updateComment={updateComment}
      addAnswerToComment={addAnswerToComment}
      showDependsComments={showDependsComments}
      setShowDependsComments={setShowDependsComment}
      updateAnswerComment={updateAnswerComment}
      deleteComment={deleteComment}
      statusPage={statusPage}
      principalRole={principalRole}
      handleDeleteComment={handleDeleteComment}
      handleSubmitComment={handleSubmitComment}
    />
  )
}

export default CommentsBlock
