import TaskDto, { TaskStatus } from '../../models/service/Task/TaskDto'

const useIsSolvedRight = <AnswerType>(
  task: TaskDto | undefined | any,
  answers: AnswerType[] | null,
  isAnswerRight: (answer: AnswerType) => boolean
) => {
  if (!answers || answers.length === 0) return !!(task && task.status === TaskStatus.SolvedRight)
  return answers.find(answer => !isAnswerRight(answer)) === undefined
}

export default useIsSolvedRight
