import { OrderingTaskAnswerDto, OrderingTaskDto } from '../../../../models/service/Task/OrderingTaskDto'
import { OrderingDirectionTaskDto } from '../../../../../../model/direction-model'

const getIsSolved = (
  orderingTask: OrderingTaskDto | OrderingDirectionTaskDto | undefined,
  answeredItems: OrderingTaskAnswerDto[] | undefined
) => {
  return !!(orderingTask && answeredItems && answeredItems.length)
}

export default getIsSolved
