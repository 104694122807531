import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation, withTranslation } from 'react-i18next'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store/rootReducer'
import { useReactAlert } from 'src/hooks/useReactAlert'
import UserNavigationService from '../../../../../services/common-services/user-navigation-service'
import AnimatedProgressBar from '../../../../animated-progress-bar/animated-progress-bar'
import styles from './student-bread-crumbs.module.scss'
import { getCourseProgress } from '../../../../../store/taskSlice'
import NumberUtil from '../../../../../utils/NumberUtil'
import UserCourseService from '../../../../../services/common-services/user-course-service'

import { generateCourseURL } from '../../../../../utils/generateCourseURL'
import { selectCourseType } from '../../../../../store/course-type/course-type.selectors'
import { convertResponseImageUrl } from '../../../../../utils/convertResponseImageUrl'

const taskTypeToString = (taskType: string, translator: (word: string) => string) => {
  switch (taskType) {
    case 'lecture':
      return `${translator('Lecture')}`
    case 'code':
      return `${translator('PracticalTask')}`
    case 'theory':
      return `${translator('TheoreticalTask')}`
    case 'word':
      return `${translator('SecurityQuestion')}`
    case 'ordering':
      return `${translator('OrderTask')}`
    case 'association':
      return `${translator('AssociationTask')}`
    case 'multi_test':
      return `${translator('MultiTestTask')}`
    case 'multi_answer':
      return `${translator('MultiAnswerTask')}`
    case 'gapping':
      return `${translator('GappingTask')}`
    case 'mentorCheckTask':
      return `${translator('TheTaskVerifiedByTheMentor')}`
    case 'review_step':
      return `${translator('GroupReviewByModule')}`
    case 'multi_input':
      return `${translator('MultiInputTask')}`
    default:
      return `${translator('Task')}`
  }
}

const userCourseService = new UserCourseService()

type IconsDto = {
  checked: boolean
  solved: boolean
  taskType: string
  needToResolve: boolean
  video: boolean
}

type TasksHeading = {
  taskType: string
  chapterName: string
  taskHeadingDtos: IconsDto[]
}

interface StudentBreadCrumbsProps {
  tasksHeading: TasksHeading
  coursePosition: number
  modulePosition: number
  chapterPosition: number
  courseTaskPosition: number
  chapterId: number
  courseId: number
  statusPage: string
  studentId: number
  isLoading: boolean
  coursePic: string
}
const StudentBreadCrumbs: React.FC<StudentBreadCrumbsProps> = ({
  tasksHeading = {},
  coursePosition = 0,
  modulePosition = 0,
  chapterPosition = 0,
  courseTaskPosition = 0,
  chapterId = 0,
  courseId = 0,
  statusPage = '',
  studentId = -1,
  isLoading = false,
  coursePic = '',
}) => {
  const { catchErrorAlert } = useReactAlert()
  const courseType = useSelector(selectCourseType)
  const dispatch = useDispatch()
  const [courseName, setCourseName] = useState('')
  const [chapterCount, setChapterCount] = useState(0)
  const [previousModuleChapterCount, setPreviousModuleChapterCount] = useState(0)
  const [moduleCount, setModuleCount] = useState(0)
  const [previousChapterTaskCount, setPreviousChapterTaskCount] = useState(0)
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>()
  const [isNextChapterBlocked, setIsNextChapterBlocked] = useState(false)

  // eslint-disable-next-line id-length
  const { t } = useTranslation()
  const { studentPoints, coursePoints } = useSelector((state: RootState) => state.task)

  useEffect(() => {
    dispatch(getCourseProgress({ coursePosition, studentId, statusPage }))
  }, [coursePosition])

  useEffect(() => {}, [chapterId])
  const progress = useMemo(() => {
    return NumberUtil.getPercentage(studentPoints, coursePoints)
  }, [studentPoints, coursePoints])

  const { taskHeadingDtos: iconsDto = [], taskType: headTaskType, chapterName } = tasksHeading

  useEffect(() => {
    if (courseId !== 0) {
      userCourseService.getCourseName(courseId).then(courseNameData => {
        setCourseName(courseNameData)
        const taskPartTitle =
          modulePosition * chapterPosition * courseTaskPosition !== 0
            ? `. ${taskTypeToString(headTaskType || '', t)} ${modulePosition}.${chapterPosition}.${courseTaskPosition}`
            : ''
        setBreadCrumbsTitle(courseNameData + taskPartTitle)
      })
    }
  }, [courseId])

  useEffect(() => {
    if (!chapterId) {
      return
    }

    UserNavigationService.getChapterNavigationInfoById(chapterId)
      .then(data => {
        setChapterCount(data.currentModuleChapterCount)
        setPreviousModuleChapterCount(data.prevModuleChapterCount)
        setModuleCount(data.moduleCount)
        setPreviousChapterTaskCount(data.taskCountInPrevChapter)
        setIsNextChapterBlocked(data.nextChapterBlocked)
      })
      .catch(err => {
        catchErrorAlert(err)
      })
  }, [chapterId])

  useEffect(() => {
    if (courseTaskPosition !== 0 && headTaskType) {
      setBreadCrumbsTitle(
        `${courseName}. ${taskTypeToString(headTaskType, t)} ${modulePosition}.${chapterPosition}.${courseTaskPosition}`
      )
      document.title = taskTypeToString(headTaskType, t)
    } else {
      document.title = courseName
    }
  }, [courseTaskPosition, tasksHeading])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const prevPageLink = useMemo(() => {
    if (+chapterPosition === 1) {
      return generateCourseURL(
        statusPage !== 'fake'
          ? `/user/courses/${coursePosition}/${+modulePosition -
              1}/${previousModuleChapterCount}/${previousChapterTaskCount}`
          : `/fake/user/${studentId}/courses/${coursePosition}/${+modulePosition -
              1}/${previousModuleChapterCount}/${previousChapterTaskCount}`,
        courseType
      )
    }

    return generateCourseURL(
      statusPage !== 'fake'
        ? `/user/courses/${coursePosition}/${modulePosition}/${+chapterPosition - 1}/${previousChapterTaskCount}`
        : `/fake/user/${studentId}/courses/${coursePosition}/${modulePosition}/${+chapterPosition -
            1}/${previousChapterTaskCount}/`,
      courseType
    )
  }, [chapterPosition, modulePosition, coursePosition, previousModuleChapterCount, previousChapterTaskCount, studentId])

  const nextPageLink = useMemo(() => {
    if (+chapterPosition === +chapterCount) {
      return generateCourseURL(
        statusPage !== 'fake'
          ? `/user/courses/${coursePosition}/${+modulePosition + 1}/1/1`
          : `/fake/user/${studentId}/courses/${coursePosition}/${+modulePosition + 1}/1/1`,
        courseType
      )
    }
    return generateCourseURL(
      statusPage !== 'fake'
        ? `/user/courses/${coursePosition}/${modulePosition}/${+chapterPosition + 1}/1`
        : `/fake/user/${studentId}/courses/${coursePosition}/${modulePosition}/${+chapterPosition + 1}/1`,
      courseType
    )
  }, [chapterPosition, modulePosition, coursePosition, chapterCount, studentId])

  const isFirstChapter = useMemo(() => {
    return +chapterPosition === 1 && +modulePosition === 1
  }, [chapterPosition, modulePosition])

  const isLastChapter = useMemo(() => {
    return +chapterPosition === +chapterCount && +modulePosition === +moduleCount
  }, [chapterPosition, modulePosition, chapterCount, moduleCount])

  const courseLogoClass = useMemo(() => {
    return cn('page-title-course-pic', styles['course-progress-bar'])
  }, [])

  const navigationButtonClass = useMemo(() => {
    return cn({
      'non-clickable': isLoading,
      'current-completed': !isLoading,
    })
  }, [isLoading])

  const isNextChapterAvailable = !isLastChapter && !isNextChapterBlocked

  return (
    <div id="middle_container">
      <div className={`middle-header ${courseTaskPosition !== 0 ? 'lesson-step' : ''}`}>
        <div className="container">
          {coursePic && (
            <div className={courseLogoClass}>
              <AnimatedProgressBar percentage={progress} className={styles['course-progress-bar__spinner']} />
              <img alt="course logo" src={coursePic} />
            </div>
          )}
          <div className="page-title-wrap">
            <div className="breadcrumbs">
              <span className="link">
                <Link
                  to={generateCourseURL(
                    statusPage !== 'fake' ? `/user/courses` : `/fake/user/${studentId}/courses`,
                    courseType
                  )}
                >
                  {t('Courses')}
                </Link>
              </span>
              <span className="link">
                <Link
                  to={generateCourseURL(
                    statusPage !== 'fake'
                      ? `/user/courses/${coursePosition}`
                      : `/fake/user/${studentId}/courses/${coursePosition}`,
                    courseType
                  )}
                >
                  {courseName}
                </Link>
              </span>
              {chapterName && (
                <span className="link">
                  <span>{chapterName}</span>
                </span>
              )}
            </div>
            <div className="page-title-wrap">
              <div className="page-title">{breadCrumbsTitle}</div>
              <div className="page-title-progress" id="page-title-progress" />
            </div>
            <div className="lesson-navigation" id="lesson-navigation">
              {!!iconsDto?.length && !isFirstChapter && (
                <Link className={navigationButtonClass} to={prevPageLink}>
                  <i className="mdi mdi-arrow-left" onClick={scrollToTop} />
                </Link>
              )}

              {iconsDto.map(({ checked, solved, video, taskType, needToResolve }, idx) => {
                const taskPosition = idx + 1
                //TODO специальный костыль Костыль
                const currentTaskPosition: number = parseInt(String(coursePosition ? courseTaskPosition : 0), 10)

                let iconClass = ''
                const iconMdiClass = {
                  lecture: video ? 'mdi-play' : 'mdi-format-align-left',
                  code: 'mdi-code-tags',
                  multi_test: 'mdi-format-list-text',
                  multi_answer: 'mdi-format-list-numbered',
                  multi_input: 'mdi-clipboard-text-outline',
                  theory: 'mdi-format-list-bulleted',
                  ordering: 'mdi-equalizer-outline',
                  gapping: 'mdi-format-line-style',
                  association: 'mdi-arrow-expand-horizontal',
                  word: 'mdi-pencil',
                  mentorCheckTask: 'mdi-code-tags',
                  review_step: 'mdi-account-group',
                }[taskType]

                if (solved && taskPosition === currentTaskPosition) {
                  if (taskType === 'mentorCheckTask' && checked) {
                    iconClass = 'current-right'
                  } else if (taskType === 'mentorCheckTask' && !checked) {
                    iconClass = 'current-onCheck'
                  } else {
                    iconClass = 'current-completed '
                  }
                } else if (solved) {
                  if (taskType === 'mentorCheckTask' && checked) {
                    iconClass = 'right '
                  } else if (taskType === 'mentorCheckTask' && !checked) {
                    iconClass = 'onCheck '
                  } else {
                    iconClass = 'completed '
                  }
                } else if (taskPosition === currentTaskPosition) {
                  if (taskType === 'mentorCheckTask' && checked && !solved) {
                    iconClass = 'current-unRight'
                  } else {
                    iconClass = 'current '
                  }
                } else if (taskType === 'mentorCheckTask' && checked && !solved) {
                  iconClass = 'unRight '
                }
                if (taskType === 'code' && needToResolve) {
                  if (taskPosition === currentTaskPosition) {
                    iconClass = 'current-unRight'
                  } else {
                    iconClass = 'unRight '
                  }
                }
                return (
                  <Link
                    key={`task_${taskPosition}`}
                    title={`${taskTypeToString(taskType, t)} ${modulePosition}.${chapterPosition}.${taskPosition}`}
                    to={generateCourseURL(
                      statusPage !== 'fake'
                        ? `/user/courses/${coursePosition}/${modulePosition}/${chapterPosition}/${taskPosition}`
                        : `/fake/user/${studentId}/courses/${coursePosition}/${modulePosition}/${chapterPosition}/${taskPosition}`,
                      courseType
                    )}
                    className={iconClass}
                  >
                    <i className={`mdi ${iconMdiClass}`} onClick={scrollToTop} />
                  </Link>
                )
              })}

              {!!iconsDto?.length && isNextChapterAvailable && (
                <Link className={navigationButtonClass} to={nextPageLink}>
                  <i className="mdi mdi-arrow-right" onClick={scrollToTop} />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(StudentBreadCrumbs)
