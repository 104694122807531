import { addDays, format, parse } from 'date-fns'

export const dateForState = (date?: string) => {
  return format(date ? new Date(date) : new Date(), 'dd.MM.yyyy')
}
export const dateForInput = (date?: string) => {
  return format(date ? parse(date, 'dd.MM.yyyy', new Date()) : new Date(), 'yyyy-MM-dd')
}
export const dateForInputMinusNDays = (endDate?: number) => {
  return format(addDays(new Date(), -(endDate ?? 7)), 'yyyy-MM-dd')
}
export const dateForStateMinusNDays = (endDate?: number) => {
  return format(addDays(new Date(), -(endDate ?? 7)), 'dd.MM.yyyy')
}
