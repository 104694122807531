import { GappingTaskDto } from '../../../../models/service/Task/GappingTaskDto'

const useCanBeSolved = (gappingTask: GappingTaskDto | undefined, gappingValues: Record<number, string>) => {
  if (!gappingTask) {
    return false
  }

  return gappingTask.taskGappings.every(({ gappingItemId }) => gappingValues[gappingItemId])
}

export default useCanBeSolved
