import CoursePageAdapter, { ResolveCoursePagePathFunction } from './CoursePageAdapter'
import { TaskType } from './models/service/Task/TaskDto'
import { StudentCourseService } from './services/student-course'
import { StudentCourseTaskService } from './services/student-course-task'

class CourseService implements CoursePageAdapter {
  public readonly coursePagePath = '/user/v2/courses/:courseId/:modulePosition?/:chapterPosition?/:taskPosition?'

  public readonly courseDetailsPagePath = '/user/v2/courses/:courseId'

  public readonly taskPagePath = '/user/v2/courses/:courseId/:modulePosition/:chapterPosition/:taskPosition'

  public resolvePath: ResolveCoursePagePathFunction = (
    courseId: number,
    modulePosition?: number,
    chapterPosition?: number,
    taskPosition?: number
  ): string => {
    const courseIdString = courseId.toString()
    const modulePositionString = modulePosition?.toString()
    const chapterPositionString = chapterPosition?.toString()
    const taskPositionString = taskPosition?.toString()

    if (!taskPositionString || !chapterPositionString || !modulePositionString) {
      return this.courseDetailsPagePath.replace(':courseId', courseIdString)
    }

    return this.taskPagePath
      .replace(':courseId', courseIdString)
      .replace(':modulePosition', modulePositionString)
      .replace(':chapterPosition', chapterPositionString)
      .replace(':taskPosition', taskPositionString)
  }

  public getCourseInfo(courseId: number) {
    return StudentCourseService.getStudentCourseByCourseId(courseId)
  }

  public getCourseModules(courseId: number) {
    return StudentCourseService.getStudentCourseModuleByCourseId(courseId)
  }

  public getCourseTaskById(courseId: number, taskId: number, taskType: TaskType): Promise<any> {
    return StudentCourseTaskService.getCourseTask(courseId, taskId, taskType)
  }
}

export default CourseService
