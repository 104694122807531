import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MultiAnswerTaskAnswerDto,
  MultiAnswerTaskDto,
  StudentMultiAnswerTaskQuestionDto,
} from '../../../../models/service/Task/MultiAnswerTaskDto'
import { MultiAnswerDirectionTaskDto } from '../../../../../../model/direction-model'
import useIsSolvedRight from '../../useIsSolvedRight'
import useCanBeSolved from '../../useCanBeSolved'
import useIsSolved from './useIsSolved'
import useResetTaskCallback from '../../useResetTaskCallback'
import useLoadTaskCallback from '../../useLoadTaskCallback'
import mapQuestionAnswersToProbableAnswers from './mapQuestionAnswersToProbableAnswers'
import useSolveTaskCallback from '../../useSolveTaskCallback'
import useIsAnswerCheckedCallback from '../../useIsAnswerCheckedCallback'
import useChangeAnswersCallback from '../../useChangeAnswersCallback'
import useIsQuestionAnswersRightCallback from '../../useIsQuestionAnswersRightCallback'
import useCurrentAnswerSetting from './useCurrentAnswerSetting'
import Spinner from '../../../../../../components/spinner'
import TaskDescription from '../../../../../../components/pages/student/course-page/task-block/task-description'
import Category from '../../Category'
import Test from '../../../../../../components/pages/student/course-page/task-block/test'
import List from '../../List'
import Actions from '../../Actions'
import Result from '../../Result'
import TaskLoading from '../../TaskLoading'

export interface Question {
  questionId: number
  studentItemAnswers: Answer[]
  isRight?: boolean
}

export interface Answer {
  id: number
}

interface Props {
  multiAnswerTask: MultiAnswerTaskDto | MultiAnswerDirectionTaskDto
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<MultiAnswerTaskDto | MultiAnswerDirectionTaskDto>
  onResetTask: () => Promise<any>
  onSolveTask: any
  postResetInterceptor: (val: any, ...args: any[]) => any
}

const MultiAnswerTask: FC<Props> = ({ multiAnswerTask, postResetInterceptor, onResetTask, onSolveTask, loadTask }) => {
  const { t } = useTranslation()

  const { description, probablyAnswers } = multiAnswerTask || {}
  const [questions, setQuestions] = useState<StudentMultiAnswerTaskQuestionDto[]>([])
  const [loading, setLoading] = useState<Partial<Loading>>({})
  const [answers, setAnswers] = useState<Question[]>([])

  const isSolved = useIsSolved(multiAnswerTask)
  const isSolvedRight = useIsSolvedRight(multiAnswerTask, answers, answer => answer.isRight as boolean)
  const canBeSolved = useCanBeSolved(
    multiAnswerTask?.questions,
    answers,
    question => question.questionId,
    answer => answer.questionId,
    answer => answer.studentItemAnswers.length
  )

  useEffect(() => {
    if (multiAnswerTask) {
      setQuestions(multiAnswerTask.questions)
    }
  }, [multiAnswerTask])

  const loadMultiAnswerTask = useLoadTaskCallback(setLoading, loadTask)

  const resetTaskLocal = useResetTaskCallback(setLoading, onResetTask, loadTask, postResetInterceptor, () => {})

  const resetMultiAnswerTask = () => {
    return resetTaskLocal()
  }

  const solveTaskLocal = useSolveTaskCallback(
    multiAnswerTask,
    () => onSolveTask(answers.map(mapQuestionAnswersToProbableAnswers)),
    async skipLoading => loadMultiAnswerTask(skipLoading),
    canBeSolved,
    setLoading
  )

  const solveMultiAnswerTask = () => {
    return solveTaskLocal()
  }

  const isAnswerChecked = useIsAnswerCheckedCallback(
    answers,
    question => question.questionId,
    question => question.studentItemAnswers,
    answer => answer.id
  )
  const changeAnswers = useChangeAnswersCallback(
    answers,
    setAnswers,
    question => question.questionId,
    question => question.studentItemAnswers,
    answer => answer.id,
    (id, questionAnswers) => ({ questionId: id, studentItemAnswers: questionAnswers }),
    id => ({ id })
  )
  const isQuestionAnswersRight = useIsQuestionAnswersRightCallback<Question, StudentMultiAnswerTaskQuestionDto>(
    answers,
    question => question.questionId,
    question => question?.isRight,
    currentQuestion => currentQuestion.questionId
  )

  useCurrentAnswerSetting(multiAnswerTask, setAnswers)

  if (loading.taskLoading) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="task-content">
      <div className="step-content-head">{t('MultiAnswerTask')}</div>
      <TaskDescription description={description} />

      <Category label="Вопросы задачи">
        {/* todo: Удалить типы, когда станет понятно, почему дженерик не резолвит их автоматически */}
        <Test<StudentMultiAnswerTaskQuestionDto, MultiAnswerTaskAnswerDto>
          tests={questions}
          resolveQuestionId={question => question.questionId}
          resolveQuestionText={question => question.questionText}
          resolveQuestionAnswers={_skip => probablyAnswers}
          resolveIsQuestionAnswersRight={isQuestionAnswersRight}
          resolveAnswerId={answer => answer.id}
          resolveAnswerText={(_skip, index) => (index + 1).toString()}
          isChecked={isAnswerChecked}
          onChange={changeAnswers}
          disabled={isSolved}
        />
      </Category>

      <Category label="Варианты ответов">
        <List items={probablyAnswers} resolveItemId={answer => answer.id} resolveItemText={answer => answer.text} />
      </Category>

      <div className="lesson-result-row">
        <Result isSolved={isSolved} isSolvedRight={isSolvedRight} />
        <Actions
          taskResettingLoading={loading.taskResetting}
          taskSolvingLoading={loading.taskSolving}
          canBeSolved={canBeSolved}
          isSolved={isSolved}
          resetTask={resetMultiAnswerTask}
          solveTask={solveMultiAnswerTask}
        />
      </div>
    </div>
  )
}

export interface Loading extends TaskLoading {}

export default MultiAnswerTask
