export enum AnswerStatusDto {
  LAST_ANSWER_RIGHT = 'LAST_ANSWER_RIGHT',
  LAST_ANSWER_WRONG = 'LAST_ANSWER_WRONG',
  NO_ANSWER = 'NO_ANSWER',
  ANSWER_RESET = 'ANSWER_RESET',
}

// Api v1
// resolved: true - answerStatus: ANSWER_RESET
// resolved: false - answerStatus: NO_ANSWER
