import { useEffect } from 'react'
import PromiseUtil from '../../../utils/PromiseUtil'
import CoursePageAdapter from '../CoursePageAdapter'
import CourseInfoV2Dto from '../models/service/CourseInfoV2Dto'
import ModuleDto from '../models/service/ModuleDto'
import { CoursePageLoading } from '../pages/CoursePage'

const useCourse = ({ courseId, pageAdapter, setLoadingByKey, setCourseInfo, setModules }: UseCourseArg) => {
  useEffect(() => {
    const abortController = new AbortController()
    ;(async () => {
      if (!courseId) {
        return
      }

      try {
        setLoadingByKey('courseInfo', true)
        setLoadingByKey('modules', true)

        const getCourseInfoRequest = pageAdapter
          .getCourseInfo(courseId)
          .then(PromiseUtil.throwIfAborted(abortController.signal))
          .then(setCourseInfo)
          .finally(() => setLoadingByKey('courseInfo', false))

        const getCourseModulesRequest = pageAdapter
          .getCourseModules(courseId)
          .then(PromiseUtil.throwIfAborted(abortController.signal))
          .then(setModules)
          .finally(() => setLoadingByKey('modules', false))

        await Promise.all([getCourseInfoRequest, getCourseModulesRequest])
      } catch (e) {
        setCourseInfo(undefined)
        setModules(undefined)
      }
    })()

    return () => abortController.abort()
  }, [courseId])
}

export default useCourse

interface UseCourseArg {
  courseId: number | undefined
  pageAdapter: CoursePageAdapter
  setLoadingByKey: (key: keyof CoursePageLoading, value: boolean) => void
  setCourseInfo: (courseInfo: CourseInfoV2Dto | undefined) => void
  setModules: (modules: ModuleDto[] | undefined) => void
}
