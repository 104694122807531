import {
  StudentGappingResultDto,
  AbstractStudentCourseTaskAnswerDto,
  StudentAssociationTaskResultDto,
  StudentMultiInputResultDto,
  StudentMultiTestItemResultDto,
  StudentMultiAnswerTaskItemResultDto,
} from '../../model/student-model'
import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { OrderingTaskItemDto } from '../../model/task-model/OrderingTaskDto'
import { AssociationTaskStudentAnswerDto } from '../../model/task-model/AssociationTaskDto'
import { MultiInputTaskStudentAnswerDto } from '../../model/task-model/multiInputTaskDto'
import { MultiTestTaskStudentAnswerDto } from '../../model/task-model/MultiTestTaskDto'
import { MultiAnswerTaskStudentAnswerDto } from '../../model/task-model/MultiAnswerTaskDto'
import { GappingTaskStudentAnswerDto } from '../../model/task-model/GappingTaskDto'
import { MentorCheckTaskAnswerType } from '../../model/task-model/MentorCheckTaskDto'

import { HTTPMethods } from '../http-enums-methods'
import {
  FilterDate,
  FilterResult,
  StudentCourseTaskInfoServiceInterface,
} from '../interfaces/student-services/student-course-task-service'

class StudentCourseTaskInfoService implements StudentCourseTaskInfoServiceInterface {
  private readonly sourceURL = `${serverHost}/api/student/taskInfo`

  public getUsersTaskSolutions = <
    StudentCourseTaskAnswerDto extends AbstractStudentCourseTaskAnswerDto & { answerType?: MentorCheckTaskAnswerType }
  >(
    studentCourseTaskId: number,
    filterResult: FilterResult = FilterResult.All,
    filterDate: FilterDate = FilterDate.New
  ) => {
    const url = `${this.sourceURL}/${studentCourseTaskId}/solutions?filterResult=${filterResult}&filterDate=${filterDate}`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<StudentCourseTaskAnswerDto>(url, queryOption)
  }

  public solveCodeCourseTask = (studentCourseTaskId: number, solution: string) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: solution,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}/${studentCourseTaskId}/code`, queryOption)
  }

  public solveTheoryTask = (studentCourseTaskId: number, answerIds: number[]) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: JSON.stringify(answerIds),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}/${studentCourseTaskId}/theory`, queryOption)
  }

  public solveWordTask = (studentCourseTaskId: number, answer: string) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: answer,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}/${studentCourseTaskId}/word`, queryOption)
  }

  public solveOrderingTask = (studentCourseTaskId: number, answers: OrderingTaskItemDto[] | undefined) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<string>(`${this.sourceURL}/${studentCourseTaskId}/ordering`, queryOption)
  }

  public solveAssociationTask = (studentCourseTaskId: number, answers: AssociationTaskStudentAnswerDto) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentAssociationTaskResultDto[]>(
      `${this.sourceURL}/${studentCourseTaskId}/association`,
      queryOption
    )
  }

  public solveMultiInputTask = (studentCourseTaskId: number, answers: MultiInputTaskStudentAnswerDto | undefined) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentMultiInputResultDto[]>(
      `${this.sourceURL}/${studentCourseTaskId}/multiInput`,
      queryOption
    )
  }

  public solveMultiTestTask = (studentCourseTaskId: number, answers: MultiTestTaskStudentAnswerDto | undefined) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentMultiTestItemResultDto[]>(
      `${this.sourceURL}/${studentCourseTaskId}/multiTest`,
      queryOption
    )
  }

  public solveMultiAnswerTask = (studentCourseTaskId: number, answers: MultiAnswerTaskStudentAnswerDto | undefined) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentMultiAnswerTaskItemResultDto[]>(
      `${this.sourceURL}/${studentCourseTaskId}/multiAnswer`,
      queryOption
    )
  }

  public solveGappingTask = (studentCourseTaskId: number, answers: GappingTaskStudentAnswerDto) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentGappingResultDto[]>(
      `${this.sourceURL}/${studentCourseTaskId}/gapping`,
      queryOption
    )
  }

  public sendToCheck = (studentCourseTaskId: number, answer: string) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(
      `${this.sourceURL}/${studentCourseTaskId}/mentorCheckTask?answer=${encodeURI(answer)}`,
      queryOption
    )
  }

  public solveLectureTask = (studentCourseTaskId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<void>(`${this.sourceURL}/${studentCourseTaskId}/solve-lecture`, queryOption)
  }

  public resetTask = (studentCourseTaskId: number) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<void>(`${this.sourceURL}/${studentCourseTaskId}/reset`, queryOption)
  }

  public getLinkForMentor = (studentCourseTaskId: number) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<string>(`${this.sourceURL}/${studentCourseTaskId}/link-to-mentor`, queryOption)
  }

  public cancelTaskCheck = (studentCourseTaskInfoId: string) => {
    const queryOption = {
      method: HTTPMethods.DELETE,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<void>(`${this.sourceURL}/${studentCourseTaskInfoId}/mentorCheckTask`, queryOption)
  }
}

export default StudentCourseTaskInfoService
