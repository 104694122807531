import PointsDto from '../models/service/PointsDto'

const getPercent = ({ current, total }: PointsDto) => {
  if (total === 0) {
    return 0
  }
  return Math.floor((current / total) * 100)
}

export { getPercent }
