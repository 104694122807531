import {
  FilterDate,
  FilterResult,
  StudentCourseTaskInfoServiceInterface,
} from '../../../services/interfaces/student-services/student-course-task-service'
import {
  AbstractStudentCourseTaskAnswerDto,
  StudentAssociationTaskResultDto,
  StudentGappingResultDto,
  StudentMultiAnswerTaskItemResultDto,
  StudentMultiInputResultDto,
  StudentMultiTestItemResultDto,
} from '../../../model/student-model'
import { serverHost } from '../../../config'
import { AuthService } from '../../../services/auth-service'
import ApiService from '../../../services/api-service'
import { HTTPMethods } from '../../../services/http-enums-methods'
import { OrderingTaskItemDto } from '../models/service/Task/OrderingTaskDto'
import { AssociationTaskStudentAnswerDto } from '../models/service/Task/AssociationTaskDto'
import { MultiInputTaskStudentAnswerDto } from '../models/service/Task/MultiInputTaskDto'
import { MultiTestTaskStudentAnswerDto } from '../models/service/Task/MultiTestTaskDto'
import { MultiAnswerTaskStudentAnswerDto } from '../models/service/Task/MultiAnswerTaskDto'
import { GappingTaskStudentAnswerDto } from '../models/service/Task/GappingTaskDto'

class StudentCourseTaskInfoService implements StudentCourseTaskInfoServiceInterface {
  private sourceURL: string

  public constructor(courseId: number) {
    this.sourceURL = `${serverHost}/api/v2/student/course/${courseId}/task`
  }

  public getUsersTaskSolutions = <StudentCourseTaskAnswerDto extends AbstractStudentCourseTaskAnswerDto>(
    studentCourseTaskId: number,
    filterResult: FilterResult = FilterResult.All,
    filterDate: FilterDate = FilterDate.New
  ) => {
    const url = `${this.sourceURL}/${studentCourseTaskId}/solutions?filterResult=${filterResult}&filterDate=${filterDate}`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<StudentCourseTaskAnswerDto>(url, queryOption)
  }

  public solveCodeCourseTask = (studentCourseTaskId: number, solution: string) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: solution,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    const fullUrl = `${this.sourceURL}/${studentCourseTaskId}/code`
    return ApiService.request<string>(fullUrl, queryOption)
  }

  public solveTheoryTask = (studentCourseTaskId: number, answerIds: number[]) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: JSON.stringify(answerIds),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}/${studentCourseTaskId}/theory`, queryOption)
  }

  public solveWordTask = (studentCourseTaskId: number, answer: string) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: answer,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}/${studentCourseTaskId}/word`, queryOption)
  }

  public solveOrderingTask = (studentCourseTaskId: number, answers: OrderingTaskItemDto[] | undefined) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<string>(`${this.sourceURL}/${studentCourseTaskId}/ordering`, queryOption)
  }

  public solveAssociationTask = (studentCourseTaskId: number, answers: AssociationTaskStudentAnswerDto) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentAssociationTaskResultDto[]>(
      `${this.sourceURL}/${studentCourseTaskId}/association`,
      queryOption
    )
  }

  public solveMultiInputTask = (studentCourseTaskId: number, answers: MultiInputTaskStudentAnswerDto | undefined) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentMultiInputResultDto[]>(
      `${this.sourceURL}/${studentCourseTaskId}/multi_input`,
      queryOption
    )
  }

  public solveMultiTestTask = (studentCourseTaskId: number, answers: MultiTestTaskStudentAnswerDto | undefined) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentMultiTestItemResultDto[]>(
      `${this.sourceURL}/${studentCourseTaskId}/multi_test`,
      queryOption
    )
  }

  public solveMultiAnswerTask = (studentCourseTaskId: number, answers: MultiAnswerTaskStudentAnswerDto | undefined) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentMultiAnswerTaskItemResultDto[]>(
      `${this.sourceURL}/${studentCourseTaskId}/multi_answer`,
      queryOption
    )
  }

  public solveGappingTask = (studentCourseTaskId: number, answers: GappingTaskStudentAnswerDto) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentGappingResultDto[]>(
      `${this.sourceURL}/${studentCourseTaskId}/gapping`,
      queryOption
    )
  }

  public sendToCheck = (studentCourseTaskId: number, answer: string) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(
      `${this.sourceURL}/${studentCourseTaskId}/mentorCheckTask?answer=${answer}`,
      queryOption
    )
  }

  public solveLectureTask = (studentCourseTaskId: number) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<void>(`${this.sourceURL}/${studentCourseTaskId}/solve-lecture`, queryOption)
  }

  public resetTask = (studentCourseTaskId: number) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<void>(`${this.sourceURL}/${studentCourseTaskId}/reset`, queryOption)
  }

  public getLinkForMentor = (studentCourseTaskId: number) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<string>(`${this.sourceURL}/${studentCourseTaskId}/link-to-mentor`, queryOption)
  }
}

export default StudentCourseTaskInfoService
