import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { store } from 'react-notifications-component'
import { useTranslation } from 'react-i18next'
import { Col, FormControl, FormFile, Row } from 'react-bootstrap'
import ProfileHeader from './edit-profile-header'
import { days, years } from '../../../../../utils/select-state/select-state-arrays'
import Spinner from '../../../../spinner'
import { studentErrorNotyTemplate, studentSuccessNotyTemplate } from '../../../../../config'
import UserProfileService from '../../../../../services/common-services/user-profile-service'
import { convertResponseImageUrl } from '../../../../../utils/convertResponseImageUrl'

const userProfileService = new UserProfileService()

const EditProfilePage = () => {
  // eslint-disable-next-line id-length
  const { t } = useTranslation()
  const [user, setUser] = useState({})
  const [uploadedImageURL, setUploadedImageURL] = useState('')
  const [loaded, setLoaded] = useState(false)

  const months = [
    { value: 1, name: `${t('JanuaryFull')}` },
    { value: 2, name: `${t('FebruaryFull')}` },
    { value: 3, name: `${t('MarchFull')}` },
    { value: 4, name: `${t('AprilFull')}` },
    { value: 5, name: `${t('MayFull')}` },
    { value: 6, name: `${t('JuneFull')}` },
    { value: 7, name: `${t('JulyFull')}` },
    { value: 8, name: `${t('AugustFull')}` },
    { value: 9, name: `${t('SeptemberFull')}` },
    { value: 10, name: `${t('OctoberFull')}` },
    { value: 11, name: `${t('NovemberFull')}` },
    { value: 12, name: `${t('DecemberFull')}` },
  ]

  const dateToString = (day, month, year) => {
    const nDay = Number(day)
    const nMonth = Number(month)
    const nYear = Number(year)
    return `${nDay < 10 ? `0${nDay}` : nDay}.${nMonth < 10 ? `0${nMonth}` : nMonth}.${nYear}`
  }

  useEffect(() => {
    userProfileService.getUserPrincipal().then(us => {
      setUser(us)
      setLoaded(true)
      if (us.avatarUrl) {
        setUploadedImageURL(convertResponseImageUrl(us.avatarUrl))
      }
    })
  }, [])

  const validate = ({ firstName, lastName }) => {
    const errors = {}
    if (!firstName || firstName === '') {
      errors.firstName = `${t('WriteYourName')}`
    }

    if (!lastName || lastName === '') {
      errors.lastName = `${t('WriteYourLastName')}`
    }

    return errors
  }

  const onSelectNewAvatar = logoFile => {
    const imgUrl = window.URL.createObjectURL(logoFile)
    setUploadedImageURL(imgUrl)
  }
  document.title = `${t('ProfileEditing')}`
  return loaded ? (
    <>
      <ProfileHeader message={t('ProfileEditing')} />
      <div className="edit-profile-wrapper">
        <div className="container" style={{ overflow: 'visible' }}>
          <div className="edit-profile-content" style={{ overflow: 'visible' }}>
            <div className="profile-top clearfix" style={{ overflow: 'visible' }}>
              <Formik
                onSubmit={values => {
                  const userOnSubmit = {
                    id: user.id,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    birthday: dateToString(values.day, values.month, values.year),
                  }
                  userProfileService.updateUserImage(user.id, values.file).then(() => {
                    document.getElementById('user_pic').setAttribute('src', uploadedImageURL)
                  })
                  userProfileService
                    .updateUser(userOnSubmit)
                    .then(() => {
                      store.addNotification({
                        ...studentSuccessNotyTemplate,
                        message: `${t('YourDetailsHaveBeenUpdated')}`,
                      })
                    })
                    .catch(() => {
                      store.addNotification({
                        ...studentErrorNotyTemplate,
                        message: `${t('ErrorUpdatingData')}`,
                      })
                    })
                }}
                validate={validate}
                initialValues={{
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                  day: user.birthday.split('.')[0],
                  month: user.birthday.split('.')[1],
                  year: user.birthday.split('.')[2],
                  file: {},
                }}
              >
                {({ values, handleChange, handleSubmit, setFieldValue, errors }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={8} className="profile-left-wrap">
                        <Row className="name-row">
                          <Col xs={6} className="form-field">
                            <label className="field-label">{t('YourName')}:</label>
                            <FormControl
                              name="firstName"
                              required="required"
                              pattern="\D+[^!@#$%^&*()_]"
                              title={t('TheNameMustNotContainNumbersAndExtraCharacters')}
                              value={values.firstName}
                              onChange={handleChange}
                            />
                            {errors.firstName && <div style={{ color: '#ff0000' }}>{errors.firstName}</div>}
                          </Col>

                          <Col xs={6} className="form-field">
                            <label className="field-label">{t('Surname')}:</label>
                            <FormControl
                              name="lastName"
                              required="required"
                              pattern="\D+[^!@#$%^&*()_]"
                              title={t('SurnameMustNotContainNumbersAndExtraCharacters')}
                              value={values.lastName}
                              onChange={handleChange}
                            />
                            {errors.lastName && <div style={{ color: '#ff0000' }}>{errors.lastName}</div>}
                          </Col>
                        </Row>

                        <Row className="bottom-row">
                          <Col xs={6} className="form-field">
                            <label htmlFor="birthday" className="field-label">
                              {t('Birthday')}:
                            </label>
                            <div className="form-inline" id="birthday">
                              <FormControl
                                as="select"
                                id="day"
                                name="day"
                                style={{ width: '20%', height: '52px' }}
                                onChange={handleChange}
                              >
                                <option value="not selected" disabled>
                                  День
                                </option>
                                {days.map(day => {
                                  if (day === Number(values.day)) {
                                    return (
                                      <option selected="selected" key={day} value={day}>
                                        {day}
                                      </option>
                                    )
                                  }
                                  return (
                                    <option key={day} value={day}>
                                      {day}
                                    </option>
                                  )
                                })}
                              </FormControl>
                              <FormControl
                                as="select"
                                id="month_select"
                                name="month"
                                style={{ height: '52px', width: '52%' }}
                                onChange={handleChange}
                              >
                                <option value="not selected" disabled>
                                  {t('Month')}
                                </option>
                                {months.map(month => {
                                  const realMonth =
                                    Number(values.day) === 31 ? Number(values.month) + 1 : Number(values.month)
                                  if (month.value === realMonth) {
                                    return (
                                      <option selected="selected" key={month.value} value={month.value}>
                                        {month.name}
                                      </option>
                                    )
                                  }
                                  return (
                                    <option key={month.value} value={month.value}>
                                      {month.name}
                                    </option>
                                  )
                                })}
                              </FormControl>
                              <FormControl
                                as="select"
                                id="year"
                                name="year"
                                style={{ height: '52px', width: '25%' }}
                                onChange={handleChange}
                              >
                                <option value="not selected" disabled>
                                  Год
                                </option>
                                {years.map(year => {
                                  if (year === Number(values.year)) {
                                    return (
                                      <option selected="selected" key={year} value={year}>
                                        {year}
                                      </option>
                                    )
                                  }
                                  return (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  )
                                })}
                              </FormControl>
                            </div>
                          </Col>
                          <Col xs={6} className="form-field">
                            <label className="field-label">e-mail:</label>
                            <FormControl
                              name="email"
                              disabled="disabled"
                              title={t('ContactYourCuratorToChangeYourEmailAddress')}
                              value={values.email}
                            />
                          </Col>
                        </Row>

                        <div className="profile-bottom">
                          <button
                            className="save-changes-btn"
                            type="submit"
                            style={{
                              cursor: 'pointer',
                              marginRight: 'auto',
                              marginLeft: 'auto',
                            }}
                          >
                            {t('SaveChanges')}
                          </button>
                        </div>
                      </Col>

                      <Col xs={4}>
                        <div className="profile-avatar-wrap">
                          <div className="profile-avatar-pic">
                            <img
                              id="course_pic"
                              src={uploadedImageURL === '' ? '/images/profile-big-photo.png' : uploadedImageURL}
                              alt={t('UserImage')}
                            />
                          </div>

                          <div>
                            <label
                              style={{ display: 'inline-block' }}
                              htmlFor="course_pic_input"
                              className="change-profile-pic"
                            >
                              {t('ChangeAvatar')}
                            </label>
                            <FormFile
                              style={{ visibility: 'hidden' }}
                              id="course_pic_input"
                              name="file"
                              accept="image/gif,image/jpeg,image/png"
                              onChange={event => {
                                const file = event.currentTarget.files[0]
                                setFieldValue('file', file)
                                onSelectNewAvatar(file)
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="task-loader">
      <Spinner />
    </div>
  )
}

export default EditProfilePage
