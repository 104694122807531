import { TaskIndices } from '../models/util/Indices'
import { TaskLocation } from '../models/util/Location'

class PositionUtil {
  public static convertPositionToIndex(position: number) {
    return position - 1
  }

  public static convertIndexToPosition(index: number) {
    return index + 1
  }

  public static convertTaskLocationToTaskIndices(location: TaskLocation): TaskIndices {
    return {
      moduleIndex: PositionUtil.convertPositionToIndex(location.modulePosition),
      chapterIndex: PositionUtil.convertPositionToIndex(location.chapterPosition),
      taskIndex: PositionUtil.convertPositionToIndex(location.taskPosition),
    }
  }
}

export default PositionUtil
