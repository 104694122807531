import { AssociationTaskDto } from '../../../../models/service/Task/AssociationTaskDto'

const useIsSolved = (associationTask: AssociationTaskDto | undefined) => {
  return !!(
    associationTask &&
    associationTask.studentAssociationResults &&
    associationTask.studentAssociationResults.length
  )
}

export default useIsSolved
