import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'

class UserChapterService {
  sourceURL

  constructor() {
    this.sourceURL = `${serverHost}/api/user/chapter`
  }

  getIdByModuleIdAndPosition = (moduleId, chapterPosition) => {
    const queryOption = {
      method: 'GET',
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(
      `${this.sourceURL}/getIdByModuleIdAndPosition/${chapterPosition}?moduleId=${moduleId}`,
      queryOption
    )
  }

  courseTasksCount = chapterId => {
    const url = `${this.sourceURL}/${chapterId}/size`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(url, queryOption)
  }
}

export default UserChapterService
