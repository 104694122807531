import { TaskHeadingDto } from '../../../model/student-model'
import { serverHost } from '../../../config'
import { AuthService } from '../../auth-service'
import ApiService from '../../api-service'

class FakeOpenStudentCourseService {
  openSourceURL: string

  constructor() {
    this.openSourceURL = `${serverHost}/api/fake/user/course`
  }

  getTasksHeading = (
    coursePosition: number,
    modulePosition: number,
    chapterPosition: number,
    courseTaskPosition: number,
    studentId: number
  ) => {
    const url = `${this.openSourceURL}/${coursePosition}/${modulePosition}/${chapterPosition}/${courseTaskPosition}?studentId=${studentId}`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<TaskHeadingDto>(url, queryOption)
  }

  getCourseIdByPos = (position: number, studentId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<number>(
      `${this.openSourceURL}/getIdByPos/${position}?studentId=${studentId}`,
      queryOption
    )
  }

  loadEnrollingState = (courseId: number, studentId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<boolean>(
      `${this.openSourceURL}/${courseId}/is-enrolling?studentId=${studentId}`,
      queryOption
    )
  }

  loadModuleViewDto = (courseId: number, studentId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ModuleViewDto[]>(
      `${this.openSourceURL}/${courseId}/module-dto?studentId=${studentId}`,
      queryOption
    )
  }

  getAvailableCourses = (studentId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<CourseDTO[]>(`${this.openSourceURL}/all-views?studentId=${studentId}`, queryOption)
  }

  getLastCourse = (studentId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<CourseDTO>(`${this.openSourceURL}/last-course?studentId=${studentId}`, queryOption)
  }

  /**
   * @returns {Promise<any>}
   */
  getCourseByPosition(coursePosition: number, studentId: number) {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<AllCoursesView>(
      `${this.openSourceURL}/views/${coursePosition}?studentId=${studentId}`,
      queryOption
    )
  }
}

export default FakeOpenStudentCourseService

interface AllCoursesView {
  id: number
  directionId: number
  name: string
  position: number
  percent: number
  prevCoursePercent: number
  coursePoints: number
  studentPoints: number
  isAvailable: boolean
}

interface ChapterViewDto {
  allTaskAmount: number
  chapterId: number
  moduleId: number
  name: string
  position: number
  solvedTaskAmount: number
  blocked: boolean
}

interface ModuleViewDto extends ChapterViewDto {
  chapterViewDtos: ChapterViewDto[]
  description: string
}

export interface CourseDTO extends AllCoursesView {
  available: boolean
}
