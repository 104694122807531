import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import StatusPage from '../../../../../model/status-page-enum'
import FakeProtectedStudentCourseTaskService from '../../../../../services/fake-page-services/fake-protected-student-course-task-service'

export const isCodeTaskExemplarySolutionAvailableCallback = (principalRole: RoleEnum | undefined): (() => boolean) => {
  return () => {
    return principalRole === 'MENTOR'
  }
}

export const isCodeTaskSolutionHistoryAvailableCallback = (
  principalRole: RoleEnum | undefined,
  statusPage: StatusPage | string,
  isTaskAvailableForPrincipal?: boolean
): (() => boolean) => {
  return () => {
    return isTaskAvailableForPrincipal || statusPage === StatusPage.Default || principalRole !== RoleEnum.PAY_STUDENT
  }
}

export const isCodeTaskMentorCommentAvailableCallback = (
  statusPage: StatusPage | string,
  isTaskAvailableForPrincipal?: boolean
): (() => boolean) => {
  return () => {
    return isTaskAvailableForPrincipal || statusPage !== StatusPage.Fake
  }
}

export const isCodeTaskSessionStorageAvailableCallback = (statusPage: StatusPage | string): (() => boolean) => {
  return () => {
    return statusPage !== 'fake'
  }
}

export const getSessionStorageNameCallback = (
  userId: number,
  taskType: string = '',
  courseTaskId: number
): (() => string) => {
  return () => {
    return `${userId}${taskType}${courseTaskId}`
  }
}

export const getReferenceSolutionCallback = (courseTaskId: number) => {
  const service = new FakeProtectedStudentCourseTaskService()
  return () => {
    return service.getReferenceSolution(courseTaskId)
  }
}
