import { Dispatch, SetStateAction, useEffect } from 'react'
import { MultiInputTaskDto } from '../../../../models/service/Task/MultiInputTaskDto'
import { MultiInputDirectionTaskDto } from '../../../../../../model/direction-model'
import { Answer } from './index'

const useCurrentAnswerSettings = (
  multiInputTask: MultiInputTaskDto | MultiInputDirectionTaskDto | undefined,
  setAnswers: Dispatch<SetStateAction<Answer[]>>,
  answers: Answer[]
) => {
  useEffect(() => {
    if (!multiInputTask) {
      return
    }
    setAnswers(answers.length === 0 ? multiInputTask.studentMultiInputResults : answers)
  }, [multiInputTask])
}

export default useCurrentAnswerSettings
