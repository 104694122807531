import React, { useEffect, useLayoutEffect, useState } from 'react'
import { store } from 'react-notifications-component'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { studentErrorNotyTemplate, studentSuccessNotyTemplate } from '../../../../config'
import StudentBreadCrumbs from '../components/student-bread-crumbs'
import { CodeTask, LectureTask, TheoryTask, WordTask } from './task-block'
import StudentCourseService from '../../../../services/student-services/student-course-service'
import CommentsBlock from './comment-block/comments-block'
import { AuthService } from '../../../../services/auth-service'
import StudentCourseTaskService from '../../../../services/student-services/student-course-task-service'
import UserCourseTaskService from '../../../../services/common-services/user-course-task-service'
import UserChapterService from '../../../../services/common-services/user-chapter-service'
import UserModuleService from '../../../../services/common-services/user-module-service'
import StudentCourseTaskInfoService from '../../../../services/student-services/student-course-task-info-service'
import UserNavigationService from '../../../../services/common-services/user-navigation-service'
import MultiInputTask from './task-block/multi-input-task'
import getLoadTaskCallback from './getLoadTaskCallback'
import {
  getReferenceSolutionCallback,
  getSessionStorageNameCallback,
  isCodeTaskExemplarySolutionAvailableCallback,
  isCodeTaskMentorCommentAvailableCallback,
  isCodeTaskSessionStorageAvailableCallback,
  isCodeTaskSolutionHistoryAvailableCallback,
} from './task-block/codeTaskCallbacks'
import MentorCheckTask from './task-block/mentor-check-task'
import AssociationTask from './task-block/association-task'
import GappingTask from './task-block/gapping-task'
import OrderingTask from './task-block/ordering-task'
import MultiTestTask from './task-block/multi-test-task'
import MultiAnswerTask from './task-block/multi-answer-task'
import Spinner from '../../../spinner'
import RaceConditionGuard from './raceConditionGuard'
import { useEffectAllDepsChange } from './useEffectAllDepsChange'
import usePrevious from './usePrevious'
import { getPostResetInterceptorStudent } from './getPostResetInterceptorStudent'
import { getReviewEntryCallbackStudent } from './task-block/getReviewEntryCallbackStudent'
import { getCheckAvailabilityCallbackStudent } from './getCheckAvailabilityCallbackStudent'
import getResetTaskCallbackStudent from './getResetTaskCallbackStudent'
import { getSolveTaskCallbackStudent } from './getSolveTaskCallbackStudent'
import { getPostSolveInterceptorStudent } from './getPostSolveInterceptorStudent'
import { getCoursePageNextStepUrlStudent } from './getCoursePageNextStepUrlStudent'
import { generateCourseURL } from '../../../../utils/generateCourseURL'
import { selectCourseType } from '../../../../store/course-type/course-type.selectors'
import UserCourseService from '../../../../services/common-services/user-course-service'
import { convertResponseImageUrl } from '../../../../utils/convertResponseImageUrl'
import ReviewStepTask from './task-block/review-step-task'
import reduxStore from '../../../../store'

let studentCourseService = new StudentCourseService()
const studentCourseTaskService = new StudentCourseTaskService()
const studentCourseTaskInfoService = new StudentCourseTaskInfoService()
const userCourseTaskService = new UserCourseTaskService()
const userChapterService = new UserChapterService()
const userModuleService = new UserModuleService()
const raceConditionGuard = new RaceConditionGuard()
const userCourseService = new UserCourseService()

reduxStore.subscribe(() => {
  const { courseType } = reduxStore.getState().courseType

  if (studentCourseService.courseType === courseType) {
    return
  }

  studentCourseService = new StudentCourseService(courseType)
})

const CoursePage = function CoursePage({
  history,
  statusPage = '',
  match: {
    params: {
      coursePosition = 0,
      modulePosition = 0,
      chapterPosition = 0,
      courseTaskPosition = 0,
      studentId = -1,
    } = {},
  },
}) {
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const courseType = useSelector(selectCourseType)
  const { t } = useTranslation()
  const [task, setTask] = useState()
  const [taskType, setTaskType] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const [moduleIsLoading, setModuleIsLoading] = useState(true)
  const [chapterIsLoading, setChapterIsLoading] = useState(true)
  const [taskTypeLoading, setTaskTypeLoading] = useState(0)

  const [tasksHeading, setTasksHeading] = useState({})
  const [nextButtonText, setNextButtonText] = useState(t('NextStep'))
  const [nextTaskLastInTheCourse, setNextTaskLastInTheCourse] = useState(false)
  const [chapterCount, setChapterCount] = useState(0)
  const [moduleCount, setModuleCount] = useState(0)
  const [courseTaskId, setCourseTaskId] = useState(0)
  const [courseId, setCourseId] = useState(0)
  const [chapterId, setChapterId] = useState(0)
  const [moduleId, setModuleId] = useState(0)
  const [studentCourseTaskInfoId, setStudentCourseTaskInfoId] = useState(0)
  const [linkForMentor, setLinkForMentor] = useState(``)
  const [principalRole, setPrincipalRole] = useState()
  const [principalId, setPrincipalId] = useState()
  const [coursePic, setCoursePic] = useState('')
  const [isNextChapterBlocked, setIsNextChapterBlocked] = useState(false)

  const solutionsLink = `/user/courses/${coursePosition}/${modulePosition}/${chapterPosition}/${courseTaskPosition}/solutions`
  const postResetInterceptor = getPostResetInterceptorStudent(taskType)
  const postSolveInterceptor = getPostSolveInterceptorStudent(taskType)
  const loadTasksHeading = () => {
    setIsLoading(true)
    studentCourseService
      .getTasksHeading(coursePosition, modulePosition, chapterPosition, courseTaskPosition)
      .then(newTaskHeading => {
        setTasksHeading(newTaskHeading)
        setTaskType(newTaskHeading.taskType)
        setTaskTypeLoading(prevState => prevState + 1)
      })
  }

  const onSolveTask = getSolveTaskCallbackStudent(taskType, studentCourseTaskInfoId)

  const loadTask = async (interceptor = val => val) => {
    if (courseTaskId <= 0) {
      return null
    }
    const load = getLoadTaskCallback(courseTaskId, taskType, studentId, studentCourseTaskService)
    return load()
      .then(value => {
        setTask(interceptor(value))
        return value
      })
      .then(value => {
        setIsLoading(false)
        return value
      })
      .catch(err => {
        catchErrorAlert(err)

        if (err.code === 423) {
          reactAlert.error(err.text)
          history.replace(`/user/courses/${courseId}`)
        }
      })
  }

  const onResetTask = getResetTaskCallbackStudent(studentCourseTaskInfoId)

  const prevChapterPosition = usePrevious(chapterPosition)
  const prevModulePosition = usePrevious(modulePosition)

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    setPrincipalRole(AuthService.currentUserValue().role.name)
    setPrincipalId(AuthService.currentUserValue().id)
  }, [])

  useEffect(() => {
    scrollToTop()
  }, [])

  useLayoutEffect(() => {
    setModuleIsLoading(true)
  }, [modulePosition])

  useLayoutEffect(() => {
    setChapterIsLoading(true)
  }, [chapterPosition])

  useEffect(() => {
    if (courseId * modulePosition !== 0) {
      raceConditionGuard
        .getGuardedPromise(userModuleService.getIdByCourseIdAndPosition(courseId, modulePosition))
        .then(value => {
          setModuleId(value)
          setModuleIsLoading(false)
        })
    }
  }, [courseId, modulePosition])

  useEffect(() => {
    if (moduleId * chapterPosition !== 0 && !moduleIsLoading && prevModulePosition === modulePosition) {
      raceConditionGuard
        .getGuardedPromise(userChapterService.getIdByModuleIdAndPosition(moduleId, chapterPosition))
        .then(value => {
          setChapterId(value)
        })
    }
  }, [moduleId, chapterPosition, moduleIsLoading])

  useEffect(() => {
    setChapterIsLoading(false)
  }, [chapterId])

  useEffect(() => {
    if (coursePosition * modulePosition * chapterPosition * courseTaskPosition !== 0) {
      studentCourseService.getCourseIdByPos(coursePosition).then(setCourseId)
      loadTasksHeading()
    }
  }, [coursePosition, modulePosition, chapterPosition, courseTaskPosition, studentId])

  useEffect(() => {
    if (courseId) {
      userCourseService.getCourseInfo(courseId).then(course => {
        setCoursePic(convertResponseImageUrl(course.coursePicUrl))
      })
    }
  }, [courseId])

  useEffect(() => {
    if (
      chapterId * courseTaskPosition !== 0 &&
      !moduleIsLoading &&
      !chapterIsLoading &&
      prevChapterPosition === chapterPosition &&
      prevModulePosition === modulePosition
    ) {
      setIsLoading(true)
      userCourseTaskService.getIdByChapterIdAndPosition(chapterId, courseTaskPosition).then(value => {
        setCourseTaskId(value)
      })
      UserNavigationService.getChapterNavigationInfoById(chapterId)
        .then(data => {
          setChapterCount(data.currentModuleChapterCount)
          setModuleCount(data.moduleCount)
          setIsNextChapterBlocked(data.nextChapterBlocked)
        })
        .catch(err => {
          catchErrorAlert(err)
        })
    }
  }, [chapterId, courseTaskPosition, moduleIsLoading, chapterIsLoading])

  useEffect(() => {
    if (courseTaskId !== 0) {
      userCourseTaskService.isNextTaskLastInTheCourse(courseTaskId).then(setNextTaskLastInTheCourse)
      studentCourseTaskService.getStudentCourseTaskIdByCourseTaskId(courseTaskId).then(setStudentCourseTaskInfoId)
    }
  }, [courseTaskId])

  useEffect(() => {
    if (studentCourseTaskInfoId !== 0) {
      studentCourseTaskInfoService.getLinkForMentor(studentCourseTaskInfoId).then(setLinkForMentor)
    }
  }, [studentCourseTaskInfoId])

  useEffectAllDepsChange(() => {
    loadTask()
  }, [courseTaskId, taskTypeLoading])

  const hasNextTask = () => {
    return tasksHeading.taskHeadingDtos?.length > parseInt(courseTaskPosition, 10)
  }

  const hasNextChapter = () => {
    return chapterCount > chapterPosition
  }

  const hasNextModule = () => {
    return moduleCount > modulePosition
  }

  const linkToMentor = () => {
    navigator.clipboard
      .writeText(`${linkForMentor}`)
      .then(() => {
        store.addNotification({
          ...studentSuccessNotyTemplate,
          message: `${t('LinkCopiedToClipboard')}`,
        })
      })
      .catch(() => {
        store.addNotification({
          ...studentErrorNotyTemplate,
          message: `${t('FailedToCopyLink')}`,
        })
      })
  }

  const urlNextStep = () => {
    return generateCourseURL(
      getCoursePageNextStepUrlStudent(
        hasNextTask,
        hasNextChapter,
        hasNextModule,
        studentId,
        coursePosition,
        modulePosition,
        chapterPosition,
        courseTaskPosition
      )(),
      courseType
    )
  }

  const nextStepButtonUrl = () => {
    history.push(urlNextStep())
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  /**
   * @param {string} courseTaskInfoId
   * @returns {function(): Promise<void>}
   */
  const handleCancelTaskCheck = courseTaskInfoId => async () => {
    await studentCourseTaskInfoService.cancelTaskCheck(courseTaskInfoId)
    setTask({ ...task, answer: null, answerId: null, solved: false })
  }

  let taskRender

  if (nextTaskLastInTheCourse) {
    if (nextButtonText !== `${t('ToCourseList')}`) {
      setNextButtonText(`${t('ToCourseList')}`)
    }
  } else if (nextButtonText !== `${t('NextStep')}`) {
    setNextButtonText(`${t('NextStep')}`)
  }

  const isCodeTaskExemplarySolutionAvailable = isCodeTaskExemplarySolutionAvailableCallback(principalRole)
  const isCodeTaskSolutionHistoryAvailable = isCodeTaskSolutionHistoryAvailableCallback(principalRole, statusPage)
  const isCodeTaskMentorCommentAvailable = isCodeTaskMentorCommentAvailableCallback(statusPage)
  const isCodeTaskSessionStorageAvailable = isCodeTaskSessionStorageAvailableCallback(statusPage)
  const getSessionStorageName = getSessionStorageNameCallback(principalId, taskType, courseTaskId)
  const getCodeTaskExemplarySolution = getReferenceSolutionCallback(courseTaskId)

  let mentorTaskSolved
  let mentorTaskChecked
  let mentorTaskAvailable

  // FIXME: третее условие - костыль поверх костылей, позволяющий избежать проблему,
  //  из-за которой при быстром переключении с задачи A на задачу B и обратно возникает случай, когда:
  //  1) taskType === A.type
  //  2) task.type === B.type (задача B успела сфетчиться быстрее и обновить состояние)
  //  3) A.type !== B.type => taskType !== task.type (два источника истины)
  //  4) isLoading === false (Race condition. Задача B успела сфетчиться быстрее и обновить состояние)
  //  В следствие чего интерфейс пытается отрендерить таску A с данными задачи B и падает с ошибкой
  //  Баг вызван критическими ошибками в проектировании - двумя источникам истины (тип задачи) и race condition
  //  в изменении состояния загрузки (из-за того, что состояние загрузки одно, а запросов может быть много и все
  //  они влияют на одно общее состояние)
  if (!isLoading && task && task.type === taskType) {
    if (tasksHeading.taskHeadingDtos.length < courseTaskPosition) {
      mentorTaskSolved = false
      mentorTaskChecked = false
      mentorTaskAvailable = false
    } else {
      mentorTaskSolved = tasksHeading.taskHeadingDtos[courseTaskPosition - 1].solved
      mentorTaskChecked = tasksHeading.taskHeadingDtos[courseTaskPosition - 1].checked
      mentorTaskAvailable = tasksHeading.taskHeadingDtos[courseTaskPosition - 1].available
    }
    switch (taskType) {
      case 'lecture':
        taskRender = (
          <LectureTask lectureTask={task} onSolveTask={onSolveTask} postSolveInterceptor={postSolveInterceptor} />
        )
        break

      case 'theory':
        taskRender = (
          <TheoryTask
            theoryTask={task}
            loadTask={loadTask}
            onSolveTask={onSolveTask}
            postSolveInterceptor={postSolveInterceptor}
            onResetTask={onResetTask}
            postResetInterceptor={postResetInterceptor}
          />
        )
        break
      case 'ordering':
        taskRender = (
          <OrderingTask
            orderingTask={task}
            loadTask={loadTask}
            onSolveTask={onSolveTask}
            onResetTask={onResetTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break
      case 'association':
        taskRender = (
          <AssociationTask
            associationTask={task}
            loadTask={loadTask}
            onResetTask={onResetTask}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break
      case 'word':
        taskRender = (
          <WordTask
            wordTask={task}
            onResetTask={onResetTask}
            onGetTask={loadTask}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break
      case 'code':
        taskRender = (
          <CodeTask
            codeTask={task}
            loadTask={loadTask}
            isExemplarySolutionAvailableCallback={isCodeTaskExemplarySolutionAvailable}
            isSolutionHistoryAvailableCallback={isCodeTaskSolutionHistoryAvailable}
            isMentorCommentAvailableCallback={isCodeTaskMentorCommentAvailable}
            isSessionStorageAvailableCallback={isCodeTaskSessionStorageAvailable}
            getExemplarySolution={getCodeTaskExemplarySolution}
            sessionStorageName={getSessionStorageName()}
            onSolveTask={onSolveTask}
            onResetTask={onResetTask}
            solutionsLink={generateCourseURL(solutionsLink, courseType)}
            courseTaskId={courseTaskId}
            statusPage={statusPage}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
            principalRole={principalRole}
          />
        )
        break
      case 'multi_input':
        taskRender = (
          <MultiInputTask
            multiInputTask={task}
            loadTask={loadTask}
            onResetTask={onResetTask}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break
      case 'multi_test':
        taskRender = (
          <MultiTestTask
            multiTestTask={task}
            loadTask={loadTask}
            onResetTask={onResetTask}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break
      case 'multi_answer':
        taskRender = (
          <MultiAnswerTask
            multiAnswerTask={task}
            loadTask={loadTask}
            onResetTask={onResetTask}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break
      case 'gapping':
        taskRender = (
          <GappingTask
            gappingTask={task}
            loadTask={loadTask}
            onResetTask={onResetTask}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break

      case 'mentorCheckTask':
        taskRender = (
          <MentorCheckTask
            solved={mentorTaskSolved}
            checked={mentorTaskChecked}
            handleCheckRequest={() => {}}
            statusPage={statusPage}
            loadTask={loadTask}
            mentorCheckTask={task}
            onSolveTask={onSolveTask}
            onCancelTaskCheck={handleCancelTaskCheck(studentCourseTaskInfoId)}
            postSolveInterceptor={postSolveInterceptor}
            solutionsLink={generateCourseURL(solutionsLink, courseType)}
            isAnswerAvailable
            isPermittedToCheckCallback={getCheckAvailabilityCallbackStudent()}
          />
        )
        break
      case 'review_step':
        taskRender = (
          <ReviewStepTask
            reviewStepTask={task}
            loadReviewDto={getReviewEntryCallbackStudent(moduleId, task.reviewType)}
            isReviewAssignable
            moduleId={moduleId}
            available={mentorTaskAvailable}
            completed={mentorTaskSolved}
          />
        )
        break
      default:
        taskRender = (
          <div className="task-loader">
            <Spinner />
          </div>
        )
    }
  } else {
    taskRender = (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  const isNextStepAvailable = hasNextTask() || !isNextChapterBlocked

  return (
    <>
      <StudentBreadCrumbs
        isLoading={isLoading}
        coursePosition={coursePosition}
        modulePosition={modulePosition}
        chapterPosition={chapterPosition}
        courseTaskPosition={courseTaskPosition}
        chapterId={chapterId}
        courseId={courseId}
        tasksHeading={tasksHeading}
        statusPage={statusPage}
        studentId={studentId}
        coursePic={coursePic}
      />
      <div className="step-content-wrap">
        <div className="container">
          <div className="step-content lesson">{taskRender}</div>
          {isNextStepAvailable && (
            <button type="button" className="next-step-btn" onClick={nextStepButtonUrl}>
              <span>{nextButtonText}</span>
              <i className="mdi mdi-chevron-right" />
            </button>
          )}
          {principalRole === 'PAY_STUDENT' && (
            <p>
              <button type="button" className="btn btn-light" style={{ verticalAlign: 'top' }} onClick={linkToMentor}>
                {t('CopyLinkForMentor')}
              </button>
            </p>
          )}
        </div>
      </div>
      <CommentsBlock
        courseTaskId={courseTaskId}
        statusPage={statusPage}
        principalRole={principalRole}
        isCourseComments
      />
    </>
  )
}

export default CoursePage
