import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import classes from './index.module.scss'
import AnimatedProgressBar from '../../../../components/animated-progress-bar/animated-progress-bar'
import CourseInfoV2Dto from '../../models/service/CourseInfoV2Dto'
import { CoursePageLoading } from '../../pages/CoursePage'
import TaskDto, { TaskStatus, TaskType } from '../../models/service/Task/TaskDto'
import TitleLoader from '../TitleLoader'
import BreadcrumbLoader from '../BreadcrumbItemLoader'
import Loader from '../Loader'
import TasksLoader from '../TasksLoader'
import ModuleDto from '../../models/service/ModuleDto'
import useCoursePageRouteParams from '../../hooks/useCoursePageRouteParams'
import getTasksByLocation from '../../utils/getTasksByLocation'
import { ResolveCoursePagePathFunction } from '../../CoursePageAdapter'
import LocationUtil from '../../utils/LocationUtil'
import isNullish from '../../../../utils/isNullish'
import { convertResponseImageUrl } from '../../../../utils/convertResponseImageUrl'
import { getPercent } from '../../utils/percentUtil'

export enum BreadcrumbsPart {
  Switch = 'Switch',
  TaskInfo = 'TaskInfo',
}

const iconClassNameByTaskType: Record<TaskType, string> = {
  [TaskType.Association]: 'mdi-arrow-expand-horizontal',
  [TaskType.Code]: 'mdi-code-tags',
  [TaskType.Gapping]: 'mdi-format-line-style',
  [TaskType.Lecture]: 'mdi-play',
  [TaskType.MentorCheck]: 'mdi-code-tags',
  [TaskType.MultiAnswer]: 'mdi-format-list-numbered',
  [TaskType.MultiInput]: 'mdi-clipboard-text-outline',
  [TaskType.MultiTest]: 'mdi-format-list-text',
  [TaskType.Ordering]: 'mdi-equalizer-outline',
  [TaskType.ReviewStep]: 'mdi-account-group',
  [TaskType.Theory]: 'mdi-format-list-bulleted',
  [TaskType.Word]: 'mdi-pencil',
}

const Breadcrumbs = ({ hiddenParts, courseInfo, currentTask, modules, loading, resolvePath }: Props) => {
  const { courseId, modulePosition, chapterPosition, taskPosition } = useCoursePageRouteParams()

  const navigation =
    (!isNullish(modulePosition) &&
      !isNullish(chapterPosition) &&
      !isNullish(taskPosition) &&
      LocationUtil.getNeighboursByTaskLocation(
        {
          modulePosition,
          chapterPosition,
          taskPosition,
        },
        modules
      )) ||
    undefined

  const tasks = useMemo(() => {
    if (!modules || isNullish(modulePosition) || isNullish(chapterPosition)) {
      return []
    }

    return getTasksByLocation(modules, { modulePosition, chapterPosition }) || []
  }, [modules, modulePosition, chapterPosition])

  const isPartHidden = (part: BreadcrumbsPart) => hiddenParts?.includes(part)

  const isTaskActive = (taskPositionToCheck: number) => {
    if (isNullish(taskPosition)) {
      return false
    }

    return taskPosition === taskPositionToCheck
  }

  const resolveCoursePath = () => {
    if (!courseId) {
      return '#'
    }

    return resolvePath(courseId)
  }

  const resolveTaskPath = (taskPositionToBeNavigatedTo: number) => {
    if (!courseId || isNullish(modulePosition) || isNullish(chapterPosition)) {
      return '#'
    }

    return resolvePath(courseId, modulePosition, chapterPosition, taskPositionToBeNavigatedTo)
  }

  const getNeighbourPath = LocationUtil.getNeighbourPath(
    navigation?.nearestNeighboursWithoutTasks,
    courseId,
    modulePosition,
    chapterPosition,
    resolvePath
  )

  const prevNeighbourPath = getNeighbourPath('prev')
  const nextNeighbourPath = getNeighbourPath('next')

  return (
    <div className={classes.breadcrumbs}>
      <div className={cn('container', classes.breadcrumbs__wrap)}>
        <div className={classes.breadcrumbs__image}>
          <img src={convertResponseImageUrl(courseInfo?.imageLink ?? null)} alt="" />
          <AnimatedProgressBar
            percentage={getPercent(courseInfo?.points ?? { total: 0, current: 0 })}
            className={classes.breadcrumbs__progress}
          />
        </div>

        <div className={classes.breadcrumbs__content}>
          <div className={cn(classes.breadcrumbs__links, classes.links)}>
            <Link to="/" className={classes.links__item}>
              Курсы
            </Link>
            <Link to={resolveCoursePath()} className={classes.links__item}>
              <Loader loader={BreadcrumbLoader} loading={loading.courseInfo} error={!courseInfo}>
                {courseInfo?.name}
              </Loader>
            </Link>

            <Loader
              loader={BreadcrumbLoader}
              loading={loading.currentTask || loading.modules}
              error={!currentTask}
              hide={isPartHidden(BreadcrumbsPart.TaskInfo)}
            >
              <span className={classes.links__item}>{currentTask?.name}</span>
            </Loader>
          </div>

          <div className={cn(classes.breadcrumbs__title, classes.title)}>
            <Loader
              loader={TitleLoader}
              loading={loading.courseInfo}
              error={!courseInfo}
              errorBoundary="Неизвестный курс"
            >
              {courseInfo?.name}
            </Loader>
            <Loader hide={isPartHidden(BreadcrumbsPart.TaskInfo)}>. </Loader>
            <Loader
              loader={TitleLoader}
              loading={loading.currentTask || loading.modules}
              error={!currentTask}
              errorBoundary="Неизвестная задача"
              hide={isPartHidden(BreadcrumbsPart.TaskInfo)}
            >
              {currentTask?.name}
            </Loader>
          </div>

          <Loader loader={TasksLoader} loading={loading.modules} hide={isPartHidden(BreadcrumbsPart.Switch)}>
            <div className={cn(classes.breadcrumbs__switch, classes.switch)}>
              {prevNeighbourPath && (
                <Link
                  to={prevNeighbourPath}
                  className={cn(classes.switch__item, classes.switch__item_link, classes.switch__item_success)}
                >
                  <i className="mdi mdi-arrow-left" />
                </Link>
              )}

              {tasks.map(({ type, status, taskId }, index) => (
                <Link
                  key={taskId}
                  to={resolveTaskPath(index + 1)}
                  className={cn(classes.switch__item, {
                    [classes.switch__item_active!]: isTaskActive(index + 1),
                    [classes.switch__item_success!]: [TaskStatus.SolvedRight, TaskStatus.CheckPassed].includes(status),
                    [classes.switch__item_failure!]: [TaskStatus.ReviewNotPassed, TaskStatus.SolvedWrong].includes(
                      status
                    ),
                    [classes.switch__item_warning!]: [TaskStatus.NeedReview].includes(status),
                  })}
                >
                  <i className={cn('mdi', iconClassNameByTaskType[type])} />
                </Link>
              ))}

              {nextNeighbourPath && (
                <Link
                  to={nextNeighbourPath}
                  className={cn(classes.switch__item, classes.switch__item_link, classes.switch__item_success)}
                >
                  <i className="mdi mdi-arrow-right" />
                </Link>
              )}
            </div>
          </Loader>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumbs

interface Props {
  hiddenParts?: BreadcrumbsPart[]
  courseInfo: CourseInfoV2Dto | undefined
  currentTask: TaskDto | null
  modules: ModuleDto[] | undefined
  loading: Partial<CoursePageLoading>
  resolvePath: ResolveCoursePagePathFunction
}
