export const getCoursePageNextStepUrlStudent = (
  hasNextTask: () => boolean,
  hasNextChapter: () => boolean,
  hasNextModule: () => boolean,
  studentId: number,
  coursePosition: number,
  modulePosition: number,
  chapterPosition: number,
  courseTaskPosition: number
): (() => string) => {
  return () => {
    if (hasNextTask()) {
      return `/user/courses/${coursePosition}/${modulePosition}/${chapterPosition}/${parseInt(
        String(courseTaskPosition),
        10
      ) + 1}`
    }
    if (hasNextChapter()) {
      return `/user/courses/${coursePosition}/${modulePosition}/${parseInt(String(chapterPosition), 10) + 1}/1`
    }
    if (hasNextModule()) {
      return `/user/courses/${coursePosition}/${parseInt(String(modulePosition), 10) + 1}/1/1`
    }
    return `/`
  }
}
