import { Dispatch, SetStateAction } from 'react'
import TaskDto from '../../models/service/Task/TaskDto'

const useResetTaskCallback = <T extends TaskDto, Loading>(
  setLoading: Dispatch<SetStateAction<Partial<Loading>>>,
  onResetTask: () => any,
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<any>,
  postResetInterceptor: (value: any) => any,
  resetAnswers: () => void
) => {
  return async () => {
    setLoading(loading => ({
      ...loading,
      taskResetting: true,
    }))

    try {
      const response = await onResetTask()
      loadTask(postResetInterceptor)
      resetAnswers()
      return response
    } catch (e) {
      console.log(e)
      return undefined
    } finally {
      setLoading(loading => ({
        ...loading,
        taskResetting: false,
      }))
    }
  }
}

export default useResetTaskCallback
