import {
  AssociationTaskDto,
  StudentAssociationTaskAnswerItemDto,
} from '../../../../models/service/Task/AssociationTaskDto'

const useCanBeSolved = (
  associationTask: AssociationTaskDto | undefined,
  answers: StudentAssociationTaskAnswerItemDto[]
) => {
  if (!associationTask) {
    return false
  }

  return associationTask.terms.length === answers.length && answers.every(answer => answer.definitionId)
}

export default useCanBeSolved
