import React from 'react'
import Editor, { EditorProps } from '@monaco-editor/react'
import Spinner from '../spinner'
import { CodeEditorTheme, useCodeEditorTheme } from '../../hooks/useCodeEditorTheme'

const CodeEditor = (props: Props) => {
  useCodeEditorTheme(CodeEditorTheme.Tomorrow)

  return (
    <Editor
      {...props}
      width="100%"
      height={500}
      theme="tomorrow"
      loading={<Spinner />}
      options={{
        ...props.options,
        fontSize: 16,
      }}
    />
  )
}

export default CodeEditor

type Props = EditorProps
