import React, { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Loading } from '../AssociationTask'
import useLoadTaskCallback from '../../useLoadTaskCallback'
import useIsSolved from './useIsSolved'
import useIsSolvedRight from '../../useIsSolvedRight'
import useCanBeSolved from './useCanBeSolved'
import useCurrentAnswerSettings from './useCurrentAnswerSettings'
import useSetAnswerCallback from './useSetAnswerCallback'
import { ItemDto, MultiInputTaskDto } from '../../../../models/service/Task/MultiInputTaskDto'
import useSolveTaskCallback from '../../useSolveTaskCallback'
import useResetTaskCallback from '../../useResetTaskCallback'
import usePostSolveAnswerSetterCallback from './usePostSolveAnswerSetterCallback'
import Spinner from '../../../../../../components/spinner'
import TaskDescription from '../../../../../../components/pages/student/course-page/task-block/task-description'
import InputItems from './input-items'
import Result from '../../Result'
import Actions from '../../Actions'

interface Props {
  multiInputTask: MultiInputTaskDto
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<MultiInputTaskDto>
  onResetTask: () => Promise<any>
  onSolveTask: any // т.к. в getSolveTaskCallback используются фейковые сервисы, которые не типизированы
  postResetInterceptor: (val: any, ...args: any[]) => any
  postSolveInterceptor: (val: any, ...args: any[]) => any
}

export interface Answer {
  itemId: number
  answerText: string
  right?: boolean
}

const MultiInputTask: FC<Props> = ({
  multiInputTask,
  loadTask,
  onResetTask,
  onSolveTask,
  postResetInterceptor,
  postSolveInterceptor,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { description, items } = multiInputTask || {}
  const [answers, setAnswers] = useState<Answer[]>([])

  const [loading, setLoading] = useState<Partial<Loading>>({})

  const loadMultiInputTask = useLoadTaskCallback(setLoading, loadTask)

  const isSolved = useIsSolved(multiInputTask)

  const isAnswerRight = (answer: Answer) => {
    return answer.right || false
  }

  const isSolvedRight = useIsSolvedRight<Answer>(multiInputTask, answers, isAnswerRight)
  const canBeSolved = useCanBeSolved(multiInputTask, answers)

  useCurrentAnswerSettings(multiInputTask, setAnswers, answers)
  const setAnswer = useSetAnswerCallback(answers, setAnswers)

  const getStudentAnswer = (item: ItemDto) => {
    const itemId = item.id
    const answerDto = answers.find(answer => answer.itemId === itemId)
    return answerDto ? answerDto.answerText : ''
  }

  const getIsRight = (item: ItemDto): boolean | undefined => {
    const itemId = item.id
    const answerDto = answers.find(answer => answer.itemId === itemId)
    if (!answerDto) return undefined
    return answerDto.right
  }

  const resetAnswers = () => {
    setAnswers([])
  }

  const resetTaskLocal = useResetTaskCallback(setLoading, onResetTask, loadTask, postResetInterceptor, resetAnswers)

  const solveTaskLocal = useSolveTaskCallback(
    multiInputTask,
    async () => {
      return onSolveTask(answers)
    },
    async skipLoading => loadMultiInputTask(skipLoading),
    canBeSolved,
    setLoading
  )

  const postSolveAnswerSetter = usePostSolveAnswerSetterCallback(setAnswers, answers)

  const solveMultiInputTask = () => {
    // @ts-ignore
    solveTaskLocal()
      .then(res => {
        // @ts-ignore
        postSolveAnswerSetter(res)
      })
      .then(() => {
        postSolveInterceptor(isSolved, loadTask, dispatch)
      })
  }

  if (loading.taskLoading) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="task-content">
      <div className="step-content-head">{t('MultiInputTask')}</div>
      <TaskDescription description={description} />
      <InputItems<ItemDto>
        items={items}
        disabled={isSolved && isSolvedRight}
        resolveAnswer={getStudentAnswer}
        resolveIsRight={getIsRight}
        resolveQuestion={item => item.questionText}
        resolveItemId={item => item.id}
        setAnswer={setAnswer}
      />

      <div className="lesson-result-row">
        <Result isSolved={isSolved} isSolvedRight={isSolvedRight} />
        <Actions
          taskResettingLoading={loading.taskResetting}
          taskSolvingLoading={loading.taskSolving}
          canBeSolved={canBeSolved}
          isSolved={isSolved && isSolvedRight}
          resetTask={resetTaskLocal}
          solveTask={solveMultiInputTask}
        />
      </div>
    </div>
  )
}

export default MultiInputTask
