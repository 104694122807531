import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import TaskDescription from '../../../../../../components/pages/student/course-page/task-block/task-description'
import { WordTaskDto } from '../../../../models/service/Task/WordTaskDto'
import Spinner from '../../../../../../components/spinner'
import { generateAllAvailableTaskStatus } from '../../../../utils/formatterStatusTasks'
import { AnswerStatusDto } from '../../../../models/service/Task/AnswerStatusDto'

const WordTask: FC<IProps> = ({
  wordTask,
  onResetTask,
  onSolveTask,
  onGetTask,
  postResetInterceptor,
  postSolveInterceptor,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [newAnswer, setNewAnswer] = useState(``)
  const [solvingTask, setSolvingTask] = useState(false)
  const [taskLoaded, setTaskLoaded] = useState(true)
  const [lastActionIsRight, setLastActionIsRight] = useState(false)
  const [lastActionIsWrong, setLastActionIsWrong] = useState(false)
  const allTaskStatus = generateAllAvailableTaskStatus(wordTask.answerStatus)

  useEffect(() => {
    if (wordTask.answerStatus) {
      const answerIsRight = allTaskStatus[AnswerStatusDto.LAST_ANSWER_RIGHT]
      const answerIsWrong = allTaskStatus[AnswerStatusDto.LAST_ANSWER_WRONG]
      const isReseated = allTaskStatus[AnswerStatusDto.ANSWER_RESET]
      setTaskLoaded(false)
      setLastActionIsRight(answerIsRight!)
      setLastActionIsWrong(answerIsWrong!)
      const { answer } = wordTask
      const chooseAnswer = isReseated ? `` : answer || ``
      setNewAnswer(chooseAnswer)
    }
  }, [])

  // TODO: loadTask being called when reset or submit actions happened to refresh ui but it used old api
  //  Not sure about support this method in V2 version thus removed logic related with that

  const loadWordTask = (interceptor: any) => Promise.resolve(wordTask)

  const solveWordTask = () => {
    const { isSolved: previousSolveStatus } = wordTask
    onSolveTask(newAnswer).then((solveResult: any) =>
      postSolveInterceptor(
        previousSolveStatus,
        solveResult,
        loadWordTask,
        newAnswer,
        setLastActionIsRight,
        setLastActionIsWrong,
        dispatch
      )
    )
  }

  useEffect(() => {
    if (solvingTask) {
      solveWordTask()
    }
  }, [solvingTask])

  const resetTask = () => {
    onResetTask()
      .then(() => {
        return loadWordTask(postResetInterceptor)
      })
      .then(() => {
        setLastActionIsRight(false)
        setLastActionIsWrong(false)
        setNewAnswer('')
      })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAnswer(event.target.value)
  }

  if (taskLoaded || wordTask === null) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  const { description } = wordTask
  const isReseated = allTaskStatus[AnswerStatusDto.ANSWER_RESET]
  const disabled = newAnswer.length === 0
  let inputClassName = lastActionIsRight ? `lesson-input success` : `lesson-input error`
  let lessonClassName = lastActionIsRight ? `lesson-result success` : `lesson-result error`
  let message = lastActionIsRight ? `${t('TheRightSolution')}` : `${t('InvalidSolutionCheckAgain')}`
  let icon: JSX.Element | null = lastActionIsRight ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />

  if (!lastActionIsRight && !lastActionIsWrong) {
    inputClassName = `lesson-input`
    lessonClassName = `lesson-result`
    message = ``
    icon = null
  }

  if (isReseated) {
    inputClassName = `lesson-input`
  }

  return (
    <div className="task-content">
      <div className="step-content-head">{t('AnswerLesson')}</div>
      <TaskDescription description={description} />
      <input
        type="text"
        className={inputClassName}
        style={{ color: `black` }}
        name="lesson-answer"
        onChange={handleChange}
        placeholder={t('EnterYourAnswer')}
        value={newAnswer || ''}
        disabled={lastActionIsRight && !isReseated}
      />

      <div className="lesson-result-row">
        {isReseated || solvingTask ? null : (
          <div className={lessonClassName}>
            {icon}
            <span>{message}</span>
          </div>
        )}
        <div className="lesson-result-right-wrap">
          <button type="button" className="reset-value-btn" style={{ cursor: 'pointer' }} onClick={() => resetTask()}>
            {t('ResetSolution')}
          </button>
          {solvingTask ? (
            <button type="button" disabled className="send-result-btn" style={{ cursor: 'pointer' }}>
              {t('SubmittingSolution')}
            </button>
          ) : (
            <button type="button" onClick={() => setSolvingTask(true)} className="send-result-btn" disabled={disabled}>
              {t('SubmitSolution')}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default WordTask

interface IProps {
  wordTask: WordTaskDto
  onResetTask: () => Promise<any>
  onSolveTask: any
  onGetTask: (interceptor?: (value: any) => Promise<any>) => Promise<WordTaskDto>
  postResetInterceptor: (val: any, ...args: any[]) => any
  postSolveInterceptor: (
    previousSolveStatus: boolean,
    solveResult: any,
    loadTask: (interceptor?: (value: any) => any) => Promise<WordTaskDto>,
    newAnswer: string,
    setLastActionIsRight: Dispatch<SetStateAction<Partial<boolean>>>,
    setLastActionIsWrong: Dispatch<SetStateAction<Partial<boolean>>>,
    dispatch: Dispatch<any>
  ) => void
}
