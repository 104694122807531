import { AnswerStatusDto } from './AnswerStatusDto'

interface TaskDto {
  taskId: number
  name: string
  description: string
  position?: number
  status: TaskStatus
  type: TaskType
  id: number
  answer: string | null
  taskPoints: number
  isSolved: boolean
  answerStatus: AnswerStatusDto
}

export default TaskDto

export enum TaskStatus {
  NeedReview = 'NEED_REVIEW',
  ReviewNotPassed = 'REVIEW_NOT_PASSED',
  SolvedWrong = 'SOLVED_WRONG',
  SolvedRight = 'SOLVED_RIGHT',
  CheckPassed = 'CHECK_PASSED',
  NotSolved = 'NOT_SOLVED',
}

export enum TaskType {
  Lecture = 'lecture',
  Word = 'word',
  Theory = 'theory',
  Code = 'code',
  MentorCheck = 'mentorCheckTask',
  ReviewStep = 'review_step',
  Ordering = 'ordering',
  Association = 'association',
  MultiTest = 'multi_test',
  MultiAnswer = 'multi_answer',
  Gapping = 'gapping',
  MultiInput = 'multi_input',
}
