import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'

class UserCourseService {
  sourceURL

  constructor() {
    this.sourceURL = `${serverHost}/api/user/course`
  }

  getCoursePic = courseId => {
    const queryOption = {
      method: 'GET',
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${courseId}/course-pic`, queryOption)
  }

  getCourseName = courseId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${courseId}/name`, queryOption)
  }

  getCourseInfo = courseId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${courseId}/course-info`, queryOption)
  }

  getCourseAuthor = courseId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${courseId}/course-author`, queryOption)
  }

  moduleCount = courseId => {
    const url = `${this.sourceURL}/${courseId}/size`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(url, queryOption)
  }

  loadDirectionIdByStudentId = studentId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/direction?studentId=${studentId}`, queryOption)
  }
}

export default UserCourseService
