import React, { useMemo, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import TaskPage from '../TaskPage'
import Breadcrumbs, { BreadcrumbsPart } from '../../components/Breadcrumbs'
import Layout from '../../components/Layout'
import CourseDetailsPage from '../CourseDetailsPage'
import isNullish from '../../../../utils/isNullish'
import CoursePageAdapter from '../../CoursePageAdapter'
import CourseInfoV2Dto from '../../models/service/CourseInfoV2Dto'
import ModuleDto from '../../models/service/ModuleDto'
import useCoursePageRouteParams from '../../hooks/useCoursePageRouteParams'
import useCourse from '../../hooks/useCourse'
import useTask from '../../hooks/useTask'
import AnyTaskDto from '../../models/service/Task/AnyTaskDto'

interface Props {
  pageAdapter: CoursePageAdapter
}

export interface CoursePageLoading {
  courseInfo: boolean
  modules: boolean
  currentTask: boolean
}

const CoursePage = ({ pageAdapter }: Props) => {
  const { courseId, modulePosition, chapterPosition, taskPosition } = useCoursePageRouteParams()
  const [loading, setLoading] = useState<Partial<CoursePageLoading>>({
    courseInfo: false,
    currentTask: false,
    modules: false,
  })
  const [courseInfo, setCourseInfo] = useState<CourseInfoV2Dto>()
  const [modules, setModules] = useState<ModuleDto[]>()
  const [currentTask, setCurrentTask] = useState<AnyTaskDto | null>(null)

  const setLoadingByKey = (loadingKey: keyof typeof loading, loadingValue: boolean) => {
    setLoading(currentLoading => ({
      ...currentLoading,
      [loadingKey]: loadingValue,
    }))
  }

  useCourse({
    courseId,
    pageAdapter,
    setLoadingByKey,
    setCourseInfo,
    setModules,
  })

  useTask({
    courseId,
    modules,
    modulePosition,
    chapterPosition,
    taskPosition,
    setLoadingByKey,
    setCurrentTask,
    pageAdapter,
  })

  const hiddenBreadcrumbsParts = useMemo(() => {
    const parts = []

    if (isNullish(taskPosition)) {
      parts.push(BreadcrumbsPart.Switch, BreadcrumbsPart.TaskInfo)
    }

    return parts
  }, [taskPosition])

  return (
    <Layout>
      <Breadcrumbs
        hiddenParts={hiddenBreadcrumbsParts}
        courseInfo={courseInfo}
        currentTask={currentTask}
        modules={modules}
        loading={loading}
        resolvePath={pageAdapter.resolvePath}
      />

      <div className="container">
        <Switch>
          <Route path={pageAdapter.courseDetailsPagePath} exact>
            <CourseDetailsPage
              modules={modules}
              courseInfo={courseInfo}
              loading={loading}
              resolvePath={pageAdapter.resolvePath}
            />
          </Route>
          <Route path={pageAdapter.taskPagePath} exact>
            <TaskPage task={currentTask} />
          </Route>
          <Route path="**">
            <Redirect to={courseId ? pageAdapter.resolvePath(courseId) : '/'} />
          </Route>
        </Switch>
      </div>
    </Layout>
  )
}

export default CoursePage
