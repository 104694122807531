import React from 'react'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import AnimatedProgressBar from '../../../../animated-progress-bar/animated-progress-bar'
import NumberUtil from '../../../../../utils/NumberUtil'
import { AuthService } from '../../../../../services/auth-service'
import FakeProtectEnrollService from '../../../../../services/fake-page-services/fake-protect-enroll-service'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import { generateCourseURL } from '../../../../../utils/generateCourseURL'
import CourseType from '../../course-type.model'
import 'react-confirm-alert/src/react-confirm-alert.css'

const mentorEnrollService = new FakeProtectEnrollService()
export const availableRolesForCompleteCourse = [
  RoleEnum.ADMIN,
  RoleEnum.MENTOR,
  RoleEnum.CHIEF_MENTOR,
  RoleEnum.CURATOR,
]

const CourseElement = ({
  course: { id, name, position, studentPoints, coursePoints, available = true },
  pic,
  studentId,
  statusPage,
  courseType = CourseType.Regular,
}) => {
  const percentage = NumberUtil.getPercentage(studentPoints, coursePoints)
  const principalRole = AuthService.currentUserValue().role.name
  const link = statusPage !== 'fake' ? `/user/courses/${position}` : `/fake/user/${studentId}/courses/${position}`

  const finishedCourse = () => {
    confirmAlert({
      title: 'Завершение модуля у студента',
      message: 'Подтверждаешь завершение модуля? Отменить действие будет невозможно!',
      buttons: [
        {
          label: 'Завершить',
          onClick: () => {
            mentorEnrollService.enrollStudentCourse(id, studentId).then(() => {
              // eslint-disable-next-line no-restricted-globals
              history.go()
            })
          },
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }

  return (
    <>
      {available ? (
        <Link to={generateCourseURL(link, courseType)} className="course-block">
          <AnimatedProgressBar className="course-progress" percentage={percentage} />
          <span className="course-pic">{pic && <img src={pic} alt="course-pic" />}</span>
          <span className="course-title">{name}</span>
        </Link>
      ) : (
        <div className="course-block">
          <span className="course-pic">{pic && <img src={pic} alt="course-pic" />}</span>
          <span className="course-title" style={{ color: '#BEBEBE' }}>
            {name}
          </span>
        </div>
      )}
      {availableRolesForCompleteCourse.includes(principalRole) && (
        <button
          type="button"
          className={studentPoints / coursePoints >= 1 ? 'finish-course-block' : 'finish-course'}
          style={{ cursor: 'pointer' }}
          onClick={finishedCourse}
        >
          Завершить курс
        </button>
      )}
    </>
  )
}

export default CourseElement
