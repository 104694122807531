import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useIsSolved from './useIsSolved'
import useIsSolvedRight from '../../useIsSolvedRight'
import useCanBeSolved from '../../useCanBeSolved'
import useLoadTaskCallback from '../../useLoadTaskCallback'
import useResetTaskCallback from '../../useResetTaskCallback'
import mapQuestionAnswersToProbableAnswers from './mapQuestionAnswersToProbableAnswers'
import usePostSolveAnswerSetter from './usePostSolveAnswerSetter'
import useIsAnswerCheckedCallback from '../../useIsAnswerCheckedCallback'
import useChangeAnswersCallback from '../../useChangeAnswersCallback'
import useIsQuestionAnswersRightCallback from '../../useIsQuestionAnswersRightCallback'
import {
  MultiTestAnswerDto,
  MultiTestQuestionDto,
  MultiTestTaskDto,
} from '../../../../models/service/Task/MultiTestTaskDto'
import Spinner from '../../../../../../components/spinner'
import TaskDescription from '../../../../../../components/pages/student/course-page/task-block/task-description'
import Category from '../../Category'
import Test from '../../../../../../components/pages/student/course-page/task-block/test'
import Result from '../../Result'
import Actions from '../../Actions'
import { MultiTestDirectionTaskDto } from '../../../../../../model/direction-model'
import TaskLoading from '../../TaskLoading'
import useSolveTaskCallback from '../../useSolveTaskCallback'

export interface Question {
  itemId: number
  studentItemAnswers: Answer[]
  isRight?: boolean
}

export interface Answer {
  itemAnswerId: number
}

const MultiTestTask: FC<Props> = ({ multiTestTask, postResetInterceptor, onResetTask, onSolveTask, loadTask }) => {
  const { t } = useTranslation()

  const { description, tests } = multiTestTask || {}
  const [loading, setLoading] = useState<Partial<Loading>>({})
  const [questions, setQuestions] = useState<Question[]>([])
  const [isAnswersUpdatable, setIsAnswersUpdatable] = useState<boolean>(true)

  const isSolved = useIsSolved(multiTestTask)
  const isSolvedRight = useIsSolvedRight(multiTestTask, questions, question => question.isRight as boolean)
  const canBeSolved = useCanBeSolved(
    multiTestTask?.tests,
    questions,
    question => question.itemId,
    answer => answer.itemId,
    answer => answer.studentItemAnswers.length
  )

  const loadMultiTestTask = useLoadTaskCallback(setLoading, loadTask)

  const resetTaskLocal = useResetTaskCallback(setLoading, onResetTask, loadTask, postResetInterceptor, () => {
    setQuestions([])
  })

  const resetMultiTestTask = () => {
    return resetTaskLocal()
  }

  const solveTaskLocal = useSolveTaskCallback(
    multiTestTask,
    () => onSolveTask(questions.map(mapQuestionAnswersToProbableAnswers)),
    async skipLoading => loadMultiTestTask(skipLoading),
    canBeSolved,
    setLoading
  )

  const postSolveAnswerSetter = usePostSolveAnswerSetter(questions, setQuestions)

  const solveMultiTestTask = () => {
    return (
      solveTaskLocal()
        .then(solveResult => {
          if (solveResult) setIsAnswersUpdatable(false)
          return solveResult
        })
        // @ts-ignore
        .then(postSolveAnswerSetter)
    )
  }

  const isAnswerChecked = useIsAnswerCheckedCallback(
    questions,
    question => question.itemId,
    question => question.studentItemAnswers,
    answer => answer.itemAnswerId
  )
  const changeAnswers = useChangeAnswersCallback(
    questions,
    setQuestions,
    question => question.itemId,
    question => question.studentItemAnswers,
    answer => answer.itemAnswerId,
    (id, answers) => ({ itemId: id, studentItemAnswers: answers }),
    id => ({ itemAnswerId: id })
  )
  const isQuestionAnswersRight = useIsQuestionAnswersRightCallback<Question, MultiTestQuestionDto>(
    questions,
    question => question.itemId,
    question => question?.isRight,
    currentQuestion => currentQuestion.itemId
  )

  useEffect(() => {
    if (multiTestTask && isAnswersUpdatable) {
      setQuestions(multiTestTask.studentItems)
    }
  }, [multiTestTask])

  if (loading.taskLoading) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="task-content">
      <div className="step-content-head">{t('MultiTestTask')}</div>
      <TaskDescription description={description} />

      <Category label="Вопросы задачи">
        {/* todo: Удалить типы, когда станет понятно, почему дженерик не резолвит их автоматически */}
        <Test<MultiTestQuestionDto, MultiTestAnswerDto>
          tests={tests}
          resolveQuestionId={question => question.itemId}
          resolveQuestionText={question => question.itemText}
          resolveQuestionAnswers={question => question.itemAnswers}
          resolveIsQuestionAnswersRight={isQuestionAnswersRight}
          resolveAnswerId={answer => answer.itemAnswerId}
          resolveAnswerText={answer => answer.itemAnswerText}
          isChecked={isAnswerChecked}
          onChange={changeAnswers}
          disabled={isSolved}
        />
      </Category>

      <div className="lesson-result-row">
        <Result isSolved={isSolved} isSolvedRight={isSolvedRight} />
        <Actions
          taskResettingLoading={loading.taskResetting}
          taskSolvingLoading={loading.taskSolving}
          canBeSolved={canBeSolved}
          isSolved={isSolved}
          resetTask={resetMultiTestTask}
          solveTask={solveMultiTestTask}
        />
      </div>
    </div>
  )
}

interface Props {
  multiTestTask: MultiTestTaskDto | MultiTestDirectionTaskDto
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<MultiTestTaskDto | MultiTestDirectionTaskDto>
  onResetTask: () => Promise<any>
  onSolveTask: any
  postResetInterceptor: (val: any, ...args: any[]) => any
}

export interface Loading extends TaskLoading {}

export default MultiTestTask
