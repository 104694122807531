import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const ContactsPage = () => {
  const { t } = useTranslation()

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToTop()
  }, [])

  document.title = `${t('Contacts')}`
  return (
    <div className="step-content-wrap">
      <div className="container">
        <div className="info-jm-block">
          <div className="info-jm-content">
            <h3>{t('Contacts')}</h3>
            <div>
              <p>{t('Mail')}: info@kata.academy</p>
              <p>{t('PhoneNumber')}: 8-800-350-32-96</p>
              <p>
                {t('Website')}:{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener">
                  https://kata.academy//
                </a>
              </p>
              <p>
                {t('VKGroup')}:{' '}
                <a href="https://vk.com/java_mentor" target="_blank" rel="noreferrer noopener">
                  KataAcademy
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactsPage
