import React from 'react'
import { useTranslation } from 'react-i18next'
import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import CommentForm from '../comment-form'
import TaskComment from '../task-comments'
import { User, TaskComment as taskCommentType, Avatars as avatarsType } from '../comments-block'

export type CommentBlockProps = {
  addNewComment: (text: string) => void
  taskComments: taskCommentType[]
  authUser: User | null
  avatars: avatarsType
  changeLike: (commentIndex: number) => void
  setOpenedAnswerForm: React.Dispatch<React.SetStateAction<number>>
  openedAnswerForm: number
  editComment: {
    id: number
    text: string
  }
  setEditComment: React.Dispatch<
    React.SetStateAction<{
      id: number
      text: string
    }>
  >
  updateComment: (text: string, commentId: number) => void
  addAnswerToComment: (text: string) => void
  showDependsComments: number
  setShowDependsComments: React.Dispatch<React.SetStateAction<number>>
  updateAnswerComment: (text: string, commentId: number, mainCommentId: number) => void
  changeAnswerCommentLike: (commentIndex: number, mainCommentId: number) => void
  deleteComment: (commentId: number) => void
  statusPage: string
  principalRole: RoleEnum
  handleDeleteComment: (commentId: number) => void
  handleSubmitComment: (commentId: number, mainIndex: number, mainCommentId?: number) => void
}

const CommentsRender: React.FC<CommentBlockProps> = ({
  changeAnswerCommentLike,
  changeLike,
  addNewComment,
  taskComments,
  authUser,
  avatars,
  setOpenedAnswerForm,
  openedAnswerForm,
  editComment,
  setEditComment,
  updateComment,
  addAnswerToComment,
  showDependsComments,
  setShowDependsComments,
  updateAnswerComment,
  deleteComment,
  statusPage,
  principalRole,
  handleSubmitComment,
  handleDeleteComment,
}) => {
  const { t } = useTranslation()
  const tasksComments = taskComments.map((taskComment: taskCommentType, idx: number) => {
    return (
      <TaskComment
        taskComment={taskComment}
        idx={idx}
        key={`comment_${taskComment.id}`}
        authUser={authUser}
        avatars={avatars}
        setOpenedAnswerForm={setOpenedAnswerForm}
        openedAnswerForm={openedAnswerForm}
        editComment={editComment}
        setEditComment={setEditComment}
        updateComment={updateComment}
        addAnswerToComment={addAnswerToComment}
        showDependsComments={showDependsComments}
        setShowDependsComments={setShowDependsComments}
        updateAnswerComment={updateAnswerComment}
        deleteComment={deleteComment}
        statusPage={statusPage}
        principalRole={principalRole}
        changeLike={changeLike}
        changeAnswerCommentLike={changeAnswerCommentLike}
        handleSubmitComment={handleSubmitComment}
        handleDeleteComment={handleDeleteComment}
      />
    )
  })
  return (
    <div className="step-bottom-wrapper">
      <div className="container">
        <div className="step-bottom-wrap">
          <div className="steps-bottom-tabs sbox">
            <div className="tabs-wrap">
              <div className="course-tab active" id="comment" data-tab="1">
                {t('Comments')}
              </div>
            </div>
            <div className="tab-content tab-1 active">
              <div className="add-comment-wrap">
                <div className="add-comment-title">{t('AddAComment')}</div>
                <CommentForm addAnswerComment addNewComment={addNewComment} isAnswerComment={false} />
              </div>
              <div className="all-comments-wrap">
                <div className="all-comments-title">
                  {t('StudentsComments')} ({taskComments.length})
                </div>
                <div className="all-comments-body">{tasksComments}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommentsRender
