import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import './modal-right-answer.css'
import { useTranslation } from 'react-i18next'
import UserMessageService from '../../../../../../../services/common-services/user-message-service'

const customStyles = {
  content: {
    left: '50%',
    top: '30%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    padding: 0,
    margin: '44px 0',
  },
  overlay: {
    backgroundColor: 'rgba(196, 196, 196, 0.5)',
    overflow: 'hidden',
    zIndex: 10,
  },
}

const userMessageService = new UserMessageService()

const ModalRightAnswer = ({ modalShowed, onClose }) => {
  const [message, setMessage] = useState(``)
  // eslint-disable-next-line id-length
  const { t } = useTranslation()

  useEffect(() => {
    if (modalShowed) {
      userMessageService.getMessageRightAnswerModal().then(mes => setMessage(mes.message))
    }
  }, [modalShowed])

  const closeModalAndSendComment = () => {
    onClose()
    setTimeout(() => {
      document.getElementById('comment').scrollIntoView({ block: 'start', inline: 'center' })
      document.getElementsByClassName('public-DraftEditor-content')[0].focus()
    }, 10)
  }
  return (
    <>
      <Modal isOpen={modalShowed} onRequestClose={onClose} style={customStyles}>
        <div className="stat-courses-popup popup-window popup-window-write-comment">
          <div className="popup-head">
            {t('RightSolutionModalTitle')}
            <button type="button" className="close-modal-button" onClick={onClose} />
          </div>
          <div className="courses-popup-wrap">{message}</div>
          <div className="content-buttom-message-to-comment">
            <span className="send-comment" onClick={closeModalAndSendComment}>
              {t('WriteComment')}
            </span>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalRightAnswer
