import React from 'react'
import ContentLoader from '../ContentLoader'

const NUMBER_OF_MODULES = 8
const TASK_BUTTON_SIZE = 46
const TASK_BUTTON_SIDE_INDENT = 10
const TOP_INDENT = 16

const TasksLoader = () => {
  return (
    <ContentLoader
      width={NUMBER_OF_MODULES * (TASK_BUTTON_SIZE + TASK_BUTTON_SIDE_INDENT)}
      height={TASK_BUTTON_SIZE + TOP_INDENT}
    >
      {Array.from({ length: NUMBER_OF_MODULES }, (_, index) => (
        <rect
          key={index}
          x={(TASK_BUTTON_SIZE + TASK_BUTTON_SIDE_INDENT) * index}
          y={TOP_INDENT}
          rx={TASK_BUTTON_SIZE}
          ry={TASK_BUTTON_SIZE}
          width={TASK_BUTTON_SIZE}
          height={TASK_BUTTON_SIZE}
        />
      ))}
    </ContentLoader>
  )
}

export default TasksLoader
