import { AnswerStatusDto } from '../models/service/Task/AnswerStatusDto'

type ReturnType = { [key: string]: boolean }

export const generateAllAvailableTaskStatus = (currentTaskStatus: string): ReturnType => {
  return Object.keys(AnswerStatusDto).reduce((acc, currentValue): ReturnType => {
    // @ts-ignore
    acc[currentValue] = [currentValue].includes(currentTaskStatus)
    return acc
  }, {})
}
