import produce from 'immer'
import { Dispatch, SetStateAction } from 'react'
import { Answer } from './index'
import { TermDto } from '../../../../models/service/Task/AssociationTaskDto'

const useSetAnswerCallback = (
  answers: Answer[],
  setAnswers: Dispatch<SetStateAction<Answer[]>>,
  terms: TermDto[] | undefined
) => {
  return (itemId: number, position: number | null) => {
    setAnswers(
      produce(answers, draftAnswers => {
        const currentAnswerIndex = draftAnswers.findIndex(answer => answer.definitionId === itemId)

        if (currentAnswerIndex !== -1) {
          draftAnswers.splice(currentAnswerIndex, 1)
        }

        const term = position && terms?.[position - 1]

        if (!term) {
          return
        }

        const { termId } = term

        const newAnswer = draftAnswers.find(answer => answer.termId === termId)

        if (newAnswer) {
          newAnswer.definitionId = itemId
          return
        }

        draftAnswers.push({
          termId,
          definitionId: itemId,
        })
      })
    )
  }
}

export default useSetAnswerCallback
