import React from 'react'
import ReactContentLoader from 'react-content-loader'
import ContentLoader from '../ContentLoader'

const RectLoader = ({ width, height = 15, backgroundColor, foregroundColor }: Props) => {
  return (
    <ContentLoader width={width} height={height} backgroundColor={backgroundColor} foregroundColor={foregroundColor}>
      <rect x="0" y="0" rx="4" ry="4" width={width} height={height} />
    </ContentLoader>
  )
}

export default RectLoader

interface Props {
  width: number
  height?: number
  backgroundColor?: string
  foregroundColor?: string
}
