import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import { store } from 'react-notifications-component'
import { days, years } from '../../../utils/select-state/select-state-arrays'
import RegistrationService from '../../../services/registration-service'
import { getMonthsToRegister } from '../student/course-page/modal-review-step-task/custom-date-picker/helper'
import { AuthService } from '../../../services/auth-service'
import { studentSuccessNotyTemplate } from '../../../config'
import './start-page.css'

const registrationService = new RegistrationService()

const StartPage = ({ history }) => {
  const { t, i18n } = useTranslation()

  const [locale, setLocale] = useState(``)
  const [userFirstName, setUserFirstName] = useState(``)
  const [userLastName, setUserLastName] = useState(``)
  const [userEmail, setUserEmail] = useState(``)
  const [userPassword, setUserPassword] = useState(``)
  const [dayVal, setDayVal] = useState(1)
  const [monthVal, setMonthVal] = useState(1)
  const [yearVal, setYearVal] = useState(new Date().getFullYear())
  const [showErrorBlock, setShowErrorBlock] = useState(false)
  const [errorMessage, setErrorMessage] = useState(``)
  const [inviteBtnText, setInviteBtnText] = useState(``)
  const [disabledInviteBtn, setDisabledInviteBtn] = useState(false)

  useEffect(() => {
    const arr = window.location.pathname.toLowerCase().split('/')
    if (arr[arr.length - 1] === 'en') {
      i18n.changeLanguage('en').then(() => console.log('Change language on English'))
      setLocale('en')
    } else {
      i18n.changeLanguage('ru').then(() => console.log('Change language on Russia'))
      setLocale('ru')
    }

    setInviteBtnText(t('firstCallSignUp'))
    setErrorMessage(t('registrationEmptyFields'))
  }, [])

  const daysMonth = days.map(day => {
    return (
      <option key={`day${day}`} value={day}>
        {day}
      </option>
    )
  })

  const nameMonth = getMonthsToRegister(t).map((month, number) => {
    return (
      <option key={`month${month}`} value={number + 1}>
        {month}
      </option>
    )
  })

  const dateYears = years.map(year => {
    return (
      <option key={`year${year}`} value={year}>
        {year}
      </option>
    )
  })

  const getUserData = () => {
    return {
      firstName: userFirstName,
      lastName: userLastName,
      email: userEmail,
      password: userPassword,
      birthday: '',
    }
  }

  const inviteUser = () => {
    let userDayVal = dayVal
    let userMonthVal = monthVal
    const userYearVal = yearVal
    const birthDay = new Date(userYearVal, userMonthVal - 1, userDayVal)

    if (userYearVal === birthDay.getFullYear()) {
      setErrorMessage(t('firstCallErrorBirthday'))
      setShowErrorBlock(true)
      return
    }

    if (userDayVal < 10) {
      userDayVal = `0${userDayVal}`
    }

    if (userMonthVal < 10) {
      userMonthVal = `0${userMonthVal}`
    }

    const user = {
      ...getUserData(),
      birthday: `${userDayVal}.${userMonthVal}.${userYearVal}`,
    }

    if (user.firstName === '' || user.secondName === '' || user.password === '' || user.email === '') {
      setShowErrorBlock(true)
      return
    }

    const showErrorMessage = message => {
      setErrorMessage(message)
      setShowErrorBlock(true)
    }

    const regexFirstName = /^[A-ZА-ЯЁ][a-zа-яё]+$/
    if (!regexFirstName.test(user.firstName)) {
      showErrorMessage(t('firstCallErrorName'))
      return
    }

    const regexLastName = /^[A-ZА-ЯЁ][a-zа-яё]+(?:[- ][A-ZА-ЯЁa-zа-яё]+)*$/
    if (!regexLastName.test(user.lastName)) {
      showErrorMessage(t('firstCallErrorSurname'))
      return
    }

    if (user.password.length < 6) {
      showErrorMessage(t('firstCallErrorPassword'))
      return
    }

    const regexEmail = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z]{2,4})+$/
    if (!regexEmail.test(user.email)) {
      showErrorMessage(t('firstCallErrorEmail'))
      return
    }

    setInviteBtnText(t('firstCallButtonTextProcess'))
    setDisabledInviteBtn(true)

    setTimeout(() => {
      setShowErrorBlock(false)
      registrationService
        .registrationStudent(user, locale)
        .then(() => {
          AuthService.login({
            password: userPassword,
            username: userEmail,
            rememberMe: true,
          }).then(() => {
            history.push('/user/courses')
            store.addNotification({
              ...studentSuccessNotyTemplate,
              message: `${t('YouAreSuccessfullyRegistered')}`,
            })
          })
        })
        .catch(({ text }) => {
          setInviteBtnText(t('firstCallSignUp'))
          setDisabledInviteBtn(false)
          showErrorMessage(text)
        })
    }, 200)
  }

  return (
    <div className="start-page">
      <title>{t('registration')}</title>
      <div className="block-logo">
        <img className="logo" src="/images/java-mentor-logo.png" alt="KataAcademy Logo" />
      </div>
      <div className="block-title">
        <h1 className="title">{t('signUpForm')}</h1>
        <p className="subtitle">{t('createYourAccount')}</p>
        <p className="quote">{t('startPageSubtitle')}</p>
      </div>
      <div className="block-data">
        <div className="form-group">
          <label className="label-for-input">{t('firstCallName')}</label>
          <input
            className="form-control input-data"
            id="user-first-name"
            required="required"
            value={userFirstName}
            onChange={event => setUserFirstName(event.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="label-for-input">{t('firstCallSurname')}</label>
          <input
            className="form-control input-data"
            id="user-last-name"
            required="required"
            value={userLastName}
            onChange={event => setUserLastName(event.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="label-for-input">{t('firstCallEmail')}</label>
          <input
            className="form-control input-data"
            type="email"
            id="user-email"
            required="required"
            value={userEmail}
            onChange={event => setUserEmail(event.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="label-for-input">{t('firstCallPassword')}</label>
          <input
            className="form-control input-data"
            type="password"
            id="user-password"
            required="required"
            value={userPassword}
            onChange={event => setUserPassword(event.target.value)}
          />
        </div>
        <div className="block-birthday">
          <label className="label-for-input">{t('Birthday')}</label>
          <div className="block-date">
            <div className="form-group">
              <select
                value={dayVal}
                onChange={event => setDayVal(event.target.value)}
                className="form-control"
                id="day"
              >
                {daysMonth}
              </select>
            </div>

            <div className="form-group block-month">
              <select
                value={monthVal}
                onChange={event => setMonthVal(event.target.value)}
                className="form-control"
                id="month"
              >
                {nameMonth}
              </select>
            </div>

            <div className="form-group">
              <select
                value={yearVal}
                onChange={event => setYearVal(event.target.value)}
                className="form-control"
                id="year"
              >
                {dateYears}
              </select>
            </div>
          </div>
        </div>

        {showErrorBlock && (
          <div className="alert alert-danger" id="div_error">
            <span>
              <p id="div_error_text">{errorMessage}</p>
            </span>
          </div>
        )}

        <Button variant="primary" id="invite" disabled={disabledInviteBtn} onClick={inviteUser}>
          {inviteBtnText}
        </Button>
      </div>
    </div>
  )
}

export default StartPage
