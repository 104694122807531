import React, { useEffect, useState } from 'react'

import UserProfileService from 'src/services/common-services/user-profile-service'
import { PaymentType } from 'src/model/payment-type-enum'
import Modal from 'react-modal'
import './styles.scss'

Modal.setAppElement('#root')
const userProfileService = new UserProfileService()

export const UserWithoutMentor = () => {
  const [showNotification, setShowNotification] = useState(false)
  console.log('-> showNotification', showNotification)

  useEffect(() => {
    userProfileService
      .getUserPrincipal()
      .then(({ paymentType, hasMentor }) => {
        const isUserWithoutMentor = paymentType === PaymentType.UNTIL_PAID && !hasMentor
        setShowNotification(isUserWithoutMentor)
      })
      .catch(() => console.error('Не удалось загрузить профиль пользователя'))
  }, [])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    if (showNotification) setShowNotification(true)
  }, [showNotification])

  return (
    <Modal isOpen={showNotification} className="notification-modal" overlayClassName="notification-overlay">
      <h4 className="notification-modal_title">Наши поздравления! Ты зачислен!</h4>
      <div className="notification-modal_inner-wrapper">
        <p className="notification-modal_about">
          Ты уже зачислен на учебу. Чтобы получить полный доступ к платформе, дождись распределения на поток.
          Распределение произойдет после первого совместного звонка с потоком. Информацию о дате и времени пришлем на
          почту.
        </p>
      </div>
      <button className="notification-modal_close-btn" onClick={() => setShowNotification(false)} type="button">
        Закрыть
      </button>
    </Modal>
  )
}
