import { Dispatch, SetStateAction, useEffect } from 'react'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'
import FakeOpenService from '../../../../services/fake-page-services/open/fake-open-service'

const fakeOpenService = new FakeOpenService()

export const useOpenFakeAvailability = (
  role: RoleEnum | undefined,
  taskType: string | undefined,
  courseTaskId: number,
  setAvailability: Dispatch<SetStateAction<Partial<boolean>>>
) => {
  const { catchErrorAlert } = useReactAlert()
  useEffect(() => {
    if (courseTaskId !== 0) {
      switch (taskType) {
        case 'mentorCheckTask':
          if (role === RoleEnum.MENTOR) {
            setAvailability(true)
          } else {
            fakeOpenService
              .isMentorCheckTaskAvailable(courseTaskId)
              // @ts-ignore
              .then(setAvailability)
              .catch(err => catchErrorAlert(err))
          }
          break
        case 'code':
          if (role === RoleEnum.MENTOR) {
            setAvailability(true)
          } else {
            fakeOpenService
              .isCodeTaskAvailable(courseTaskId)
              // @ts-ignore
              .then(setAvailability)
              .catch(err => catchErrorAlert(err))
          }
          break
        default:
      }
    }
  }, [courseTaskId])
}
