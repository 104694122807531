import React, { Dispatch, FC, useEffect } from 'react'
import Plyr from 'plyr'
import { useDispatch } from 'react-redux'
import { LectureTaskDto } from '../../../../models/service/Task/LectureTaskDto'
import Description from '../../Description'

const LectureTask: FC<IProps> = ({ lectureTask, onSolveTask, postSolveInterceptor }) => {
  let player: Plyr
  const dispatch = useDispatch()

  useEffect(() => {
    if (player) {
      player.destroy()
    }
    const { isSolved, taskPoints: points } = lectureTask

    onSolveTask()
      .then(() => {
        postSolveInterceptor(isSolved, points, dispatch)
        player = new Plyr('#player', {
          keyboard: { focused: true, global: false },
        })
      })
      .catch((error: any) => console.error(error.text))
  }, [lectureTask])

  const { description } = lectureTask
  return (
    <div className="task-content">
      <div className="video-block" />
      <div className="with-video-wrap-text">
        <div className="step-content-head" />
        <Description description={description} />
      </div>
    </div>
  )
}

export default LectureTask

interface IProps {
  lectureTask: LectureTaskDto
  onSolveTask: any
  postSolveInterceptor: (isSolved: boolean, points: number, dispatch: Dispatch<any>) => Promise<void>
}
