import React, { ReactNode } from 'react'
import classes from './index.module.scss'

const Layout = ({ children }: Props) => {
  return <div className={classes.layout}>{children}</div>
}

export default Layout

type View = ReactNode | ReactNode[] | string

interface Props {
  children: View
}
