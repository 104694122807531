import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import ReportCard from '../report-card'
import { StudentReportGetDto } from '../../../../../model/student-model'
import { AuthService } from '../../../../../services/auth-service'

import styles from './report-list.module.scss'

const cx = classNames.bind(styles)
type Props = {
  getStudentReports: () => void
  reports: StudentReportGetDto[]
}

const ReportList: FC<Props> = ({ reports, getStudentReports }) => {
  const [currentStudentId, setCurrentStudentId] = useState<number>()

  useEffect(() => {
    setCurrentStudentId(AuthService.currentUserValue()?.id)
  }, [])

  const renderList = reports.map(report => {
    return (
      <ReportCard
        key={report.id}
        report={report}
        currentStudentId={currentStudentId}
        getStudentReports={getStudentReports}
      />
    )
  })
  return <div className={cx('report-list')}>{renderList.length ? renderList : `Нет данных по вашему запросу`}</div>
}

export default ReportList
