import StudentCourseTaskInfoService from '../../services/student-course-task-info-service'

const getResetTaskCallbackStudent = (courseId: number, studentCourseTaskInfoId: number) => {
  const service = new StudentCourseTaskInfoService(courseId)
  return () => {
    return service.resetTask(studentCourseTaskInfoId)
  }
}

export default getResetTaskCallbackStudent
