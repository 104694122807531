import React, { useEffect, useState } from 'react'

import UserProfileService from 'src/services/common-services/user-profile-service'
import { ProfileDto } from 'src/model/user-dto/user-dto'
import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import { PaymentType } from 'src/model/payment-type-enum'
import './styles.scss'
import { registrationControllEnabled } from 'src/config'
import Modal from 'react-modal'

Modal.setAppElement('#root')
const userProfileService = new UserProfileService()
const HALF_MIN = 1000 * 30
export const UserWithoutRegistration = () => {
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<ProfileDto | null>()

  const handleUserProfile = (data: ProfileDto) => {
    const { role, hasBotRegistration } = data
    const isUserWithoutRegistration = role.name === RoleEnum.PAY_STUDENT && !hasBotRegistration
    setShowNotification(isUserWithoutRegistration)
    setCurrentUser(data)
  }

  useEffect(() => {
    if (registrationControllEnabled) {
      userProfileService
        .getUserPrincipal()
        .then(handleUserProfile)
        .catch(() => console.error('Не удалось загрузить профиль пользователя'))
    }
  }, [])

  useEffect(() => {
    if (registrationControllEnabled) {
      if (showNotification && currentUser) setShowNotification(true)
    }
  }, [showNotification, currentUser])

  useEffect(() => {
    if (registrationControllEnabled) {
      const interval = setInterval(() => {
        userProfileService.getUserPrincipal().then(handleUserProfile)
      }, HALF_MIN)
      return () => clearInterval(interval)
    }
    return () => {}
  }, [])

  const isUnitPaid = currentUser?.paymentType === PaymentType.UNTIL_PAID && currentUser?.registrationUrl

  return (
    <Modal isOpen={showNotification} className="notification-modal" overlayClassName="notification-overlay">
      <h4 className="notification-modal_title">Необходимо выполнить регистрацию в Discord</h4>
      <div className="notification-modal_inner-wrapper">
        {currentUser?.registrationUrl ? (
          <p className="notification-modal_about">
            <p>
              В Kata Academy для общения и обучения используется мессенджер Discord. Необходимо пройти регистрацию в
              Discord по данной{' '}
              <a target="_blank" href="https://discord.com/register" rel="noreferrer">
                ССЫЛКЕ
              </a>
              .
            </p>
            <p>
              При регистрации обязательно используй ту же почту, на которую ты получил письмо с логином и паролем от
              нашей платформы. Укажи настоящие имя и фамилию на РУССКОМ языке. Так же после регистрации, обязательно
              загрузи свой аватар (фото портретного размера, где видно лицо и плечи), чтобы в чате сразу было видно, кто
              пишет. После регистрации в Discord необходимо пройти по{' '}
              <a
                target="_blank"
                /*@ts-ignore*/
                href={currentUser?.registrationUrl}
                rel="noopener noreferrer"
              >
                ССЫЛКЕ
              </a>
              , для добавления на наш сервер Kata Academy.{' '}
            </p>
            {isUnitPaid ? (
              <p>
                Пройди регистрацию до общего созвона, чтобы мы тебя не ждали во время конференции, пожалуйста. Не забудь
                установить Discord на телефон (приложение так и называется в Google Play и AppStore), чтобы вы были
                всегда на связи.
              </p>
            ) : (
              <p>
                Не забудь установить Discord на телефон (приложение так и называется в Google Play и AppStore), чтобы ты
                был всегда на связи с ментором!
              </p>
            )}
          </p>
        ) : (
          <p className="notification-modal_about notification-modal_about--error">
            <p>УПС... что-то пошло не так!</p>
            <p>
              В Kata Academy для общения и обучения используется мессенджер Discord. Мы не смогли проверить твою
              регистрацию в Discord. Напиши нам в{' '}
              <a href="https://t.me/kata_askbot" target="_blank" rel="noreferrer noopener">
                Telegram
              </a>{' '}
              и наш клиентский сервис тебе поможет. Если тебе не ответили, напиши на почту info@kata.academy и мы
              оперативно тебе поможем.
            </p>
          </p>
        )}
      </div>
    </Modal>
  )
}
