import { useEffect, useState } from 'react'
import { AllCoursesView } from '../../../../model/student-model'
import StudentCourseService from '../../../../services/student-services/student-course-service'

export const useGetAdditionalCourses = (studentId: number) => {
  const studentCourseService = new StudentCourseService()
  const [additionalCourses, setAdditionalCourses] = useState<AllCoursesView[] | []>([])

  useEffect(() => {
    if (studentId === -1) {
      studentCourseService.getAdditionalCourses().then(res => {
        res.forEach(course => {
          /**
           * @todo Необходим position для дополнительных курсов, чтобы получать корректный ID курса (методы: getCourseIdByPos, getCourseByPosition)
           **/
          course.position = course.id
        })
        setAdditionalCourses(res)
      })
    }
  }, [studentId])

  return additionalCourses
}
