import React from 'react'
import { Route, Switch } from 'react-router-dom'
import StudentHeader from './components/header'
import StudentFooter from './components/footer'
import AllCoursesPage from './all-courses-page'
import CourseDetailsPage from './course-details-page'
import StatisticPage from './statistic-page'

import SolutionsHistory from './course-page/history-page/history-page'

import './student-page.css'
import ReviewPage from './review-page'
import FakeCoursePage from './course-page/fake-course-page'
import NotFound from '../../not-found'

const FakeStudentPage = () => {
  return (
    <>
      <Switch>
        <Route path="/fake/user/:studentId" component={props => <StudentHeader {...props} statusPage="fake" />} />
      </Switch>
      <Switch>
        <Route
          path="/fake/user/:studentId/statistic"
          exact
          component={props => <StatisticPage {...props} statusPage="fake" />}
        />
        <Route
          path="/fake/user/:studentId/review"
          exact
          component={props => <ReviewPage {...props} statusPage="fake" />}
        />
        <Route
          path="/fake/user/:studentId/courses"
          exact
          component={props => <AllCoursesPage {...props} statusPage="fake" />}
        />
        <Route
          path="/fake/user/:studentId/courses/:coursePosition"
          exact
          render={props => <CourseDetailsPage {...props} statusPage="fake" />}
        />
        <Route
          path="/fake/user/:studentId/courses/:coursePosition/:modulePosition/:chapterPosition/:courseTaskPosition"
          exact
          render={props => <FakeCoursePage {...props} />}
        />
        <Route
          path="/fake/user/:studentId/courses/:coursePosition/:modulePosition/:chapterPosition/:courseTaskPosition/solutions"
          exact
          render={props => <SolutionsHistory {...props} statusPage="fake" />}
        />
        <Route path="" component={NotFound} />
      </Switch>
      <StudentFooter />
    </>
  )
}

export default FakeStudentPage
