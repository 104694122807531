import React from 'react'
import Modal from 'react-modal'
import '../../../../../../../components/pages/student/student-page.css'
import './fake-exemplary-solution-modal.css'
import CodeEditor from '../../../../../../../components/code-editor'

const customStyles = {
  content: {
    left: '50%',
    top: 0,
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    padding: 30,
    margin: '44px 0',
    marginBottom: '44px',
    maxWidth: '1060px',
    width: '100%',
  },
  overlay: {
    backgroundColor: 'rgba(30, 30, 30, 0.54)',
  },
}

const FakeExemplarySolutionModal = ({ studentCode, modalShowed, onClose }) => {
  const code = studentCode === null ? 'null' : studentCode
  const codeClass = `code-editor success`
  return (
    <>
      <Modal isOpen={modalShowed} onRequestClose={onClose} style={customStyles}>
        <div className="solution-popup popup-window">
          <div className="solution-popup-head-wrap">
            <div className="solution-popup-head">Эталонное решение</div>
            <div>
              <button
                type="button"
                data-fancybox-close=""
                className="fancybox-close-small"
                title="Close"
                onClick={onClose}
              />
            </div>
          </div>
          <div className="solution-popup">
            <CodeEditor
              value={code}
              className={codeClass}
              language="java"
              options={{
                minLines: 10,
                readOnly: true,
              }}
            />
          </div>
          <div className="lesson-result-row" />
        </div>
      </Modal>
    </>
  )
}

export default FakeExemplarySolutionModal
