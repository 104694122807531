import qs from 'query-string'
import { HTTPMethods } from '../http-enums-methods'
import { StudentReportPostDto, StudentReportGetDto } from '../../model/student-model'
import { AuthService } from '../auth-service'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { StudentReportServiceInterface } from '../interfaces/student-services/student-report-service'
import { PageDto } from '../../model/page-dto'

class StudentReportService implements StudentReportServiceInterface {
  sourceURL = `${serverHost}/api/student/report`

  public getStudentReports = async (
    pageNumber: number = 1,
    items: number,
    onlySelf: boolean,
    query: string,
    startDate: string,
    endDate: string
  ) => {
    const queryOptions = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<PageDto<StudentReportGetDto>>(
      `${this.sourceURL}/page/${pageNumber}?${qs.stringify({
        items,
        onlySelf,
        query,
        startDate,
        endDate,
      })}`,
      queryOptions
    )
  }

  public addStudentReport = async (text: string, countHours: number, reportDate: string) => {
    const studentReportDtoRequestBody: StudentReportPostDto = {
      id: null,
      text,
      countHours,
      reportDate,
    }
    const queryOption = {
      method: HTTPMethods.POST,
      body: JSON.stringify(studentReportDtoRequestBody),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<void>(`${this.sourceURL}`, queryOption)
  }

  public updateStudentReport = async (reportId: number, text: string, countHours: number, reportDate: string) => {
    const studentReportDtoRequestBody: StudentReportPostDto = {
      id: reportId,
      text,
      countHours,
      reportDate,
    }
    const queryOption = {
      method: HTTPMethods.PUT,
      body: JSON.stringify(studentReportDtoRequestBody),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<StudentReportGetDto>(`${this.sourceURL}`, queryOption)
  }

  public deleteStudentReport = async (reportId: number) => {
    const queryOption = {
      method: HTTPMethods.DELETE,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<string>(`${this.sourceURL}/${reportId}`, queryOption)
  }
}

export default StudentReportService
