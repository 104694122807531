import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GappingTaskDto } from 'src/features/Course/models/service/Task/GappingTaskDto'
import Spinner from 'src/components/spinner'
import useLoadTaskCallback from '../../useLoadTaskCallback'
import classes from './index.module.scss'
import useIsSolved from './useIsSolved'
import useResetTaskCallback from '../../useResetTaskCallback'
import useSolveTaskCallback from '../../useSolveTaskCallback'
import useIsSolvedRight from '../../useIsSolvedRight'
import useCanBeSolved from './useCanBeSolved'
import mapGappingValuesToStudentAnswers from './mapGappingValuesToStudentAnswers'
import useFillGappingsCallback from './useFillGappingsCallback'
import useCurrentGappingValuesSetting from './useCurrentGappingValuesSetting'
import usePostSolveAnswerSetterCallback from './usePostSolveAnswerSetterCallback'
import Description from '../../Description'
import Category from '../../Category'
import Result from '../../Result'
import Actions from '../../Actions'

export interface Gapping {
  gappingItemId: number
  gappingItemPosition: number
  isRight?: boolean
}

interface Props {
  gappingTask: GappingTaskDto
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<GappingTaskDto>
  onResetTask: () => Promise<any>
  onSolveTask: any
  postResetInterceptor: (val: any, ...args: any[]) => any
}

export type GappingValues = Record<number, string>

const GappingTask: FC<Props> = ({ gappingTask, loadTask, onSolveTask, postResetInterceptor, onResetTask }) => {
  const { t } = useTranslation()
  const { text: taskText, description, studentGappingResults, taskGappings } = gappingTask || {}

  const [currentGappings, setCurrentGappings] = useState(
    studentGappingResults?.length ? studentGappingResults : taskGappings
  )

  const [loading, setLoading] = useState<Partial<Loading>>({})
  const [gappingValues, setGappingValues] = useState<GappingValues>({})
  const [isAnswersUpdatable, setIsAnswersUpdatable] = useState<boolean>(true)

  const isSolved = useIsSolved(gappingTask)
  const isSolvedRight = useIsSolvedRight(gappingTask, currentGappings, answer => {
    // @ts-ignore
    return answer.isRight || false
  })
  const canBeSolved = useCanBeSolved(gappingTask, gappingValues)

  const loadGappingTask = useLoadTaskCallback(setLoading, loadTask)

  const resetTaskLocal = useResetTaskCallback(setLoading, onResetTask, loadTask, postResetInterceptor, () =>
    setGappingValues({})
  )

  const resetGappingTask = () => {
    return resetTaskLocal()
  }

  const solveTaskLocal = useSolveTaskCallback(
    gappingTask,
    async () => {
      return onSolveTask(mapGappingValuesToStudentAnswers(gappingValues))
    },
    async skipLoading => loadGappingTask(skipLoading),
    canBeSolved,
    setLoading
  )

  const postSolveAnswerSetter = usePostSolveAnswerSetterCallback(setCurrentGappings, currentGappings)

  const solveGappingTask = () => {
    solveTaskLocal()
      // @ts-ignore
      .then(postSolveAnswerSetter)
      .then(() => setIsAnswersUpdatable(false))
  }

  useCurrentGappingValuesSetting(gappingTask, setGappingValues, isSolved as boolean, isAnswersUpdatable)
  const fillGappings = useFillGappingsCallback(gappingValues, setGappingValues)

  const filledText = useMemo(() => {
    return fillGappings(taskText, currentGappings, isSolved as boolean)
  }, [taskText, currentGappings, gappingValues, isSolved])

  if (loading.taskLoading) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="task-content">
      <div className="step-content-head">{t('GappingTask')}</div>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <Description description={description} />

      <Category label="Текст задания">
        <p style={{ whiteSpace: 'pre-line' }} className={classes.textWithGappings}>
          {filledText}
        </p>
      </Category>

      <div className="lesson-result-row">
        <Result isSolved={isSolved} isSolvedRight={isSolvedRight} />
        <Actions
          taskResettingLoading={loading.taskResetting}
          taskSolvingLoading={loading.taskSolving}
          canBeSolved={canBeSolved}
          isSolved={isSolved && isSolvedRight}
          resetTask={resetGappingTask}
          solveTask={solveGappingTask}
        />
      </div>
    </div>
  )
}

export interface Loading {
  taskSolving: boolean
  taskResetting: boolean
  taskLoading: boolean
}

export default GappingTask
