import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useReactAlert } from 'src/hooks/useReactAlert'
import StudentBreadCrumbs from '../../components/student-bread-crumbs'
import '../../student-page.css'
import RowHistorySolution from './row-history-solution/row-history-solution'
import StudentCourseTaskInfoService from '../../../../../services/student-services/student-course-task-info-service'
import StudentCourseTaskService from '../../../../../services/student-services/student-course-task-service'
import FakeOpenStudentCourseTaskService from '../../../../../services/fake-page-services/open/fake-open-student-course-task-service'
import UserCourseTaskService from '../../../../../services/common-services/user-course-task-service'
import UserChapterService from '../../../../../services/common-services/user-chapter-service'
import UserModuleService from '../../../../../services/common-services/user-module-service'
import StudentCourseService from '../../../../../services/student-services/student-course-service'
import FakeOpenStudentCourseService from '../../../../../services/fake-page-services/open/fake-open-student-course-service'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import { addAvailableCourseId } from '../../../../../store/available-courses/availableCoursesSlice'
import { AuthService } from '../../../../../services/auth-service'
import FakeOpenStudentCourseTaskInfoService from '../../../../../services/fake-page-services/open/fake-open-student-course-task-info-service'
import { generateCourseURL } from '../../../../../utils/generateCourseURL'
import { selectCourseType } from '../../../../../store/course-type/course-type.selectors'

const fakeStudentCourseService = new FakeOpenStudentCourseService()
const studentCourseService = new StudentCourseService()
const fakeStudentCourseTaskService = new FakeOpenStudentCourseTaskService()
const studentCourseTaskService = new StudentCourseTaskService()
const studentCourseTaskInfoService = new StudentCourseTaskInfoService()
const fakeOpenStudentCourseTaskInfoService = new FakeOpenStudentCourseTaskInfoService()
const userCourseTaskService = new UserCourseTaskService()
const userChapterService = new UserChapterService()
const userModuleService = new UserModuleService()

const SolutionsHistory = props => {
  const { catchErrorAlert } = useReactAlert()
  const courseType = useSelector(selectCourseType)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [tasksHeading, setTasksHeading] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [usersTasksSolutions, setUsersTaskSolutions] = useState([])
  const [sortByDate, setSortByDate] = useState('NEW')
  const [sortByResult, setSortByResult] = useState('ALL')
  const [courseTaskId, setCourseTaskId] = useState(0)
  const [courseId, setCourseId] = useState(0)
  const [chapterId, setChapterId] = useState(0)
  const [moduleId, setModuleId] = useState(0)
  const [principalRole, setPrincipalRole] = useState()
  const [principalId, setPrincipalId] = useState()
  const [studentCourseTaskInfoId, setStudentCourseTaskInfoId] = useState(0)
  const { availableCourseIds } = useSelector(({ availableCourses }) => availableCourses)
  const { statusPage = '', match, history } = props
  const { coursePosition, modulePosition, chapterPosition, courseTaskPosition, studentId = -1 } = match.params

  const currentStudentCourseService = statusPage !== 'fake' ? studentCourseService : fakeStudentCourseService
  const currentStudentCourseTaskInfoService =
    statusPage !== 'fake' ? studentCourseTaskInfoService : fakeOpenStudentCourseTaskInfoService
  const currentStudentCourseTaskService =
    statusPage !== 'fake' ? studentCourseTaskService : fakeStudentCourseTaskService

  document.title = `${t('SolutionHistory')}`

  useEffect(() => {
    setPrincipalRole(AuthService.currentUserValue().role.name)
  }, [])

  useEffect(() => {
    setPrincipalId(AuthService.currentUserValue().id)
  }, [])

  useEffect(() => {
    if (studentId !== -1 && principalRole === RoleEnum.PAY_STUDENT && !availableCourseIds.includes(courseId)) {
      fakeStudentCourseService.loadEnrollingState(courseId, principalId).then(isEnrolled => {
        if (isEnrolled) {
          dispatch(addAvailableCourseId({ courseId }))
        } else {
          history.push(generateCourseURL('/user/courses?param=forbidden', courseType))
        }
      })
    }
  }, [courseId])

  useEffect(() => {
    currentStudentCourseService.getCourseIdByPos(coursePosition, studentId).then(setCourseId)
    currentStudentCourseService
      .getTasksHeading(coursePosition, modulePosition, chapterPosition, courseTaskPosition, studentId)
      .then(heading => {
        setTasksHeading(heading)
        setLoaded(true)
      })
    currentStudentCourseService.getCourseIdByPos(coursePosition, studentId).then(setCourseId)
  }, [coursePosition, modulePosition, chapterPosition, courseTaskPosition])

  useEffect(() => {
    if (courseId * modulePosition !== 0) {
      userModuleService.getIdByCourseIdAndPosition(courseId, modulePosition).then(setModuleId)
    }
  }, [courseId, modulePosition])

  useEffect(() => {
    if (moduleId * chapterPosition !== 0) {
      userChapterService.getIdByModuleIdAndPosition(moduleId, chapterPosition).then(setChapterId)
    }
  }, [moduleId, chapterPosition])

  useEffect(() => {
    if (chapterId * courseTaskPosition !== 0) {
      userCourseTaskService.getIdByChapterIdAndPosition(chapterId, courseTaskPosition).then(setCourseTaskId)
    }
  }, [chapterId, courseTaskPosition])

  useEffect(() => {
    if (courseTaskId !== 0) {
      currentStudentCourseTaskService
        .getStudentCourseTaskIdByCourseTaskId(courseTaskId, studentId)
        .then(setStudentCourseTaskInfoId)
    }
  }, [courseTaskId])

  useEffect(() => {
    currentStudentCourseTaskInfoService
      .getUsersTaskSolutions(studentCourseTaskInfoId, sortByResult, sortByDate)
      .then(solutions => {
        setUsersTaskSolutions(solutions)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [studentCourseTaskInfoId, sortByResult, sortByDate])

  const sortByDateChange = event => {
    setSortByDate(event.target.value)
  }

  const sortByResultChange = event => {
    setSortByResult(event.target.value)
  }

  return loaded ? (
    <div>
      <StudentBreadCrumbs
        coursePosition={coursePosition}
        modulePosition={modulePosition}
        chapterPosition={chapterPosition}
        courseTaskPosition={courseTaskPosition}
        tasksHeading={tasksHeading}
        statusPage={statusPage}
        studentId={studentId}
      />
      <div className="solutions-wrapper">
        <div className="container">
          <div className="solution-content ">
            <div className="solution-content-head">
              <span>{t('Solutions')}</span>{' '}
              <span
                className="return-to-lesson-link"
                onClick={() => {
                  history.push(
                    generateCourseURL(
                      statusPage !== 'fake'
                        ? `/user/courses/${coursePosition}/${modulePosition}/${chapterPosition}/${courseTaskPosition}`
                        : `/fake/user/${studentId}/courses/${coursePosition}/${modulePosition}/${chapterPosition}/${courseTaskPosition}`,
                      courseType
                    )
                  )
                }}
              >
                {t('ReturnToTask')}
              </span>
            </div>

            <div className="solution-sort-wrap">
              <Row>
                <Col>
                  <div className="select-wrap">
                    <select className="sort-select" onChange={sortByResultChange}>
                      <option name="by-result" value="ALL" defaultValue="ALL">
                        {t('AnyStatus')}
                      </option>
                      <option name="by-result" value="SUCCESS">
                        {t('TheRightSolution')}
                      </option>
                      <option name="by-result" value="FAILED">
                        {t('WrongSolution')}
                      </option>
                    </select>
                  </div>
                </Col>

                <Col>
                  <div className="select-wrap">
                    <select className="sort-select" onChange={sortByDateChange}>
                      <option name="by-date" value="NEW" defaultValue="NEW">
                        {t('NewFirst')}
                      </option>
                      <option name="by-date" value="OLD">
                        {t('FirstOld')}
                      </option>
                    </select>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="solutions-list">
              <table>
                <tbody>
                  {usersTasksSolutions.map(solution => (
                    <RowHistorySolution
                      id={solution.id}
                      key={`solution_${solution.id}`}
                      date={solution.date}
                      answer={solution.answer || solution.result}
                      result={solution.result}
                      right={solution.right}
                      codeLang={solution.codeLang}
                      type={solution.courseTask.task.type}
                      answerType={solution.answerType}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default withRouter(SolutionsHistory)
