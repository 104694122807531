import React, { useCallback } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { store } from 'react-notifications-component'
import { getAllTranslateMonths, getMonthsToRegister } from './custom-date-picker/helper'
import CustomDatePicker from './custom-date-picker/custom-date-picker'
import { studentErrorNotyTemplate, studentSuccessNotyTemplate } from '../../../../../config'
import StudentReviewService from '../../../../../services/student-services/student-review-service'

import './modal-review-step-task.css'

const customStyles = {
  content: {
    left: '50%',
    top: '10%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    padding: 0,
    margin: '44px 0',
    width: '600px',
    maxHeight: '710px',
  },
  overlay: {
    overflow: 'scroll',
    backgroundColor: 'rgba(196, 196, 196, 0.5)',
    zIndex: 10,
  },
}

const ModalReviewStepTask = ({
  modalShowed,
  onClose,
  reviewEntryDto,
  date,
  setDate,
  setCurrentListTimeEntryDto,
  setDay,
  setMonth,
  setRecordedDay,
  currentListTimeEntryDto,
  month,
  recordedDay,
  day,
  selectedReviewId,
  setSelectedReviewId,
}) => {
  const { t } = useTranslation()
  const sprService = new StudentReviewService()

  const handleSelect = useCallback(
    id => () => {
      setSelectedReviewId(id)
    },
    []
  )

  const deleteFromReview = () => {
    if (selectedReviewId !== -1) {
      sprService.deleteStudentFromReviewByReviewId(selectedReviewId).then(r => {
        setSelectedReviewId(-1)
        onClose()
        setDay(-1)
        setDate(new Date())
        store.addNotification({
          ...studentSuccessNotyTemplate,
          message: `${t('YouCheckedOutWithAReview')}`,
        })
      })
    }
  }

  const registerOnReview = () => {
    if (selectedReviewId !== -1) {
      sprService
        .registerStudentOnReviewByReviewId(selectedReviewId, reviewEntryDto.moduleId)
        .then(r => {
          setSelectedReviewId(-1)
          onClose()
          store.addNotification({
            ...studentSuccessNotyTemplate,
            message: `${t('YouSignedUpForAReview')}`,
          })
        })
        .catch(({ code, text }) => {
          if (code === 400)
            store.addNotification({
              ...studentErrorNotyTemplate,
              message: text,
            })
        })
    }
  }

  const close = () => {
    setSelectedReviewId(-1)
    setDay(-1)
  }

  const times = currentListTimeEntryDto.map((time, idx) => {
    const timeArray = time.time.split('.')

    if (time.registered) {
      return (
        <div key={`time_${time.time}`} className="registeredInputTimeCustomDatePicker">
          {time.time} - {Number(timeArray[0]) + 1}.{timeArray[1]}
        </div>
      )
    }

    if (time.full) {
      return (
        <div className="disabledInputTimeCustomDatePicker">
          {time.time} - {Number(timeArray[0]) + 1}.{timeArray[1]} (нет мест)
        </div>
      )
    }

    return (
      <div
        key={`time_entry_${time.time}`}
        className={
          selectedReviewId === time.reviewId ? 'registeredInputTimeCustomDatePicker' : 'inputTimeCustomDatePicker'
        }
        onClick={handleSelect(time.reviewId)}
      >
        {time.time} - {Number(timeArray[0]) + 1}.{timeArray[1]}
      </div>
    )
  })

  return (
    <>
      <Modal isOpen={modalShowed} onRequestClose={onClose} style={customStyles}>
        {recordedDay === null && day === -1 ? (
          <>
            <div className="stat-courses-popup popup-window" style={{ width: 'initial' }}>
              <div className="popup-head">{t('RecordingOnReview')}</div>
              <button type="button" className="close-modal-button" onClick={onClose} />
              <div className="textCustomDatePicker">
                {t('ChooseAConvenientDateFromTheAvailableOnesTheyAreMarkedInGreen')}
              </div>
            </div>
            <div className="textCustomDatePicker">
              {date.getMonth() === new Date().getMonth() ? (
                <>
                  {getAllTranslateMonths(t)[date.getMonth()]} {date.getFullYear()}{' '}
                  <div>
                    <div
                      className="buttonModalReviewStepTask"
                      onClick={() => {
                        const d = new Date()
                        d.setMonth(d.getMonth() + 1)
                        d.setDate(1)
                        setDate(d)
                      }}
                    >
                      {getAllTranslateMonths(t)[date.getMonth() + 1]}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {getAllTranslateMonths(t)[date.getMonth()]} {date.getFullYear()}{' '}
                  <div>
                    <div
                      className="buttonModalReviewStepTask"
                      onClick={() => {
                        setDate(new Date())
                      }}
                    >
                      {getAllTranslateMonths(t)[date.getMonth() - 1]}
                    </div>
                  </div>
                </>
              )}
            </div>
            <CustomDatePicker
              dateNow={date}
              reviewEntryDto={reviewEntryDto}
              onCloseMainModal={onClose}
              setRecordedDay={setRecordedDay}
              setCurrentListTimeEntryDto={setCurrentListTimeEntryDto}
              setDay={setDay}
              setMonth={setMonth}
            />{' '}
          </>
        ) : (
          <>
            <div className="stat-courses-popup popup-window" style={{ width: 'initial' }}>
              <div className="popup-head">{t('RecordingOnReview')}</div>
              <button
                type="button"
                className="close-modal-button"
                onClick={() => {
                  onClose()
                }}
              />
              {recordedDay ? (
                <div className="textCustomDatePicker">
                  {t('MyRecordOn')} {day} {getMonthsToRegister(t)[month]}
                </div>
              ) : (
                <div className="textCustomDatePicker">
                  {t('ChooseAConvenientTimeFor')} {day} {getMonthsToRegister(t)[month + 1]}
                </div>
              )}
              <div className="modalBlockCustomDatePicker">
                <div>{times}</div>
                {recordedDay ? (
                  <div>
                    <button type="submit" className="btnModalRefuseCustomDatePicker" onClick={deleteFromReview}>
                      {t('OptOut')}
                    </button>
                  </div>
                ) : (
                  <div>
                    <button type="button" className="btnModalBackCustomDatePicker" onClick={close}>
                      {t('ToDateSelection')}
                    </button>
                    <button type="button" className="btnModalRegisterCustomDatePicker" onClick={registerOnReview}>
                      {t('SignUp')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

export default ModalReviewStepTask
