import React, { useEffect, useState } from 'react'
import './review-page.css'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useReactAlert } from 'src/hooks/useReactAlert'
import ListReviewStudent from './list-review-student'
import StudentReviewService from '../../../../services/student-services/student-review-service'
import { deleteItemByIndex, getIndexByKeyValue } from '../../../../utils/ArraysUtils'
import ConfirmModalReview from './confirm-modal'
import DetailsModalReview from './details-modal'
import FakeStudentReviewService from '../../../../services/fake-page-services/fake-student-review-service'

const studentReviewService = new StudentReviewService()
const fakeStudentReviewService = new FakeStudentReviewService()

const ReviewPage = ({ statusPage = '', match: { params: { studentId = -1 } = {} } = {} }) => {
  const { catchErrorAlert } = useReactAlert()
  const reviewService = statusPage === 'fake' ? fakeStudentReviewService : studentReviewService
  const { t } = useTranslation()
  document.title = `${t('YourReview')}`
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(14, 'days')
      .toDate()
  )
  const [endDate, setEndDate] = useState(
    moment()
      .add(14, 'days')
      .toDate()
  )
  const [reviews, setReviews] = useState([])
  const [filter, setFilter] = useState(null)
  const [selectReviewDetails, setSelectReviewDetails] = useState('')
  const [selectReviewFuture, setSelectReviewFuture] = useState('')
  const [codeTask, setCodeTask] = useState([])
  const [theoryTask, setTheoryTask] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)
  const [showDetails, setShowDetails] = useState(false)

  const ListReview = () => {
    if (startDate !== null && endDate !== null) {
      let reviewsPromise
      if (statusPage === 'fake') {
        reviewsPromise = reviewService.getStudentReviews(
          format(startDate, 'dd.MM.yyyy'),
          format(endDate, 'dd.MM.yyyy'),
          filter,
          studentId
        )
      } else {
        reviewsPromise = reviewService.getStudentReviews(
          format(startDate, 'dd.MM.yyyy'),
          format(endDate, 'dd.MM.yyyy'),
          filter
        )
      }
      reviewsPromise.then(reviewsEntity => setReviews(reviewsEntity)).catch(error => catchErrorAlert(error))
    }
  }
  const LoadTaskCodeAndTheoryTask = () => {
    const { studentReviewId } = selectReviewDetails
    if (studentReviewId !== undefined) {
      reviewService
        .getAllTheoryTaskByStudentReview(studentReviewId)
        .then(theoryTasks => setTheoryTask(theoryTasks))
        .catch(error => catchErrorAlert(error))
      reviewService
        .getAllOpenStudentTaskByStudentReviewId(studentReviewId)
        .then(codeTasks => setCodeTask(codeTasks))
        .catch(error => catchErrorAlert(error))
    }
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    ListReview()
  }, [startDate, endDate, filter])

  useEffect(() => {
    LoadTaskCodeAndTheoryTask()
  }, [selectReviewDetails.studentReviewId])

  const deleteReview = studentReviewId => {
    studentReviewService
      .deleteReviewById(studentReviewId)
      .then(() => {
        setReviews(deleteItemByIndex(reviews, getIndexByKeyValue(reviews, 'studentReviewId', studentReviewId)))
        setShowConfirm(false)
      })
      .catch(error => catchErrorAlert(error))
  }

  return (
    <>
      <ConfirmModalReview
        show={showConfirm}
        onClose={() => setShowConfirm(false)}
        onDeleteReview={id => deleteReview(id)}
        studentReview={selectReviewFuture}
      />
      <DetailsModalReview
        show={showDetails}
        onClose={() => setShowDetails(false)}
        studentReview={selectReviewDetails}
        codeTask={codeTask}
        theoryTask={theoryTask}
      />
      <div className="middle-header review">
        <div className="container">
          <div className="page-title-wrap">
            <div className="page-title">{t('GroupReview')}</div>
          </div>
        </div>
      </div>

      <div className="review-wrap">
        <div className="container">
          <div className="review-block">
            <div className="header-review">{statusPage !== 'fake' ? t('YourReview') : t('ReviewsOfStudent')}</div>
            <div className="review-table">
              <div className="filter-block">
                <div className="filter-review">
                  <span>{t('Filter')}</span>
                  <div className="combo-box-review">
                    <select
                      className="form-control-review"
                      onChange={event => setFilter(event.target.value === 'ALL' ? null : event.target.value)}
                    >
                      <option value="ALL">{t('AllReview')}</option>
                      <option value="SUCCESS">{t('PassedReviews')}</option>
                      <option value="FAILED">{t('FailedReviews')}</option>
                      <option value="WAITED">{t('FutureReviews')}</option>
                    </select>
                  </div>
                </div>

                <div className="between-date">
                  <div className="review-date-input-block">
                    <span>{t('ReviewPeriodWith')}</span>
                    <div className="start-date">
                      <input
                        type="date"
                        value={startDate.toISOString().substring(0, 10)}
                        className="ScheduleSettings__date"
                        onChange={event => {
                          const inputValue = event.target.value
                          if (inputValue) {
                            setStartDate(new Date(inputValue))
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="review-date-input-block">
                    <span>{t('By')}</span>
                    <div className="end-date">
                      <input
                        type="date"
                        value={endDate.toISOString().substring(0, 10)}
                        className="ScheduleSettings__date"
                        onChange={event => {
                          const inputValue = event.target.value
                          if (inputValue) {
                            setEndDate(new Date(inputValue))
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <ListReviewStudent
                entities={reviews}
                onSelectReviewDetails={reviewDetails => {
                  setSelectReviewDetails(reviewDetails)
                  setShowDetails(true)
                }}
                onSelectReviewFuture={reviewFuture => {
                  if (statusPage !== 'fake') {
                    setSelectReviewFuture(reviewFuture)
                    setShowConfirm(true)
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReviewPage
