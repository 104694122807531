import StudentCourseTaskInfoService from '../../../../services/student-services/student-course-task-info-service'

const service = new StudentCourseTaskInfoService()

const getResetTaskCallbackStudent = (studentCourseTaskInfoId: number) => {
  return () => {
    return service.resetTask(studentCourseTaskInfoId)
  }
}

export default getResetTaskCallbackStudent
