import React, { ReactNode } from 'react'
import ReactContentLoader from 'react-content-loader'

const ContentLoader = ({
  height,
  width,
  children,
  backgroundColor = '#f3f3f3',
  foregroundColor = '#dedede',
}: Props) => {
  return (
    <ReactContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      {children}
    </ReactContentLoader>
  )
}

export default ContentLoader

interface Props {
  height: number
  width: number
  children: ReactNode | ReactNode[]
  backgroundColor?: string
  foregroundColor?: string
}
