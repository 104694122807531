import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './student-header.css'
import { useTranslation } from 'react-i18next'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { adminServerHost } from 'src/config.ts'
import { history, showChangeLanguageSelect, showReportForStudent } from '../../../../../config'
import NotificationBlock from './notification-block/notification-block'
import { AuthService } from '../../../../../services/auth-service'
import LocaleService from '../../../../../services/locale-service'

import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import { getIndexByKeyValue } from '../../../../../utils/ArraysUtils'
import FakePageService from '../../../../../services/fake-page-services/fake-page-service'
import UserProfileService from '../../../../../services/common-services/user-profile-service'
import FakeOpenPageService from '../../../../../services/fake-page-services/open/fake-open-page-service'
import { convertResponseImageUrl } from '../../../../../utils/convertResponseImageUrl'

const fakePageService = new FakePageService()
const fakeOpenPageService = new FakeOpenPageService()
const userProfileService = new UserProfileService()
const localeService = new LocaleService()

const StudentHeader = ({ hasError = false, statusPage = '', match: { params: { studentId = -1 } = {} } = {} }) => {
  const { catchErrorAlert } = useReactAlert()
  //for user page
  const { i18n, t } = useTranslation()
  const [active, setActive] = useState(false)
  const [uploadedImageURL, setUploadedImageURL] = useState('')
  const [selectSection, onChooseSection] = useState(1)
  const [locale, setLocale] = useState(i18n.language)
  const [principalRole, setPrincipalRole] = useState('')

  //for fake page
  const [mentorsStudents, setMentorsStudents] = useState([])
  const [mentorName, setMentorName] = useState('')
  const [singleStudent, setSingleStudent] = useState(null)
  const [isFound, setIsFound] = useState(true)

  //for fake-student page
  const [hostOfPage, setHostOfPage] = useState('')

  useEffect(() => {
    if (statusPage !== 'fake') {
      userProfileService
        .getUserPrincipal()
        .then(us => {
          if (us.avatarUrl) {
            setUploadedImageURL(convertResponseImageUrl(us.avatarUrl))
          }
        })
        .catch(err => catchErrorAlert(err))
    }
  }, [uploadedImageURL, statusPage])

  useEffect(() => {
    setPrincipalRole(AuthService.currentUserValue().role.name)
  }, [])

  useEffect(() => {
    const currentLocation = history.location.pathname

    if (/statistic/.test(currentLocation)) {
      onChooseSection(1)
    } else if (/courses/.test(currentLocation)) {
      onChooseSection(2)
    } else if (/review/.test(currentLocation)) {
      onChooseSection(3)
    } else if (/report/.test(currentLocation)) {
      onChooseSection(5)
    }
  }, [history.location])

  useEffect(() => {
    const listener = event => {
      // eslint-disable-next-line no-use-before-define
      handleClickOutSide(event)
    }

    if (statusPage !== 'fake') {
      window.addEventListener('click', listener)
    }

    return () => {
      window.removeEventListener('click', listener)
    }
  }, [statusPage])

  const handleClickOutSide = event => {
    const notificationBlock = document.getElementById(`profile-menu-wrap`)
    // eslint-disable-next-line no-shadow
    const path = event.path || (event.composedPath && event.composedPath())

    if (!path.includes(notificationBlock)) {
      const notifyBtn = document.getElementById(`profile-icn`)

      if (!path.includes(notifyBtn)) {
        setActive(false)
      }
    }
  }

  const changeLanguage = ({ target }) => {
    const newLocale = target.value
    setLocale(newLocale)
    i18n
      .changeLanguage(newLocale)
      .then(() => localeService.changeLocale(newLocale))
      .catch(err => catchErrorAlert(err))
  }

  //fake page
  const changeStudent = ({ target }) => {
    // eslint-disable-next-line no-restricted-globals
    history.push(history.location.pathname.replace(`${studentId}`, `${target.value}`))
  }

  const getAllStudents = student => {
    return <option key={student.id} value={student.id}>{`${student.firstName} ${student.lastName}`}</option>
  }

  const foundStudentFromList = () => {
    return getIndexByKeyValue(mentorsStudents, 'id', parseInt(studentId, 10))
  }

  const getSingleStudent = () => {
    if (mentorsStudents.length > 0 && !isFound) {
      fakePageService.getSingleUser(studentId).then(student => {
        setSingleStudent(student)
      })
    }
  }

  const getNameSingleStudent = () => {
    if (singleStudent) {
      return (
        <option key={singleStudent.id} value={singleStudent.id}>
          {`${singleStudent.firstName} ${singleStudent.lastName}`}
        </option>
      )
    }
    return ''
  }

  useEffect(() => {
    if (statusPage === 'fake' && principalRole && principalRole !== RoleEnum.PAY_STUDENT) {
      fakePageService
        .getStudents(studentId)
        .then(students => {
          setMentorsStudents(students)
        })
        .catch(err => catchErrorAlert(err))
    }
  }, [studentId, statusPage, principalRole])

  useEffect(() => {
    if (statusPage === 'fake' && principalRole && principalRole !== RoleEnum.PAY_STUDENT) {
      fakeOpenPageService
        .getMentorNameByStudentId(studentId)
        .then(name => setMentorName(name))
        .catch(err => catchErrorAlert(err))
    }
  }, [studentId, statusPage, principalRole])

  useEffect(() => {
    if (statusPage === 'fake' && mentorsStudents.length > 0) {
      setIsFound(foundStudentFromList() >= 0)
    }
  }, [mentorsStudents, statusPage])

  useEffect(() => {
    if (statusPage === 'fake') {
      getSingleStudent()
    }
  }, [isFound, statusPage])

  useEffect(() => {
    if (statusPage === 'fake' && principalRole === 'PAY_STUDENT') {
      fakeOpenPageService.getStudentNameByStudentId(studentId).then(name => {
        setHostOfPage(name)
      })
    }
  }, [studentId, principalRole])

  return (
    <div className="header">
      <div className="top-header">
        <div className="container">
          {statusPage === 'fake' ? (
            <Link to={`/fake/user/${studentId}/courses`} className="logo">
              <img src="/images/header-logo.svg" alt="logo" />
            </Link>
          ) : (
            <Link to="/user/courses" className="logo">
              <img src="/images/header-logo.svg" alt="logo" />
            </Link>
          )}
          <ul className="top-nav">
            <li className={selectSection === 1 ? 'active' : ' '}>
              {statusPage === 'fake' ? (
                <Link to={`/fake/user/${studentId}/statistic`}>{t('Main')}</Link>
              ) : (
                <Link to="/user/statistic">{t('Main')}</Link>
              )}
            </li>
            <li className={selectSection === 2 ? 'active' : ' '}>
              {statusPage === 'fake' ? (
                <Link to={`/fake/user/${studentId}/courses`}>{t('Study')}</Link>
              ) : (
                <Link to="/user/courses">{t('Study')}</Link>
              )}
            </li>

            <li className={selectSection === 3 ? 'active' : ' '}>
              {statusPage === 'fake' &&
              [RoleEnum.MENTOR, RoleEnum.ADMIN, RoleEnum.CHIEF_MENTOR].includes(principalRole) ? (
                <Link to={`/fake/user/${studentId}/review`}>{t('Review')}</Link>
              ) : (
                statusPage !== 'fake' && <Link to="/user/review">{t('Review')}</Link>
              )}
            </li>

            <li className={selectSection === 4 ? 'active' : ' '}>
              {statusPage === 'fake' ? (
                <a href={principalRole === RoleEnum.PAY_STUDENT ? '/' : adminServerHost}>{t('BackToYourDashboard')}</a>
              ) : null}
            </li>

            <li className={selectSection === 5 ? 'active' : ' '}>
              {statusPage !== 'fake' && showReportForStudent && <Link to="/user/report">{t('Reports')}</Link>}
            </li>
            {/* времменно скрыта */}
          </ul>

          {statusPage !== 'fake' && (
            <div className="header-right-wrap">
              <span className="change-language-selector">
                {showChangeLanguageSelect && (
                  <select
                    className="form-control"
                    defaultValue={locale === `ru` ? `ru` : `en`}
                    onChange={event => changeLanguage(event)}
                  >
                    <option value="ru">Русский</option>
                    <option value="en">English</option>
                  </select>
                )}
              </span>
              {!hasError && <NotificationBlock />}
              <div id="profile-icn" className="profile-icn" onClick={() => setActive(!active)}>
                <img src={uploadedImageURL === '' ? '/images/man.png' : uploadedImageURL} id="user_pic" alt="avatar" />
              </div>
              {active && (
                <div id="profile-menu-wrap" className="profile-menu-wrap">
                  <ul>
                    <li>
                      <Link to="/user/profile">{t('ChangeProfile')}</Link>
                    </li>
                    <li>
                      <Link to="/user/password">{t('ChangePassword')}</Link>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a type="button" onClick={() => AuthService.logout()}>
                        {t('Exit')}
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}

          {statusPage === 'fake' && (
            <div className="header-mentor-panel">
              {[RoleEnum.MENTOR, RoleEnum.ADMIN, RoleEnum.CHIEF_MENTOR, RoleEnum.CURATOR].includes(principalRole) && (
                <>
                  <div className="info">
                    <div className="info_select-wrapper">
                      <p className="info_text">Выбранный студент:</p>
                      <div className="info_select">
                        <select id="selectStudent" value={studentId} onChange={changeStudent}>
                          {getNameSingleStudent()}
                          <option value="undefined">Выберите студента</option>
                          {mentorsStudents.map(getAllStudents)}
                        </select>
                      </div>
                    </div>
                    <div className="info_mentor">
                      <p className="info_text">Ментор:</p>
                      <small className="info_text">{mentorName}</small>
                    </div>
                  </div>
                </>
              )}
              {[RoleEnum.PAY_STUDENT].includes(principalRole) && (
                <>
                  {hostOfPage ? <span className="pageHostLabel">{`${t('pageOfStudent')}: ${hostOfPage}`}</span> : null}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StudentHeader
