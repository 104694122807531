import ModuleDto from '../models/service/ModuleDto'
import { TaskLocation } from '../models/util/Location'
import getTasksByLocation from './getTasksByLocation'

const getTaskByLocation = (modules: ModuleDto[], taskLocation: TaskLocation) => {
  const { taskPosition, ...chapterLocation } = taskLocation
  const tasks = getTasksByLocation(modules, chapterLocation)

  return tasks?.[taskPosition - 1]
}

export default getTaskByLocation
