import { serverHost } from '../../../config'
import ApiService from '../../../services/api-service'
import { StudentCourseTaskServiceInterface } from './interfaces/student-course-task-service-interface'
import { TaskType } from '../models/service/Task/TaskDto'
import TaskDtoByType from '../models/service/Task/TaskDtoByType'

const Url = {
  ROOT: `${serverHost}/api/v2/student/course`,
}

const generateCourseTaskUrl = (courseId: number, taskId: number, taskType: TaskType): string => {
  return `${courseId}/task/${taskId}/${taskType}`
}

const requestStudentCourseTaskController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const StudentCourseTaskService: StudentCourseTaskServiceInterface = class StudentCourseTaskService {
  static getCourseTask = <Type extends TaskType>(courseId: number, taskId: number, taskType: TaskType) => {
    const fullUrl = generateCourseTaskUrl(courseId, taskId, taskType)
    return requestStudentCourseTaskController<TaskDtoByType[Type] | null>(fullUrl)
  }
}
