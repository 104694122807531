import React, { FC } from 'react'
import Highlight from 'react-highlight'

import './Description.css'

const Description: FC<Props> = React.memo(function TaskDescription({ description }) {
  return (
    <div className="lesson-question">
      <Highlight className="lesson-description" innerHTML>
        {description}
      </Highlight>
    </div>
  )
})

interface Props {
  description: string
}

export default Description
