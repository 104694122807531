import { TaskLocation } from '../models/util/Location'
import ModuleDto from '../models/service/ModuleDto'
import PositionUtil from './PositionUtil'
import ChapterV2Dto from '../models/service/ChapterV2Dto'
import TaskDto from '../models/service/Task/TaskDto'
import isNullish from '../../../utils/isNullish'
import { ResolveCoursePagePathFunction } from '../CoursePageAdapter'

export enum NeighbourType {
  Module = 'Module',
  Chapter = 'Chapter',
  Task = 'Task',
}

class LocationUtil {
  public static getNearestNeighbours<Module, Chapter, Task>(
    moduleNeighbours: Neighbours<Module> | undefined,
    chapterNeighbours: Neighbours<Chapter> | undefined
  ): Neighbours<Module | Chapter>

  public static getNearestNeighbours<Module, Chapter, Task>(
    moduleNeighbours: Neighbours<Module> | undefined,
    chapterNeighbours: Neighbours<Chapter> | undefined,
    taskNeighbours: Neighbours<Task> | undefined
  ): Neighbours<Module | Chapter | Task>

  public static getNearestNeighbours<Module, Chapter, Task>(
    moduleNeighbours: Neighbours<Module> | undefined,
    chapterNeighbours: Neighbours<Chapter> | undefined,
    taskNeighbours?: Neighbours<Task> | undefined
  ): Neighbours<Module | Chapter | Task> {
    return {
      prev: taskNeighbours?.prev ?? chapterNeighbours?.prev ?? moduleNeighbours?.prev,
      next: taskNeighbours?.prev ?? chapterNeighbours?.next ?? moduleNeighbours?.next,
    }
  }

  public static getNeighboursByTaskLocation(taskLocation: TaskLocation, modules: ModuleDto[] | undefined) {
    const { moduleIndex, chapterIndex, taskIndex } = PositionUtil.convertTaskLocationToTaskIndices(taskLocation)

    const currentModuleChapters = modules?.[moduleIndex]?.chapters
    const currentChapterTasks = currentModuleChapters?.[chapterIndex]?.tasks

    const taskNeighbours =
      currentChapterTasks && LocationUtil.getNeighbours(currentChapterTasks, taskIndex, NeighbourType.Task)
    const chapterNeighbours =
      currentModuleChapters && LocationUtil.getNeighbours(currentModuleChapters, chapterIndex, NeighbourType.Chapter)
    const moduleNeighbours = modules && LocationUtil.getNeighbours(modules, moduleIndex, NeighbourType.Module)

    const nearestNeighbours = LocationUtil.getNearestNeighbours(moduleNeighbours, chapterNeighbours, taskNeighbours)
    const nearestNeighboursWithoutTasks = LocationUtil.getNearestNeighbours(moduleNeighbours, chapterNeighbours)

    return {
      taskNeighbours,
      chapterNeighbours,
      moduleNeighbours,
      nearestNeighbours,
      nearestNeighboursWithoutTasks,
    }
  }

  public static isNeighbourOfType<Type extends NeighbourType>(
    neighbour: Neighbour<unknown>,
    type: Type
  ): neighbour is Neighbour<NeighbourItemByType[Type]> {
    return neighbour.type === type
  }

  public static getNeighbourPath(
    neighbours: Neighbours<ModuleDto | ChapterV2Dto | TaskDto> | undefined,
    courseId: number | undefined,
    modulePosition: number | undefined,
    chapterPosition: number | undefined,
    resolvePath: ResolveCoursePagePathFunction
  ) {
    return (type: 'prev' | 'next') => {
      if (!courseId || !neighbours || isNullish(modulePosition) || isNullish(chapterPosition)) {
        return undefined
      }

      const neighbour = neighbours[type]

      if (!neighbour) {
        return undefined
      }

      if (LocationUtil.isNeighbourOfType(neighbour, NeighbourType.Chapter)) {
        const tasksLength = neighbour.item.tasks.length
        const lastTaskIndex = tasksLength ? tasksLength - 1 : 0

        const newTaskPosition = PositionUtil.convertIndexToPosition(type === 'prev' ? lastTaskIndex : 0)

        return resolvePath(
          courseId,
          modulePosition,
          PositionUtil.convertIndexToPosition(neighbour.index),
          newTaskPosition
        )
      }

      if (LocationUtil.isNeighbourOfType(neighbour, NeighbourType.Module)) {
        const chaptersLength = neighbour.item.chapters.length
        const lastChapterIndex = chaptersLength ? chaptersLength - 1 : 0

        const chapterIndex = type === 'prev' ? lastChapterIndex : 0
        const chapter = neighbour.item.chapters[chapterIndex]

        const tasksLength = chapter?.tasks.length ?? 0
        const lastTaskIndex = tasksLength ? tasksLength - 1 : 0
        const taskIndex = type === 'prev' ? lastTaskIndex : 0

        const newChapterPosition = PositionUtil.convertIndexToPosition(chapterIndex)
        const newTaskPosition = PositionUtil.convertIndexToPosition(taskIndex)

        return resolvePath(
          courseId,
          PositionUtil.convertIndexToPosition(neighbour.index),
          newChapterPosition,
          newTaskPosition
        )
      }

      return undefined
    }
  }

  private static getNeighbours<Item>(array: Item[], index: number, type: NeighbourType): Neighbours<Item> {
    const prevIndex = index - 1
    const nextIndex = index + 1

    const prevItem = array[prevIndex]
    const nextItem = array[nextIndex]

    return {
      prev: prevItem && {
        index: prevIndex,
        item: prevItem,
        type,
      },
      next: nextItem && {
        index: nextIndex,
        item: nextItem,
        type,
      },
    }
  }
}

export default LocationUtil

export interface Neighbours<Item> {
  prev: Neighbour<Item> | undefined
  next: Neighbour<Item> | undefined
}

interface Neighbour<Item> {
  index: number
  item: Item
  type: NeighbourType
}

interface NeighbourItemByType {
  [NeighbourType.Module]: ModuleDto
  [NeighbourType.Chapter]: ChapterV2Dto
  [NeighbourType.Task]: TaskDto
}
