import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { Link, useHistory } from 'react-router-dom'
import CourseInfoV2Dto from '../../models/service/CourseInfoV2Dto'
import ModuleDto from '../../models/service/ModuleDto'
import { CoursePageLoading } from '../CoursePage'
import CourseInfoLoader from '../../components/CourseInfoLoader'
import Loader from '../../components/Loader'
import RectLoader from '../../components/RectLoader'
import ModulesLoader from '../../components/ModulesLoader'
import { ResolveCoursePagePathFunction } from '../../CoursePageAdapter'

const CourseDetailsPage = ({ modules, courseInfo, loading, resolvePath }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const resolveChapterPath = (modulePosition: number, chapterPosition: number) => {
    if (!courseInfo) {
      return '#'
    }

    return resolvePath(courseInfo.courseId, modulePosition, chapterPosition, 1)
  }

  return (
    <div>
      <div className="detail-course-info-wrap">
        <div className="detail-course-info-block">
          {loading.courseInfo && <CourseInfoLoader />}

          {!loading.courseInfo && courseInfo && (
            <>
              <div className="detail-course-text">{courseInfo.description}</div>

              <div className="right-wrap">
                <div className="info-block load">
                  <i className="mdi mdi-school" />
                  <div className="block-text">
                    <b>{t('Load')}:</b>
                    <span>{courseInfo.load}</span>
                  </div>
                </div>

                <div className="info-block time">
                  <i className="mdi mdi-clock" />
                  <div className="block-text">
                    <b>{t('CourseTime')}:</b>
                    <span>{courseInfo.duration}</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="detail-course-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-8 course-left-wrap">
              <div className="main-course-info sbox">
                <Tabs>
                  <TabList>
                    <div className="tabs-wrap">
                      <Tab selectedClassName="active" className="course-tab">
                        {t('Content')}
                      </Tab>
                    </div>
                  </TabList>
                  <TabPanel selectedClassName="active" className="tab-content">
                    <div className="course-lessons">
                      <Loader loader={ModulesLoader} loading={loading.modules}>
                        {modules?.map((module, moduleIndex) => (
                          <table className="lesson-table" key={module.moduleId}>
                            <thead>
                              <tr>
                                <td colSpan={3}>
                                  {moduleIndex + 1} {module.name}
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {module.chapters.map((chapter, chapterIndex) => (
                                <tr key={chapter.chapterId}>
                                  <td>
                                    <Link to={resolveChapterPath(moduleIndex + 1, chapterIndex + 1)}>
                                      {moduleIndex + 1}.{chapterIndex + 1} {chapter.name}
                                    </Link>
                                  </td>
                                  <td>
                                    {chapter.solvedTaskAmount}/{chapter.allTaskAmount}
                                  </td>
                                  <td>
                                    {chapter.solvedTaskAmount === chapter.allTaskAmount && (
                                      <i className="completed mdi mdi-check-circle" />
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ))}
                      </Loader>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>

            <div className="col-md-4">
              <div className="about-author sbox">
                <div className="about-author-title">{t('AboutCourseAuthor')}</div>
                <div className="author-wrap">
                  <div className="author-detail">
                    <div className="author-name">
                      <Loader loader={<RectLoader width={170} />} loading={loading.courseInfo}>
                        {courseInfo?.author.id}
                      </Loader>
                    </div>
                    <div className="author-detail-info">
                      <Loader loader={<RectLoader width={250} />} loading={loading.courseInfo}>
                        {courseInfo?.author.email}
                      </Loader>
                    </div>
                  </div>
                </div>
                <div className="author-text">
                  <Loader
                    loader={
                      <>
                        <RectLoader width={330} />
                        <RectLoader width={200} />
                      </>
                    }
                    loading={loading.courseInfo}
                  >
                    {courseInfo?.author.about}
                  </Loader>
                </div>
              </div>

              <div className="requirements-wrap sbox">
                <div className="req-wrap">
                  <div className="req-title">{t('Requirements')}</div>
                  <div id="requirement" className="req-text">
                    <Loader
                      loader={
                        <>
                          <RectLoader width={330} />
                          <RectLoader width={200} />
                        </>
                      }
                      loading={loading.courseInfo}
                    >
                      {courseInfo?.requirements}
                    </Loader>
                  </div>
                </div>

                <div className="target-wrap">
                  <div className="target-title">{t('TheTargetAudience')}</div>
                  <div className="target-text">
                    <Loader
                      loader={
                        <>
                          <RectLoader width={330} />
                          <RectLoader width={200} />
                        </>
                      }
                      loading={loading.courseInfo}
                    >
                      {courseInfo?.targetAudience}
                    </Loader>
                  </div>
                </div>
              </div>
              {/*{!isEnrolling && statusPage !== 'fake' && (*/}
              {/*  <button type="button" className="select-course-btn" onClick={enrollInCourse}>*/}
              {/*    <i className="mdi mdi-check" />*/}
              {/*    <span>Поступить на курс</span>*/}
              {/*  </button>*/}
              {/*)}*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseDetailsPage

interface Props {
  modules: ModuleDto[] | undefined
  courseInfo: CourseInfoV2Dto | undefined
  loading: Partial<CoursePageLoading>
  resolvePath: ResolveCoursePagePathFunction
}
