import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useSelector } from 'react-redux'

import { useTranslation, withTranslation } from 'react-i18next'
import { Icon } from '@iconify/react'
import lockOutline from '@iconify/icons-mdi/lock-outline'
import StudentCourseService from '../../../../services/student-services/student-course-service'
import StudentBreadCrumbs from '../components/student-bread-crumbs'
import { AuthService } from '../../../../services/auth-service'
import UserCourseService from '../../../../services/common-services/user-course-service'
import FakeOpenStudentCourseService from '../../../../services/fake-page-services/open/fake-open-student-course-service'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'
import FakeProtectEnrollService from '../../../../services/fake-page-services/fake-protect-enroll-service'
import MentorReviewService from '../../../../services/mentor-services/mentor-review-service'

import { generateCourseURL } from '../../../../utils/generateCourseURL'
import { selectCourseType } from '../../../../store/course-type/course-type.selectors'

import style from './course-detail-page.module.scss'
import { availableRolesForCompleteCourse } from '../components/course-element/course-element'
import store from '../../../../store'
import { convertResponseImageUrl } from '../../../../utils/convertResponseImageUrl'

const fakeStudentCourseService = new FakeOpenStudentCourseService()
const mentorEnrollService = new FakeProtectEnrollService()
let studentCourseService = new StudentCourseService()
const userCourseService = new UserCourseService()
const mentorReviewService = new MentorReviewService()

store.subscribe(() => {
  const { courseType } = store.getState().courseType

  if (studentCourseService.courseType === courseType) {
    return
  }

  studentCourseService = new StudentCourseService(courseType)
})

const CourseContent = ({
  moduleViewDtos,
  coursePosition,
  isEnrolling,
  statusPage = '',
  studentId = -1,
  courseId,
  setReloadDto,
}) => {
  const courseType = useSelector(selectCourseType)
  const [principalRole, setPrincipalRole] = useState(RoleEnum.EMPTY)
  const enrollStudentModule = moduleId => {
    mentorEnrollService.enrollStudentModule(courseId, moduleId, studentId).then(() => {
      setReloadDto(reload => !reload)
    })
  }
  const discardReview = moduleId => {
    mentorReviewService.discardReview(moduleId, studentId).then(() => {
      setReloadDto(reload => !reload)
    })
  }

  useEffect(() => {
    setPrincipalRole(!AuthService.currentUserValue() ? RoleEnum.EMPTY : AuthService.currentUserValue().role.name)
  }, [])

  const isNotAvailableToEnroll = (allTaskAmount, solvedTaskAmount, moduleId) => {
    if (allTaskAmount !== 0 && solvedTaskAmount === allTaskAmount) {
      return (
        <span style={{ float: 'right' }}>
          <button className={style.moduleBtnReset} type="button" onClick={() => discardReview(moduleId)}>
            Сбросить ревью
          </button>
        </span>
      )
    }
    return (
      <span style={{ float: 'right' }}>
        <button className={style.moduleBtnDone} type="button" onClick={() => enrollStudentModule(moduleId)}>
          Сделать выполненным
        </button>
      </span>
    )
  }

  return (
    <div className="course-lessons">
      {moduleViewDtos.map(moduleViewDto => {
        const {
          chapterViewDtos,
          blocked,
          moduleId: id,
          position: modulePosition,
          allTaskAmount: allAmount,
          solvedTaskAmount: solveAmount,
        } = moduleViewDto
        return (
          <table className="lesson-table" key={`module_${modulePosition}`}>
            <thead>
              <tr>
                <td colSpan="3">
                  {`${moduleViewDto.position} ${moduleViewDto.name}`}
                  {availableRolesForCompleteCourse.includes(principalRole) &&
                    isNotAvailableToEnroll(parseInt(allAmount, 10), parseInt(solveAmount, 10), id)}
                </td>
              </tr>
            </thead>
            <tbody>
              {chapterViewDtos.map(({ position, allTaskAmount, solvedTaskAmount, name, chapterId }) => {
                const disabledClass = allTaskAmount === 0 ? 'chapter-disabled' : ''

                const taskLink =
                  statusPage !== 'fake'
                    ? `/user/courses/${coursePosition}/${modulePosition}/${position}/1`
                    : `/fake/user/${studentId}/courses/${coursePosition}/${modulePosition}/${position}/1`
                return (
                  <tr key={`chapter_indicate_${chapterId}`}>
                    <td className={disabledClass}>
                      {blocked || !isEnrolling ? (
                        <span>{`${modulePosition}.${position} ${name}`}</span>
                      ) : (
                        <Link
                          to={generateCourseURL(taskLink, courseType)}
                        >{`${modulePosition}.${position} ${name}`}</Link>
                      )}
                    </td>
                    {blocked ? (
                      <td className={disabledClass}>
                        <Icon
                          icon={lockOutline}
                          style={{
                            color: '#f3616f',
                            fontSize: '18px',
                          }}
                        />
                      </td>
                    ) : (
                      <>
                        <td className={disabledClass}>{`${solvedTaskAmount}/${allTaskAmount}`}</td>
                        <td className={disabledClass}>
                          {allTaskAmount === solvedTaskAmount && <i className="completed mdi mdi-check-circle" />}
                        </td>
                      </>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        )
      })}
    </div>
  )
}

const CourseDetailsPage = ({
  statusPage = '',
  history,
  match: { params: { coursePosition, studentId = -1 } = {} } = {},
}) => {
  const courseType = useSelector(selectCourseType)
  const { t } = useTranslation()
  const [moduleViewDtos, setModuleViewDtos] = useState([])
  const [courseInfo, setCourseInfo] = useState({})
  const [courseAuthor, setCourseAuthor] = useState({})
  const [isEnrolling, setIsEnrolling] = useState(true)
  const [reloadDto, setReloadDto] = useState(true)
  const [courseId, setCourseId] = useState(0)
  const [currentUserId] = useState(AuthService.currentUserValue().id)
  const [currentUserRole] = useState(AuthService.currentUserValue().role.name)

  const { target, demands, description, filling, transitTime, coursePicUrl } = courseInfo
  const { name, email, about } = courseAuthor

  useEffect(() => {
    if (studentId === -1 && courseId === 0) {
      studentCourseService.getCourseIdByPos(coursePosition).then(requestedCourseId => {
        setCourseId(requestedCourseId)
      })
    } else if (courseId === 0) {
      fakeStudentCourseService.getCourseIdByPos(coursePosition, studentId).then(requestedCourseId => {
        setCourseId(requestedCourseId)
      })
    }
  }, [coursePosition, studentId])

  useEffect(() => {
    if (courseId !== 0) {
      userCourseService.getCourseInfo(courseId).then(reqCourseInfo => setCourseInfo(reqCourseInfo))
      userCourseService.getCourseAuthor(courseId).then(author => setCourseAuthor(author))
    }
    if (courseId !== 0 && studentId !== -1) {
      if (currentUserRole === RoleEnum.PAY_STUDENT && statusPage === 'fake') {
        Promise.all([
          fakeStudentCourseService.loadEnrollingState(courseId, currentUserId),
          fakeStudentCourseService.loadEnrollingState(courseId, studentId),
        ]).then(values => {
          setIsEnrolling(values[0] || (values[0] && values[1]))
        })
      } else {
        fakeStudentCourseService.loadEnrollingState(courseId, studentId).then(isEnroll => setIsEnrolling(isEnroll))
      }
      fakeStudentCourseService.loadModuleViewDto(courseId, studentId).then(dto => setModuleViewDtos(dto))
    } else if (courseId !== 0) {
      studentCourseService.loadEnrollingState(courseId, studentId).then(isEnroll => setIsEnrolling(isEnroll))
      studentCourseService.loadModuleViewDto(courseId, studentId).then(dto => setModuleViewDtos(dto))
    }
  }, [courseId, studentId, reloadDto])

  const enrollInCourse = () => {
    studentCourseService.enrollInCourseByCoursePosition(coursePosition).then(() => {
      history.push(generateCourseURL(`/user/courses/${coursePosition}/1/1/1`, courseType))
    })
  }

  return (
    <>
      <StudentBreadCrumbs
        statusPage={statusPage}
        coursePosition={coursePosition}
        studentId={studentId}
        courseId={courseId}
        coursePic={coursePicUrl && convertResponseImageUrl(coursePicUrl)}
      />
      <div className="detail-course-info-wrap">
        <div className="container">
          <div className="detail-course-info-block">
            <div className="detail-course-text">{description}</div>

            <div className="right-wrap">
              <div className="info-block load">
                <i className="mdi mdi-school" />
                <div className="block-text">
                  <b>{t('Load')}:</b>
                  <span>{filling}</span>
                </div>
              </div>

              <div className="info-block time">
                <i className="mdi mdi-clock" />
                <div className="block-text">
                  <b>{t('CourseTime')}:</b>
                  <span>{transitTime}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="detail-course-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-8 course-left-wrap">
              <div className="main-course-info sbox">
                <Tabs>
                  <TabList>
                    <div className="tabs-wrap">
                      <Tab selectedClassName="active" className="course-tab">
                        {t('Content')}
                      </Tab>
                    </div>
                  </TabList>
                  <TabPanel selectedClassName="active" className="tab-content">
                    <CourseContent
                      moduleViewDtos={moduleViewDtos}
                      coursePosition={coursePosition}
                      isEnrolling={isEnrolling}
                      studentId={studentId}
                      statusPage={statusPage}
                      courseId={courseId}
                      setReloadDto={setReloadDto}
                    />
                  </TabPanel>
                </Tabs>
              </div>
            </div>

            <div className="col-md-4">
              <div className="about-author sbox">
                <div className="about-author-title">{t('AboutCourseAuthor')}</div>
                <div className="author-wrap">
                  <div className="author-detail">
                    <div className="author-name">{name}</div>
                    <div className="author-detail-info">{email}</div>
                  </div>
                </div>
                <div className="author-text">{about}</div>
              </div>

              <div className="requirements-wrap sbox">
                <div className="req-wrap">
                  <div className="req-title">{t('Requirements')}</div>
                  <div id="requirement" className="req-text">
                    {demands}
                  </div>
                </div>

                <div className="target-wrap">
                  <div className="target-title">{t('TheTargetAudience')}</div>
                  <div className="target-text">{target}</div>
                </div>
              </div>
              {!isEnrolling && statusPage !== 'fake' && (
                <button type="button" className="select-course-btn" onClick={enrollInCourse}>
                  <i className="mdi mdi-check" />
                  <span>Поступить на курс</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(CourseDetailsPage)
