import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Pagination from 'rc-pagination'
import classNames from 'classnames/bind'
import { useReactAlert } from 'src/hooks/useReactAlert'
import ReportFilters from './report-filters'
import ReportList from './report-list'
import StudentReportService from '../../../../services/student-services/student-report-service'
import { StudentReportGetDto } from '../../../../model/student-model'
import { dateForInput, dateForInputMinusNDays, dateForState } from './utils/utils'

import styles from './report-page.module.scss'

const cx = classNames.bind(styles)
export type TFilterValues = {
  query: string
  isOnlyOwn: boolean
  startPublicDate: string
  endPublicDate: string
}

const ReportPage = () => {
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const { t } = useTranslation()
  document.title = `${t('AllReports')}`
  const reportService = new StudentReportService()
  const [reports, setReports] = useState<StudentReportGetDto[]>([])
  const [totalReportsCount, setTotalPageCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState(9)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    query: '',
    isOnlyOwn: true,
    startPublicDate: dateForInputMinusNDays(),
    endPublicDate: dateForInput(),
  })
  const { query, isOnlyOwn, startPublicDate, endPublicDate } = filterValues
  const getStudentReports = useCallback(() => {
    reportService
      .getStudentReports(
        currentPage,
        itemsPerPage,
        isOnlyOwn,
        query,
        dateForState(startPublicDate),
        dateForState(endPublicDate)
      )
      .then(({ count, entities }) => {
        setTotalPageCount(count)
        setReports(entities)
      })
      .catch(err => catchErrorAlert(err))
  }, [query, currentPage, itemsPerPage, isOnlyOwn, startPublicDate, endPublicDate])

  useEffect(() => {
    getStudentReports()
  }, [query, currentPage, itemsPerPage, isOnlyOwn, startPublicDate, endPublicDate])
  return (
    <>
      <div className="middle-header review">
        <div className="container">
          <div className="page-title-wrap">
            <div className="page-title">{t('AllReports')}</div>
          </div>
        </div>
      </div>
      <div className={cx('report-wrap')}>
        <div className="container">
          <div className={cx('report-block')}>
            <div className={cx('header-report')}>{t('Reports')}</div>
            <div className={cx('report-filters')}>
              <ReportFilters
                getStudentReports={getStudentReports}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
              />
            </div>
            <div className={cx('report-list')}>
              <ReportList reports={reports} getStudentReports={getStudentReports} />
              {totalReportsCount > itemsPerPage && (
                <div className={cx('pagination-wrapper')}>
                  <Pagination
                    onChange={page => setCurrentPage(page)}
                    current={currentPage}
                    total={totalReportsCount}
                    pageSize={itemsPerPage}
                    showTitle={false}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ReportPage
