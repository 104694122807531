import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TimeEntryDto } from '../../../../../../model/student-model'
import TaskDescription from '../../../../../../components/pages/student/course-page/task-block/task-description'
import ModalReviewStepTask from '../../../../../../components/pages/student/course-page/modal-review-step-task/modal-review-step-task'
import { ReviewStepTaskDto } from '../../../../models/service/Task/ReviewStepTaskDto'

interface IProps {
  reviewStepTask: ReviewStepTaskDto
  available: boolean
  completed: boolean
  isReviewAssignable: boolean
}

const ReviewStepTask: FC<IProps> = ({ reviewStepTask, available, completed, isReviewAssignable }) => {
  const { t } = useTranslation()
  const [modalShow, setModalShow] = useState<boolean>(false)
  const [date, setDate] = useState<Date>(new Date())
  const [description, setDescription] = useState<string>()
  const [currentListTimeEntryDto, setCurrentListTimeEntryDto] = useState<TimeEntryDto[]>([])
  const [recordedDay, setRecordedDay] = useState<string>('')
  const [day, setDay] = useState<number>(-1)
  const [month, setMonth] = useState<number>(-1)
  const [selectedReviewId, setSelectedReviewId] = useState<number>(-1)

  const formatReviewEntryDto = () => {
    if (isReviewAssignable) {
      const { recordedDay: recordedDayFromDto } = reviewStepTask.reviewEntryDto
      setRecordedDay(recordedDayFromDto)
      if (recordedDayFromDto) {
        setDay(Number(recordedDayFromDto.split('.')[0]))
        setMonth(Number(recordedDayFromDto.split('.')[1]) - 1)

        Object.values(reviewStepTask.reviewEntryDto.mapOfListTimeEntryDto).forEach(timeEntry => {
          timeEntry.forEach(entry => {
            if (entry.registered) {
              setCurrentListTimeEntryDto(timeEntry)
              setSelectedReviewId(entry.reviewId)
            }
          })
        })
      }
    }
  }

  useEffect(() => {
    currentListTimeEntryDto.forEach(time => {
      if (time.registered) {
        setSelectedReviewId(time.reviewId)
      }
    })
  }, [selectedReviewId])

  useEffect(() => {
    if (reviewStepTask) {
      setDescription(reviewStepTask.description)
    }
  }, [reviewStepTask])

  useEffect(() => {
    if (reviewStepTask) {
      formatReviewEntryDto()
    }
  }, [modalShow])

  const onClose = () => {
    setModalShow(false)
    setDate(new Date())
  }

  return (
    <div className="task-content">
      <div className="step-content-head">{t('RecordingOnReview')}</div>
      <div className="step-content-head" />
      <TaskDescription description={description} />
      <div className="lesson-result-row">
        {isReviewAssignable && (
          <div className="lesson-result-right-wrap">
            {available ? (
              <button
                type="button"
                onClick={() => {
                  setModalShow(true)
                }}
                className="send-result-btn"
                disabled={completed}
                title={t('ReviewPassed')}
              >
                {reviewStepTask.reviewEntryDto?.recordedDay ? t('GoToPost') : t('SignUpForAReview')}
              </button>
            ) : (
              <button type="button" className="send-result-btn" disabled title={t('ReviewCompletedWarning')}>
                {t('RecordingOnReview')}
              </button>
            )}
          </div>
        )}
      </div>
      <ModalReviewStepTask
        selectedReviewId={selectedReviewId}
        setSelectedReviewId={setSelectedReviewId}
        day={day}
        recordedDay={recordedDay}
        month={month}
        currentListTimeEntryDto={currentListTimeEntryDto}
        setRecordedDay={setRecordedDay}
        setMonth={setMonth}
        setDay={setDay}
        setCurrentListTimeEntryDto={setCurrentListTimeEntryDto}
        date={date}
        modalShowed={modalShow}
        onClose={onClose}
        reviewEntryDto={reviewStepTask.reviewEntryDto}
        setDate={setDate}
      />
    </div>
  )
}

export default ReviewStepTask
