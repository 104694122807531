import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { ReviewEntryDto, TimeEntryDto } from '../../../../../../model/student-model'
import { SignUpReviewDate } from '../review-step-modal'

interface Props {
  reviewEntryDto: ReviewEntryDto
  signUpReviewDate: SignUpReviewDate | undefined
  formatTitle: (date: string) => string
  setSelectedReviewId: (reviewId: number) => void
  formatReviewTime: (date: string) => string
  cancelReview: () => void
  backToCalendar: () => void
  signUpForReview: () => void
}

const ReviewStepSignUpModal = ({
  reviewEntryDto,
  signUpReviewDate,
  formatTitle,
  setSelectedReviewId,
  formatReviewTime,
  cancelReview,
  backToCalendar,
  signUpForReview,
}: Props): JSX.Element => {
  const { i18n, t } = useTranslation()

  if (!signUpReviewDate) {
    return (
      <div className="not-found">
        <h6 className="not-found-title"> {t('CanNotFindReviewDate')}</h6>
      </div>
    )
  }

  return (
    <div className="sign_up-review">
      <div className="sign_up-inner-container">
        <div className="sign_up-about">
          <h6 className="sign_up-title">
            {reviewEntryDto.recordedDay ? t('MyRecordOn') : t('ChooseAConvenientTimeFor')}{' '}
            {formatTitle(signUpReviewDate.selectedDay)}
          </h6>
          <div className="sign_up-date">
            {signUpReviewDate.timeInfo?.map(({ registered, reviewId, time, full }: TimeEntryDto) => (
              <button
                type="button"
                // @ts-ignore
                disabled={(reviewEntryDto.recordedDay && !registered) || full}
                className={cn({
                  'action-button': true,
                  'action-time-button': true,
                  selected: registered,
                })}
                key={reviewId}
                onClick={() => {
                  setSelectedReviewId(reviewId)
                }}
              >
                {formatReviewTime(time)} {full && `(${t('noPlaceToSign')})`}
              </button>
            ))}
          </div>
        </div>

        {reviewEntryDto.recordedDay ? (
          <div className="cancel-sign_up">
            <button type="button" className="action-button action-button-warn" onClick={cancelReview}>
              {t('OptOut')}
            </button>
          </div>
        ) : (
          <div className="sing_up-actions">
            <button type="button" className="action-button action-button-primary" onClick={backToCalendar}>
              {t('ToDateSelection')}
            </button>
            <button type="button" className="action-button action-button-success" onClick={signUpForReview}>
              {t('SignUp')}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ReviewStepSignUpModal
