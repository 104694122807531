import { useEffect } from 'react'
import getTaskByLocation from '../utils/getTaskByLocation'
import PromiseUtil from '../../../utils/PromiseUtil'
import ModuleDto from '../models/service/ModuleDto'
import { CoursePageLoading } from '../pages/CoursePage'
import CoursePageAdapter from '../CoursePageAdapter'
import AnyTaskDto from '../models/service/Task/AnyTaskDto'

const useTask = ({
  courseId,
  modules,
  modulePosition,
  chapterPosition,
  taskPosition,
  setLoadingByKey,
  setCurrentTask,
  pageAdapter,
}: UseTaskArg) => {
  useEffect(() => {
    const abortController = new AbortController()
    ;(async () => {
      if (!modules || !modulePosition || !chapterPosition || !taskPosition) {
        setCurrentTask(null)
        return
      }

      try {
        setLoadingByKey('currentTask', true)

        const task = getTaskByLocation(modules, {
          modulePosition,
          chapterPosition,
          taskPosition,
        })

        if (!task) {
          return
        }
        const fetchedTask = await pageAdapter
          .getCourseTaskById(courseId!, task.taskId, task.type)
          .then(PromiseUtil.throwIfAborted(abortController.signal))

        setCurrentTask(fetchedTask)

        setLoadingByKey('currentTask', false)
      } catch (e) {
        setCurrentTask(null)
      } finally {
        // todo: лоадинг запроса ДОЛЖЕН аннулироваться именно в finally. Если будет ошибка,
        //  лоадинг не будет аннулирован.
        //  Это поведение временно допускается, потому что есть проблема, связанная с тем,
        //  что отмененный конкурентный запрос отключает лоадинг, потому что в нем тоже произошла ошибка
        //  необходимо продумать систему загрузки задачи и исправить это
        // setLoadingByKey('currentTask', false)
      }
    })()

    return () => abortController.abort()
  }, [modules, modulePosition, chapterPosition, taskPosition])
}

export default useTask

interface UseTaskArg {
  courseId: number | undefined
  modules: ModuleDto[] | undefined
  modulePosition: number | undefined
  chapterPosition: number | undefined
  taskPosition: number | undefined
  setCurrentTask: (task: AnyTaskDto | null) => void
  setLoadingByKey: (key: keyof CoursePageLoading, value: boolean) => void
  pageAdapter: CoursePageAdapter
}
