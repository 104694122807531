import React from 'react'
import ContentLoader from '../ContentLoader'

const TitleLoader = () => {
  return (
    <ContentLoader width={300} height={43}>
      <rect x="0" y="15" rx="4" ry="4" width="300" height="30" />
    </ContentLoader>
  )
}

export default TitleLoader
