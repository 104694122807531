import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { CodeTaskDto } from '../../../../models/service/Task/CodeTaskDto'
import TaskDescription from '../../../../../../components/pages/student/course-page/task-block/task-description'
import ModalRightAnswer from './ModalIsRightAnswer'
import FakeExemplarySolutionModal from './FakeExemplarySolutionModal'
import Spinner from '../../../../../../components/spinner'
import { AnswerStatusDto } from '../../../../models/service/Task/AnswerStatusDto'
import { generateAllAvailableTaskStatus } from '../../../../utils/formatterStatusTasks'
import CodeEditor from '../../../../../../components/code-editor'

interface IProps {
  codeTask: CodeTaskDto
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<CodeTaskDto>
  isExemplarySolutionAvailableCallback: () => boolean
  isSolutionHistoryAvailableCallback: () => boolean
  isMentorCommentAvailableCallback: () => boolean
  isSessionStorageAvailableCallback: () => boolean
  getExemplarySolution: () => any // Используется сервис, который не типизирован
  sessionStorageName: string
  onSolveTask: any
  onResetTask: () => Promise<void>
  solutionsLink: string
  courseTaskId: number
  postSolveInterceptor: (
    codeTask: CodeTaskDto,
    code: string,
    solveResponse: any,
    solved: boolean,
    points: number,
    previousSolveStatus: boolean,
    setReceivedAnswer: Dispatch<SetStateAction<Partial<boolean>>>,
    setProbability: Dispatch<SetStateAction<Partial<number>>>,
    setTestResult: Dispatch<SetStateAction<Partial<string>>>,
    setCode: Dispatch<SetStateAction<Partial<string>>>,
    setIsTestsPassed: Dispatch<SetStateAction<Partial<boolean | null>>>,
    dispatch: Dispatch<any>
  ) => void
  postResetInterceptor: (val: any, ...args: any[]) => any
}

const CodeTask: FC<IProps> = ({
  codeTask,
  loadTask,
  isExemplarySolutionAvailableCallback,
  isSolutionHistoryAvailableCallback,
  isMentorCommentAvailableCallback,
  isSessionStorageAvailableCallback,
  getExemplarySolution,
  sessionStorageName,
  onSolveTask,
  onResetTask,
  solutionsLink,
  courseTaskId,
  postSolveInterceptor,
  postResetInterceptor,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [code, setCode] = useState<string>(``)
  const [solvingTask, setSolvingTask] = useState<boolean>(false)
  const [receivedAnswer, setReceivedAnswer] = useState<boolean>(false)
  const [taskLoaded, setTaskLoaded] = useState<boolean>(true)
  const [testResult, setTestResult] = useState<string>(``)
  const [isTestsPassed, setIsTestsPassed] = useState<boolean | null>(null)
  const [testWithError, setTestWithError] = useState<boolean>(false)
  const [probability, setProbability] = useState<number>(0)
  const [reset, setReset] = useState<boolean>(false)
  const [modalShowed, setModalShowed] = useState<boolean>(false)
  const [referenceSolution, setReferenceSolution] = useState<string>('')

  const [lastActionIsRight, setLastActionIsRight] = useState<boolean>(false)
  const [lastActionIsWrong, setLastActionIsWrong] = useState<boolean>(false)
  const allTaskStatus = generateAllAvailableTaskStatus(codeTask.answerStatus)

  const isExemplarySolutionAvailable = isExemplarySolutionAvailableCallback()
  const isSolutionHistoryAvailable = isSolutionHistoryAvailableCallback()
  const isMentorCommentAvailable = isMentorCommentAvailableCallback()
  const isSessionStorageAvailable = isSessionStorageAvailableCallback()

  const onChange = (codeValue: string) => {
    setCode(codeValue)
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    if (codeTask.answerStatus) {
      const answerIsRight = allTaskStatus[AnswerStatusDto.LAST_ANSWER_RIGHT]
      const answerIsWrong = allTaskStatus[AnswerStatusDto.LAST_ANSWER_WRONG]
      setTaskLoaded(false)
      setLastActionIsRight(answerIsRight!)
      setLastActionIsWrong(answerIsWrong!)
    }
  }, [])

  // TODO: loadTask being called when reset or submit actions happened to refresh ui but it used old api
  //  Not sure about support this method in V2 version thus removed logic related with that

  const loadCodeTask = (interceptor?: any) => Promise.resolve(codeTask)

  const onClose = () => {
    setReceivedAnswer(false)
    setSolvingTask(false)
  }

  const onCloseExemplarySolution = () => {
    setModalShowed(false)
  }

  useEffect(() => {
    if (code !== '' && isSessionStorageAvailable) {
      sessionStorage.setItem(sessionStorageName, code)
    }
  }, [code])

  useEffect(() => {
    if (solvingTask) {
      const oldCode = code
      let solveResponse: any
      const { isSolved: previousSolveStatus } = codeTask
      onSolveTask(code)
        .then((val: any) => {
          solveResponse = val
        })
        .then(() => loadCodeTask())
        .then(({ solved, taskPoints: points }: { solved: boolean; taskPoints: number }) =>
          postSolveInterceptor(
            codeTask,
            oldCode,
            solveResponse,
            solved,
            points,
            previousSolveStatus,
            setReceivedAnswer,
            setProbability,
            setTestResult,
            setCode,
            setIsTestsPassed,
            dispatch
          )
        )
        .catch(() => setTestWithError(true))
    }
  }, [solvingTask])

  useEffect(() => {
    if (testWithError) {
      setTestResult(`${t('FailedToSubmitSolution')}`)
      setSolvingTask(false)
    }
  }, [testWithError])

  useEffect(() => {
    if (codeTask !== null) {
      setTaskLoaded(false)
      if (solvingTask) {
        setSolvingTask(false)
      }
      if (testWithError) {
        setTestWithError(false)
      }
      const { answer, placeholder, result } = codeTask
      const isReseated = allTaskStatus[AnswerStatusDto.ANSWER_RESET]
      const storage = sessionStorage.getItem(sessionStorageName)
      let newCode = isReseated ? placeholder : answer || placeholder
      if (storage != null) {
        const answerOrStorage = lastActionIsRight && !isReseated ? newCode : storage
        newCode = reset ? placeholder : answerOrStorage
        setReset(false)
      }
      setCode(newCode)
      setTestResult(result)
    }
  }, [codeTask])

  useEffect(() => {
    setIsTestsPassed(null)
    setSolvingTask(false)
  }, [courseTaskId])

  const resetTask = () => {
    setReset(true)

    onResetTask()
      .then(() => {
        return loadCodeTask(postResetInterceptor)
      })
      .then(loadedTask => {
        // mock implementation
        setCode(codeTask.answer!)
        setIsTestsPassed(null)
      })
  }

  if (taskLoaded || codeTask === null) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  const { description, codeLang, mentorTaskReviewCommentDto } = codeTask
  const isReseated = allTaskStatus[AnswerStatusDto.ANSWER_RESET]

  const getMentorCommentBlock = () => {
    if (mentorTaskReviewCommentDto) {
      const { value: mentorCommentValue, needChange } = mentorTaskReviewCommentDto
      if (mentorCommentValue && isMentorCommentAvailable) {
        return (
          <div
            className={`task-content__mentor-comment-block ${
              needChange ? 'task-content__mentor-comment-failed' : 'task-content__mentor-comment-success'
            }`}
          >
            <h3 className="task-content__mentor-comment-header">{t('messageFromMentor')}</h3>
            <p className="task-content__mentor-comment-body">{mentorCommentValue}</p>
          </div>
        )
      }
    }
    return undefined
  }

  function referenceSolutionModal() {
    if (isExemplarySolutionAvailable) {
      setModalShowed(true)
      getExemplarySolution().then((solution: any) => {
        setReferenceSolution(solution)
      })
    }
  }

  return (
    <div className="task-content">
      <div className="step-content-head">{t('LessonWithACode')}</div>
      <TaskDescription description={description} />
      <CodeEditor
        language={codeLang}
        value={code}
        onChange={value => onChange(value ?? '')}
        className={cn({
          'code-editor': true,
          'error-code-result': testWithError || lastActionIsWrong,
          'success-code-result': lastActionIsRight,
        })}
      />
      {getMentorCommentBlock()}
      <div className="compile-result">
        <div className="compile-title-wrap">
          <div className="compile-title">{t('CompilationResult')}</div>
          {isSolutionHistoryAvailable && (
            <Link to={solutionsLink} className="history-link">
              {t('SolutionHistory')}
            </Link>
          )}
          {isExemplarySolutionAvailable && (
            <span className="exemplary-solution" onClick={() => referenceSolutionModal()}>
              Эталонное решение
            </span>
          )}
        </div>
        {solvingTask || (isReseated && !testWithError)
          ? null
          : testResult && (
              <div className="terminal-result">
                <samp>{testResult}</samp>
              </div>
            )}
      </div>
      <div className="lesson-result-row">
        {solvingTask || isReseated || testWithError ? null : (
          <div
            className={cn({
              'lesson-result': !lastActionIsRight && !lastActionIsWrong,
              'lesson-result success': lastActionIsRight,
              'lesson-result error': !lastActionIsRight,
            })}
          >
            {lastActionIsRight ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />}
            {lastActionIsRight ? <span>{t('TheRightSolution')}</span> : <span>{t('InvalidSolutionCheckAgain')}</span>}
          </div>
        )}
        <div className="lesson-result-right-wrap">
          <button type="button" className="reset-value-btn" style={{ cursor: 'pointer' }} onClick={() => resetTask()}>
            {t('ResetSolution')}
          </button>
          <button type="button" className="scroll-up" id="scroll-up" onClick={scrollToTop}>
            {t('Upstairs')}
          </button>
          {solvingTask ? (
            <button type="button" className="send-result-btn">
              {t('SubmittingSolution')}
            </button>
          ) : (
            <button type="button" className="send-result-btn" onClick={() => setSolvingTask(true)}>
              {t('SubmitSolution')}
            </button>
          )}
        </div>
      </div>
      <ModalRightAnswer modalShowed={receivedAnswer && lastActionIsRight && probability < 0.2} onClose={onClose} />

      <FakeExemplarySolutionModal
        modalShowed={modalShowed}
        studentCode={referenceSolution}
        onClose={() => onCloseExemplarySolution()}
      />
    </div>
  )
}

export default CodeTask
