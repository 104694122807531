import React, { FC, ReactNode } from 'react'

const render = (viewOrComponent: ViewOrComponent) => {
  if (viewOrComponent instanceof Function) {
    return viewOrComponent({})
  }

  return viewOrComponent
}

const Loader = ({ children, loader, loading, errorBoundary, error, hide }: Props) => {
  if (hide) {
    return null
  }

  if (loading) {
    return <>{render(loader)}</>
  }

  if (error) {
    return <>{render(errorBoundary)}</>
  }

  return <>{children}</>
}

export default Loader

type View = ReactNode | string
type ViewOrComponent = View | FC

interface Props {
  children: View
  loader?: ViewOrComponent
  loading?: boolean | undefined
  errorBoundary?: ViewOrComponent
  error?: boolean | undefined
  hide?: boolean | undefined
}
