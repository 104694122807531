class PromiseUtil {
  public static throwIfAborted<T>(abortSignal: AbortSignal) {
    return (res: T) => {
      if (abortSignal.aborted) {
        throw new Error('Aborted')
      }

      return res
    }
  }
}

export default PromiseUtil
