import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { store } from 'react-notifications-component'
import { Button } from 'react-bootstrap'
import Spinner from '../../../../../spinner'
import TaskDescription from '../task-description'
import AlertDanger from '../alert-danger'
import FakePageCheckPredProjectModal from '../../../../fake-page/fake-page-check-predproject-modal/fake-page-check-predproject-modal'
import { studentErrorNotyTemplate, studentSuccessNotyTemplate } from '../../../../../../config'
import { MentorCheckTaskDto } from '../../../../../../model/task-model/MentorCheckTaskDto'
import { AnswerInput } from './answer-input'

interface IProps {
  handleCheckRequest: () => void
  solved: boolean
  checked: boolean
  statusPage: string
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<MentorCheckTaskDto>
  mentorCheckTask: MentorCheckTaskDto
  onSolveTask: any // (answer: string) => Promise<string>
  postSolveInterceptor: any
  onCheckTask?: any // используется для фейковой страницы, внутри фейковый сервис - не типизирован
  onCancelTaskCheck: () => Promise<void>
  isPermittedToCheckCallback: () => boolean
  isAnswerAvailable: boolean
  solutionsLink: string
}

const MentorCheckTask: FC<IProps> = ({
  handleCheckRequest,
  solved,
  checked,
  statusPage,
  loadTask,
  mentorCheckTask,
  onSolveTask,
  postSolveInterceptor,
  onCheckTask,
  onCancelTaskCheck,
  isPermittedToCheckCallback,
  isAnswerAvailable,
  solutionsLink,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [currentSolved, setCurrentSolved] = useState(solved)
  const [currentChecked, setCurrentChecked] = useState(checked)
  const [newAnswer, setNewAnswer] = useState<string>(``)
  const [solvingTask, setSolvingTask] = useState<boolean>(false)
  const [taskLoaded, setTaskLoaded] = useState<boolean>(true)
  const [errorLink, setErrorLink] = useState<boolean>(false)
  const [errorMassage, setErrorMassage] = useState<string>('')
  const [mentorComments, setMentorComments] = useState<string>('')
  const [lastActionIsRight, setLastActionIsRight] = useState<boolean>(false)
  const [lastActionIsWrong, setLastActionIsWrong] = useState<boolean>(false)
  const [right, setRight] = useState<boolean>(false)
  const [lastAnswerId, setLastAnswerId] = useState<number>()
  const [modalShowed, setModalShowed] = useState<boolean>(false)

  const [inputClassName, setInputClassName] = useState<string>('')
  const [lessonClassName, setLessonClassName] = useState<string>('')
  const [icon, setIcon] = useState<JSX.Element | null>()
  const [message, setMessage] = useState<string | null>('')
  const [mentorCheckResult, setMentorCheckResult] = useState<string>('')
  const [solutionSent, setSolutionSent] = useState<boolean>(false)

  const loadMentorCheckTask = (interceptor?: any) => {
    return loadTask(interceptor).then(task => {
      if (task !== undefined && task !== null) {
        setTaskLoaded(false)
        setLastActionIsRight(task.lastActionIsRight)
        setLastActionIsWrong(task.lastActionIsWrong)
        setLastAnswerId(task.answerId)
      }
      if (solvingTask) {
        setSolvingTask(false)
      }
      return task
    })
  }

  useEffect(() => {
    if (mentorCheckTask !== undefined && mentorCheckTask !== null) {
      setTaskLoaded(false)
      setLastActionIsRight(mentorCheckTask.lastActionIsRight)
      setLastActionIsWrong(mentorCheckTask.lastActionIsWrong)
      setLastAnswerId(mentorCheckTask.answerId)
    }
  }, [mentorCheckTask])

  const updateComments = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMentorComments(event.target.value)
  }

  const getInputClassName = (isSolved: boolean, isChecked: boolean, isRight: boolean, isWrong: boolean) => {
    let className = 'lesson-input '

    if (isSolved && !isChecked) {
      return `${className} on-check`
    }
    className += isRight ? ` success` : ` error`

    if (!isRight && !isWrong) {
      return 'lesson-input'
    }
    return className
  }

  const getLessonClassName = (isRight: boolean, isWrong: boolean) => {
    if (!isRight && !isWrong) {
      return `lesson-result`
    }
    return isRight ? `lesson-result success` : `lesson-result error`
  }

  const getIcon = (isRight: boolean, isWrong: boolean, isChecked: boolean) => {
    if (!isChecked) {
      return null
    }
    if (!isRight && !isWrong) {
      return null
    }
    return isRight ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />
  }

  const getMessage = (isRight: boolean, isWrong: boolean, isChecked: boolean) => {
    if (!isChecked) {
      return null
    }
    if (!isRight && !isWrong) {
      return ''
    }
    return isRight ? `${t('TheRightSolution')}` : `${t('InvalidSolutionCheckAgain')}`
  }

  const getMentorCheckResult = (checkResult: string, isSolved: boolean, isChecked: boolean) => {
    let mentorCheckResultLocal = checkResult
    if (!mentorCheckResultLocal || mentorCheckResultLocal === '') {
      if (isSolved) {
        if (isChecked) {
          mentorCheckResultLocal = t('MentorCheckingSuccess')
        } else {
          mentorCheckResultLocal = t('MentorChecking')
        }
      } else if (checked) {
        mentorCheckResultLocal = t('MentorCheckingFailure')
      }
    }
    return mentorCheckResultLocal
  }

  const reloadTaskFeedback = (
    isSolved: boolean,
    isChecked: boolean,
    isRight: boolean,
    isWrong: boolean,
    checkResult: string
  ) => {
    setInputClassName(getInputClassName(isSolved, isChecked, isRight, isWrong))
    setLessonClassName(getLessonClassName(isRight, isWrong))
    setIcon(getIcon(isRight, isWrong, isChecked))
    setMessage(getMessage(isRight, isWrong, isChecked))
    setMentorCheckResult(getMentorCheckResult(checkResult, isSolved, isChecked))
  }

  const solveMentorCheckTask = () => {
    const { solved: previousSolveStatus } = mentorCheckTask
    setErrorLink(false)
    onSolveTask(newAnswer)
      .then(() => {
        handleCheckRequest()
        return loadMentorCheckTask()
      })
      .then((task: MentorCheckTaskDto) => {
        postSolveInterceptor(previousSolveStatus, task.solved, task.taskPoints, dispatch)
        reloadTaskFeedback(task.solved, task.resolved, task.lastActionIsRight, task.lastActionIsWrong, task.result)
        setSolutionSent(true)
      })
      .catch((err: any) => {
        const { code, text } = err
        if (code === 400) {
          setSolutionSent(false)
          setErrorLink(true)
          setErrorMassage(text)
          setSolvingTask(false)
        }
      })
  }

  const handleChange = (value: string) => {
    setNewAnswer(value)
  }

  const sendSolution = () => {
    onCheckTask(mentorComments, lastAnswerId, right)
      .then(() => {
        setModalShowed(false)
        store.addNotification({
          ...studentSuccessNotyTemplate,
          message: `Результат проверки успешно сохранен`,
        })
        loadMentorCheckTask()
      })
      .catch(() => {
        store.addNotification({
          ...studentErrorNotyTemplate,
          message: `Во время сохранения результата проверки произошла ошибка`,
        })
        loadMentorCheckTask()
      })
  }

  useEffect(() => {
    if (mentorCheckTask) {
      const { answer, resolved } = mentorCheckTask
      const chooseAnswer = resolved ? `` : answer || ``
      setNewAnswer(chooseAnswer)
    }
  }, [mentorCheckTask])

  useEffect(() => {
    if (mentorCheckTask) {
      if (solvingTask) {
        setSolvingTask(false)
      }
      const { answerId, result } = mentorCheckTask
      setTaskLoaded(false)
      if (result === null) {
        setMentorComments('')
      } else {
        setMentorComments(result)
      }
      setLastAnswerId(answerId)
    }
  }, [mentorCheckTask])

  useEffect(() => {
    if (!solvingTask) {
      return
    }
    solveMentorCheckTask()
  }, [solvingTask, newAnswer])

  useEffect(() => {
    reloadTaskFeedback(currentSolved, currentChecked, lastActionIsRight, lastActionIsWrong, mentorCheckTask.result)
  }, [lastActionIsWrong, lastActionIsRight, currentSolved, currentChecked])

  if (taskLoaded || mentorCheckTask === null) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  const onClose = () => {
    setModalShowed(false)
  }

  const canBeChecked = isPermittedToCheckCallback()
  const submitButtonDisabled = newAnswer.length === 0

  return (
    <div className="task-content">
      <div className="step-content-head">{t('TheTaskVerifiedByTheMentor')}</div>
      {mentorCheckTask && mentorCheckTask.description && <TaskDescription description={mentorCheckTask.description} />}
      {statusPage !== 'fake' && (
        <>
          <AnswerInput
            className={inputClassName}
            value={mentorCheckTask.solved ? mentorCheckTask.answer : newAnswer}
            disabled={mentorCheckTask.solved}
            placeholder="Введите ответ"
            onChange={handleChange}
            type={mentorCheckTask.answerType}
          />

          {errorLink && <AlertDanger description={errorMassage} />}
          {/* eslint-disable-next-line */}
          {(currentSolved && !currentChecked || currentChecked || solutionSent) && (
            <div className="compile-result">
              <div className="compile-title-wrap">
                <div className="compile-title">{t('MentorCheckResult')}</div>
              </div>
              <div className="answer-result">
                <TaskDescription
                  description={currentSolved && !currentChecked ? t('SolutionIsPending') : mentorCheckResult}
                  isMentorAnswer
                />
              </div>
            </div>
          )}
          <div className="lesson-result-row">
            {currentChecked && (
              <div className={lessonClassName}>
                {icon}
                <span>{message}</span>
              </div>
            )}
            <div className="lesson-result-right-wrap">
              {!mentorCheckTask.result && mentorCheckTask.answer && (
                <button
                  type="button"
                  className="send-result-btn warning-btn"
                  onClick={() => {
                    onCancelTaskCheck()
                    setCurrentSolved(false)
                    setCurrentChecked(false)
                    setSolvingTask(false)
                    setSolutionSent(false)
                    setInputClassName(getInputClassName(false, false, false, false))
                  }}
                >
                  Отменить проверку
                </button>
              )}
              {solvingTask ? (
                <button type="button" disabled className="send-result-btn" style={{ cursor: 'pointer' }}>
                  {t('SubmittingSolution')}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => setSolvingTask(true)}
                  className="send-result-btn"
                  disabled={
                    submitButtonDisabled || (currentSolved && !currentChecked) || lastActionIsRight || solutionSent
                  }
                >
                  {t('SubmitSolution')}
                </button>
              )}
            </div>
          </div>
          {isAnswerAvailable && (
            <div className="allComments">
              <Link to={solutionsLink} className="history-link">
                {t('AllComments')}
              </Link>
            </div>
          )}
        </>
      )}
      {statusPage === 'fake' && newAnswer && (
        <>
          {isAnswerAvailable && (
            <>
              <label htmlFor="mentor-link">Ссылка на репозиторий:</label>
              <div className="fake-page-mentor-link">
                <a href={newAnswer} target="_blank nooffer">
                  {newAnswer}
                </a>
              </div>
            </>
          )}
          <div className="mentor-check-form">
            {canBeChecked && (
              <>
                <textarea
                  id="mentor-comment"
                  className="form-control"
                  placeholder="Комментарий к задаче"
                  rows={15}
                  onChange={updateComments}
                  value={mentorComments}
                />
                <div className="right">
                  <Button className="mt-2" variant="success" onClick={() => setModalShowed(true)} size="lg">
                    Сохранить результат проверки
                  </Button>
                </div>
              </>
            )}
          </div>
          {isAnswerAvailable && (
            <div className="allComments">
              <Link to={solutionsLink} className="history-link">
                {t('AllComments')}
              </Link>
            </div>
          )}
          {canBeChecked && (
            <FakePageCheckPredProjectModal
              modalShowed={modalShowed}
              onClose={onClose}
              sendSolution={sendSolution}
              right={right}
              setRight={setRight}
              lastActionIsRight={lastActionIsRight}
            />
          )}
        </>
      )}
      {canBeChecked && !newAnswer && <div className="no-solution-history">Не было попыток решить задачу</div>}
    </div>
  )
}

export default MentorCheckTask
