import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import './notification-modal-dialog.css'

const customStyles = {
  content: {
    left: '50%',
    top: '10%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    padding: '8px',
    margin: '44px 0',
  },
  overlay: {
    backgroundColor: 'rgba(46, 43, 43, 0.75)',
    overflowX: 'hidden',
    msOverflowY: 'scroll',
    zIndex: 10,
  },
}

const messageStyles = {
  whiteSpace: 'pre-wrap',
  padding: 15,
  textAlign: 'left',
}

const NotificationModalDialog = ({ isOpen, notification, onClose }) => {
  const { t } = useTranslation()
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} style={customStyles}>
        <div className="stat-courses-popup popup-window">
          <div className="popup-head">
            {t('ModalHead')}
            <button type="button" className="close-modal-button" onClick={onClose} />
          </div>
          <div className="courses-popup-wrap notification-title">{notification.title}</div>
          <div
            style={messageStyles}
            className="courses-popup-wrap"
            onClick={onClose}
            dangerouslySetInnerHTML={{
              __html: notification.message,
            }}
          />
          <div className="content-button-familiarized">
            <span className="familiarized" onClick={onClose}>
              {t('NotificationFamiliarized')}
            </span>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default NotificationModalDialog
