import React from 'react'
import ContentLoader from '../ContentLoader'

const BreadcrumbLoader = () => {
  return (
    <ContentLoader width={60} height={25}>
      <rect x="0" y="0" rx="4" ry="4" width="60" height="25" />
    </ContentLoader>
  )
}

export default BreadcrumbLoader
