import { Answer } from './index'
import { DefinitionDto } from '../../../../models/service/Task/AssociationTaskDto'

const useIsItemRightCallback = (answers: Answer[]) => {
  return (item: DefinitionDto) => {
    const currentAnswer = answers.find(answer => answer.definitionId === item.definitionId)
    return !!currentAnswer?.isRight
  }
}

export default useIsItemRightCallback
