import { Dispatch, SetStateAction, useEffect } from 'react'
import { GappingValues } from './index'
import { GappingTaskDto } from '../../../../models/service/Task/GappingTaskDto'

const useCurrentGappingValuesSetting = (
  gappingTask: GappingTaskDto | undefined,
  setGappingValues: Dispatch<SetStateAction<GappingValues>>,
  isSolved: boolean,
  updateAnswers: boolean
) => {
  useEffect(() => {
    if (isSolved && gappingTask && updateAnswers) {
      setGappingValues(
        Object.fromEntries(
          gappingTask.studentGappingResults.map(({ gappingItemId, gappingText }) => [gappingItemId, gappingText])
        )
      )
    }
  }, [gappingTask])
}

export default useCurrentGappingValuesSetting
