import React from 'react'
import { Link } from 'react-router-dom'

const ListCodeTaskDetails = ({ codeTasks }) => {
  if (codeTasks.length === 0) {
    return <div className="details-data">Список пуст</div>
  }

  return codeTasks.map((entity, key) => {
    const { coursePosition, modulePosition, chapterPosition, courseTaskPosition, mentorComment } = entity

    const linkToTask = `./courses/${coursePosition}/${modulePosition}/${chapterPosition}/${courseTaskPosition}`

    return (
      <div className="details-data" key={`code_debt_${linkToTask}`}>
        <Link
          to={linkToTask}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'inherit', textDecoration: 'underline' }}
        >
          {`${modulePosition}.${chapterPosition}.${courseTaskPosition}:`}
        </Link>
        {` ${mentorComment}`}
      </div>
    )
  })
}

export default ListCodeTaskDetails
