import React from 'react'

const Day = ({
  fullDate,
  currentDay,
  registrationDay,
  currentListTimeEntryDto,
  recordedDay,
  before,
  setCurrentListTimeEntryDto,
  setDay,
  setMonth,
  setRecordedDay,
}) => {
  if (fullDate === null) {
    return <div className="emptyStateDay">-</div>
  }

  const date = fullDate.getDate()
  let classDay = 'dayCustom'

  if (before) {
    classDay += 'BeforeCurrentDay'
  } else if (registrationDay) {
    classDay += 'RegisterCustomDatePicker'
  } else if (currentDay) {
    classDay += 'CurrentCustomDatePicker'
  }

  if (registrationDay) {
    return (
      <>
        <div
          className={classDay}
          onClick={() => {
            setCurrentListTimeEntryDto(currentListTimeEntryDto)
            setDay(fullDate.getDate())
            setMonth(fullDate.getMonth() - 1)
            if (recordedDay === undefined) {
              setRecordedDay(null)
            } else {
              setRecordedDay(recordedDay)
            }
          }}
        >
          {date}
        </div>
      </>
    )
  }

  return <div className={classDay}>{date}</div>
}

export default Day
