import React from 'react'
import './custom-date-picker.css'
import { useTranslation } from 'react-i18next'
import { getWeekDays, getWeeksForMonth } from './helper'
import WeekDay from './weekday'
import Day from './day'

const Month = ({
  dateNow,
  reviewEntryDto,
  onCloseMainModal,
  setRecordedDay,
  setCurrentListTimeEntryDto,
  setDay,
  setMonth,
}) => {
  const { mapOfListTimeEntryDto, recordedDay } = reviewEntryDto
  const { t } = useTranslation()
  let dayOfRecordedDay
  let monthOfRecordedMonth

  if (recordedDay !== null) {
    dayOfRecordedDay = Number(recordedDay.split('.')[0])
    monthOfRecordedMonth = Number(recordedDay.split('.')[1]) - 1
  }
  const weekDaysMarkUp = getWeekDays(t).map(weekday => {
    return <WeekDay key={weekday} title={weekday} label={weekday} />
  })

  const renderWeek = (fullDate, dayIndex) => {
    if (fullDate === null) {
      return (
        <Day
          key={dayIndex}
          fullDate={fullDate}
          setRecordedDay={setRecordedDay}
          setMonth={setMonth}
          setDay={setDay}
          setCurrentListTimeEntryDto={setCurrentListTimeEntryDto}
        />
      )
    }

    if (dateNow.getMonth() === fullDate.getMonth() && dateNow.getDate() > fullDate.getDate()) {
      return (
        <Day
          key={dayIndex}
          fullDate={fullDate}
          before
          setRecordedDay={setRecordedDay}
          setMonth={setMonth}
          setDay={setDay}
          setCurrentListTimeEntryDto={setCurrentListTimeEntryDto}
        />
      )
    }

    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const el in mapOfListTimeEntryDto) {
      const day = Number(el.split('.')[0])
      const month = Number(el.split('.')[1]) - 1

      if (
        recordedDay !== null &&
        fullDate.getMonth() === month &&
        fullDate.getDate() === day &&
        month === monthOfRecordedMonth &&
        day === dayOfRecordedDay
      ) {
        return (
          <Day
            key={fullDate}
            fullDate={fullDate}
            currentListTimeEntryDto={mapOfListTimeEntryDto[el]}
            registrationDay
            recordedDay
            onCloseMainModal={onCloseMainModal}
            setRecordedDay={setRecordedDay}
            setMonth={setMonth}
            setDay={setDay}
            setCurrentListTimeEntryDto={setCurrentListTimeEntryDto}
          />
        )
      }
      if (fullDate.getMonth() === month && fullDate.getDate() === day) {
        return (
          <Day
            key={fullDate}
            fullDate={fullDate}
            currentListTimeEntryDto={mapOfListTimeEntryDto[el]}
            registrationDay
            onCloseMainModal={onCloseMainModal}
            setRecordedDay={setRecordedDay}
            setMonth={setMonth}
            setDay={setDay}
            setCurrentListTimeEntryDto={setCurrentListTimeEntryDto}
          />
        )
      }
    }

    if (new Date().getMonth() === dateNow.getMonth() && fullDate.getDate() === dateNow.getDate()) {
      return (
        <Day
          key={fullDate}
          fullDate={fullDate}
          currentDay
          setRecordedDay={setRecordedDay}
          setMonth={setMonth}
          setDay={setDay}
          setCurrentListTimeEntryDto={setCurrentListTimeEntryDto}
        />
      )
    }
    return (
      <Day
        key={fullDate}
        fullDate={fullDate}
        setRecordedDay={setRecordedDay}
        setMonth={setMonth}
        setDay={setDay}
        setCurrentListTimeEntryDto={setCurrentListTimeEntryDto}
      />
    )
  }

  const weeks = getWeeksForMonth(dateNow.getMonth(), dateNow.getFullYear())
  const weeksMarkUp = weeks.map((week, index) => {
    return (
      // TODO (25.05.2020): find out what can be used as key instead of index
      // eslint-disable-next-line react/no-array-index-key
      <div role="row" className="weekCustom" key={index}>
        {week.map(renderWeek)}
      </div>
    )
  })

  return (
    <div>
      <div className="weekDayContainer">{weekDaysMarkUp}</div>
      {weeksMarkUp}
    </div>
  )
}

export default Month
