import React, { useEffect, useState } from 'react'
import AnyTaskDto from '../../models/service/Task/AnyTaskDto'
import { TaskType } from '../../models/service/Task/TaskDto'
import LectureTask from './Task/LectureTask'
import AssociationTask from './Task/AssociationTask'
import GappingTask from './Task/GappingTask'
import CodeTask from './Task/CodeTask'
import { getSolveTaskCallbackStudent } from './getSolveTaskCallbackStudent'
import getResetTaskCallbackStudent from './getResetTaskCallbackStudent'
import { getPostResetInterceptorStudent } from '../../../../components/pages/student/course-page/getPostResetInterceptorStudent'
import TheoryTask from './Task/TheoryTask'
import { getPostSolveInterceptorStudent } from '../../../../components/pages/student/course-page/getPostSolveInterceptorStudent'
import ReviewStepTask from './Task/ReviewStepTask'
import WordTask from './Task/WordTask'
import MultiInputTask from './Task/MultiInputTask'
import MultiTestTask from './Task/MultiTestTask'
import MultiAnswerTask from './Task/MultiAnswerTask'
import OrderingTask from './Task/OrderingTask'
import { getCheckAvailabilityCallbackStudent } from '../../../../components/pages/student/course-page/getCheckAvailabilityCallbackStudent'
import MentorCheckTask from './Task/MentorCheckTask'
import useCoursePageRouteParams from '../../hooks/useCoursePageRouteParams'
import { AuthService } from '../../../../services/auth-service'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'
import {
  getReferenceSolutionCallback,
  getSessionStorageNameCallback,
  isCodeTaskExemplarySolutionAvailableCallback,
  isCodeTaskSolutionHistoryAvailableCallback,
} from '../../../../components/pages/student/course-page/task-block/codeTaskCallbacks'

export type Props = {
  task: AnyTaskDto | null
}

const TaskPage = ({ task }: Props) => {
  const [principalRole, setPrincipalRole] = useState<RoleEnum>()
  const [principalId, setPrincipalId] = useState<any>()
  const { courseId, taskPosition } = useCoursePageRouteParams()

  const solutionsLink = `/user/v2/courses/${courseId}/${taskPosition}/solutions`

  useEffect(() => {
    const currentRole: RoleEnum = AuthService.currentUserValue()!.role.name
    const currentId = AuthService.currentUserValue()!.id
    setPrincipalRole(currentRole)
    setPrincipalId(currentId)
  }, [])

  if (!courseId || !taskPosition) {
    return null
  }

  const onResetTask = (studentCourseTaskInfoId: number) => {
    return getResetTaskCallbackStudent(courseId, studentCourseTaskInfoId)
  }

  const onSolveTask = (taskType: any, studentCourseTaskInfoId: number) => {
    return getSolveTaskCallbackStudent(courseId, taskType, studentCourseTaskInfoId)
  }

  const postResetInterceptor = (taskType: any) => {
    return getPostResetInterceptorStudent(taskType)
  }

  const postSolveInterceptor = (taskType: any) => {
    return getPostSolveInterceptorStudent(taskType)
  }
  const renderTask = () => {
    switch (task?.type) {
      case TaskType.Lecture:
        return (
          <LectureTask
            lectureTask={task}
            onSolveTask={onSolveTask(task.type, task.id)}
            postSolveInterceptor={postSolveInterceptor(task.type)}
          />
        )
      case TaskType.Association:
        return (
          <AssociationTask
            associationTask={task}
            loadTask={() => Promise.resolve(task)}
            onResetTask={onResetTask(task.id)}
            onSolveTask={onSolveTask(task.type, task.id)}
            postResetInterceptor={postResetInterceptor(task.type)}
          />
        )
      case TaskType.Gapping:
        return (
          <GappingTask
            gappingTask={task}
            loadTask={() => Promise.resolve(task)}
            onResetTask={onResetTask(task.id)}
            onSolveTask={onSolveTask(task.type, task.id)}
            postResetInterceptor={postResetInterceptor(task.type)}
          />
        )
      case TaskType.MentorCheck:
        return (
          <MentorCheckTask
            mentorCheckTask={task}
            loadTask={() => Promise.resolve(task)}
            onSolveTask={onSolveTask(task.type, task.id)}
            solved={task.isSolved}
            checked={!!task.result}
            handleCheckRequest={() => {}}
            statusPage=""
            postSolveInterceptor={postSolveInterceptor}
            solutionsLink={solutionsLink}
            isAnswerAvailable
            isPermittedToCheckCallback={getCheckAvailabilityCallbackStudent()}
          />
        )
      case TaskType.MultiAnswer:
        return (
          <MultiAnswerTask
            multiAnswerTask={task}
            loadTask={() => Promise.resolve(task)}
            onResetTask={onResetTask(task.id)}
            onSolveTask={onSolveTask(task.type, task.id)}
            postResetInterceptor={postResetInterceptor(task.type)}
          />
        )
      case TaskType.MultiInput:
        return (
          <MultiInputTask
            multiInputTask={task}
            loadTask={() => Promise.resolve(task)}
            onResetTask={onResetTask(task.id)}
            onSolveTask={onSolveTask(task.type, task.id)}
            postResetInterceptor={postResetInterceptor(task.type)}
            postSolveInterceptor={postSolveInterceptor(task.type)}
          />
        )
      case TaskType.MultiTest:
        return (
          <MultiTestTask
            multiTestTask={task}
            loadTask={() => Promise.resolve(task)}
            onResetTask={onResetTask(task.id)}
            onSolveTask={onSolveTask(task.type, task.id)}
            postResetInterceptor={postResetInterceptor(task.type)}
          />
        )
      case TaskType.Ordering:
        return (
          <OrderingTask
            orderingTask={task}
            loadTask={() => Promise.resolve(task)}
            onResetTask={onResetTask(task.id)}
            onSolveTask={onSolveTask(task.type, task.id)}
            postResetInterceptor={postResetInterceptor(task.type)}
          />
        )
      case TaskType.ReviewStep:
        return (
          <ReviewStepTask
            reviewStepTask={task}
            isReviewAssignable
            available={task.isAvailable}
            completed={task.isSolved}
          />
        )
      case TaskType.Theory:
        return (
          <TheoryTask
            theoryTask={task}
            loadTask={() => Promise.resolve(task)}
            onResetTask={onResetTask(task.id)}
            onSolveTask={onSolveTask(task.type, task.id)}
            postResetInterceptor={postResetInterceptor(task.type)}
            // @ts-ignore
            // TODO: fix this ignore later
            postSolveInterceptor={postSolveInterceptor(task.type)}
          />
        )
      case TaskType.Word:
        return (
          <WordTask
            wordTask={task}
            onResetTask={onResetTask(task.id)}
            onGetTask={() => Promise.resolve(task)}
            onSolveTask={onSolveTask(task.type, task.id)}
            postResetInterceptor={postResetInterceptor(task.type)}
            postSolveInterceptor={postSolveInterceptor(task.type)}
          />
        )
      case TaskType.Code:
        return (
          <CodeTask
            codeTask={task}
            loadTask={() => Promise.resolve(task)}
            onResetTask={onResetTask(task.id)}
            onSolveTask={onSolveTask(task.type, task.id)}
            postResetInterceptor={postResetInterceptor(task.type)}
            isExemplarySolutionAvailableCallback={isCodeTaskExemplarySolutionAvailableCallback(principalRole)}
            isSolutionHistoryAvailableCallback={isCodeTaskSolutionHistoryAvailableCallback(principalRole, '')}
            isMentorCommentAvailableCallback={() => true}
            isSessionStorageAvailableCallback={() => true}
            getExemplarySolution={getReferenceSolutionCallback(task.id)}
            sessionStorageName={getSessionStorageNameCallback(principalId, task.type, task.id)()}
            solutionsLink={solutionsLink}
            courseTaskId={task.id}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
      default:
        return null
    }
  }

  return (
    <div>
      <div className="step-content-wrap">
        <div className="container">
          <div className="step-content lesson">{renderTask()}</div>
        </div>
      </div>
    </div>
  )
}

export default TaskPage
