import { useParams } from 'react-router-dom'

const prepareNumericRouteParam = (param: string | undefined) => (param !== undefined ? +param : undefined)

const useCoursePageRouteParams = () => {
  const {
    courseId: dirtyCourseId,
    modulePosition: dirtyModulePosition,
    chapterPosition: dirtyChapterPosition,
    taskPosition: dirtyTaskPosition,
  } = useParams<RouteParams>()

  const courseId = prepareNumericRouteParam(dirtyCourseId)
  const modulePosition = prepareNumericRouteParam(dirtyModulePosition)
  const chapterPosition = prepareNumericRouteParam(dirtyChapterPosition)
  const taskPosition = prepareNumericRouteParam(dirtyTaskPosition)

  return {
    courseId,
    modulePosition,
    chapterPosition,
    taskPosition,
  }
}

export default useCoursePageRouteParams

interface RouteParams {
  courseId: string
  modulePosition?: string
  chapterPosition?: string
  taskPosition?: string
}
