import { Client, over } from 'stompjs'
import SockJS from 'sockjs-client'
import { AuthService } from './auth-service'

export class WebsocketService<T> {
  private socket: WebSocket | undefined = undefined

  private client: Client | undefined = undefined

  protected initClient(url: string, debugChannel = () => {}) {
    this.socket = new SockJS(url)
    this.client = over(this.socket)
    this.client.debug = debugChannel
  }

  protected subscribe(url: string, callback: (dto: T) => void) {
    if (this.client) {
      return this.client.subscribe(url, message => {
        try {
          const body: T = JSON.parse(message.body)
          callback(body)
        } catch (e) {
          console.error(e)
        }
      })
    }
    return undefined
  }

  protected connect = (connectCallback: () => void) => {
    const authorization = AuthService.authHeaders()?.Authorization
    if (this.client) {
      this.client.connect({ Authorization: authorization }, connectCallback, console.error)
    }
  }

  protected disconnect = () => {
    if (this.client && this.client.connected) {
      this.client.disconnect(() => {})
    }
  }
}
