import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { dateForInputMinusNDays } from '../utils/utils'
import ReportModal from '../report-modal'
import { TFilterValues } from '../report-page'

import styles from './report-filters.module.scss'

const cx = classNames.bind(styles)
type Props = {
  getStudentReports: () => void
  setFilterValues: Dispatch<SetStateAction<TFilterValues>>
  filterValues: TFilterValues
}
const ReportFilters: FC<Props> = ({ getStudentReports, setFilterValues, filterValues }) => {
  const { query, isOnlyOwn, startPublicDate: startDate, endPublicDate: endDate } = filterValues
  const { t } = useTranslation()
  const [showReportModal, setShowReportModal] = useState<boolean>(false)

  const intervalDateHandler = (endInputtedDate: number, startInputtedDate: number = 0) => {
    const startPublicDate = dateForInputMinusNDays(endInputtedDate)
    const endPublicDate = dateForInputMinusNDays(startInputtedDate)
    setFilterValues(values => ({ ...values, startPublicDate, endPublicDate }))
  }

  return (
    <>
      <ReportModal
        show={showReportModal}
        onClose={() => {
          setShowReportModal(false)
        }}
        getStudentReports={getStudentReports}
      />
      <div className={cx('filters')}>
        <div className={cx('filter-button')}>
          <button type="button" className={cx('add-report-button')} onClick={() => setShowReportModal(true)}>
            {t('AddReport')}
          </button>
        </div>
        <div className={cx('filter-search')}>
          <form
            className={cx('search-form')}
            onSubmit={event => {
              event.preventDefault()
            }}
          >
            <div className={cx('search-input')}>
              <h4>{t('SearchReports')}</h4>
              <div className={cx('search-wrap')}>
                <span className={cx('search-icon')}>
                  <i className="mdi mdi-magnify" />
                </span>
                <input
                  type="search"
                  className={cx('search')}
                  id="search"
                  onChange={({ target: { value } }) => setFilterValues(values => ({ ...values, query: value }))}
                  value={query}
                  placeholder={t('ReportSearchPlaceholder')}
                />
              </div>
              <div className={cx('checkbox-wrap')}>
                <input
                  id="isOnlyOwn"
                  name="isOnlyOwn"
                  type="checkbox"
                  checked={isOnlyOwn}
                  onChange={() => {
                    setFilterValues(values => {
                      const { isOnlyOwn: isOnlySelf, query: currentQuery } = values
                      return { ...values, isOnlyOwn: !isOnlySelf, query: !isOnlyOwn ? '' : currentQuery }
                    })
                  }}
                />
                <label htmlFor="isOnlyOwn">{t('OnlyOwn')}</label>
              </div>
            </div>
            <div className={cx('search-date')}>
              <h4>{t('PublicationDate')}</h4>
              <div className={cx('between-date')}>
                <input
                  className={cx('public-date')}
                  type="date"
                  name="public-start"
                  value={startDate}
                  onChange={({ target: { value: startPublicDate } }) => {
                    setFilterValues(values => ({ ...values, startPublicDate }))
                  }}
                  min="2018-01-01"
                  max={endDate}
                />
                <input
                  className={cx('public-date')}
                  type="date"
                  name="public-end"
                  value={endDate}
                  onChange={({ target: { value: endPublicDate } }) => {
                    setFilterValues(values => ({ ...values, endPublicDate }))
                  }}
                  min="2018-01-01"
                  max={endDate}
                />
              </div>
              <div className={cx('search-intervals')}>
                <button
                  type="button"
                  className={cx('search-interval')}
                  onClick={() => {
                    intervalDateHandler(1, 1)
                  }}
                >
                  {t('Yesterday')}
                </button>
                <button
                  type="button"
                  className={cx('search-interval')}
                  onClick={() => {
                    intervalDateHandler(0)
                  }}
                >
                  {t('Today')}
                </button>
                <button
                  type="button"
                  className={cx('search-interval')}
                  onClick={() => {
                    intervalDateHandler(7)
                  }}
                >
                  {t('ThisWeek')}
                </button>
                <button
                  type="button"
                  className={cx('search-interval')}
                  onClick={() => {
                    intervalDateHandler(30)
                  }}
                >
                  {t('ThisMonth')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default ReportFilters
