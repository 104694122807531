import React from 'react'

const WeekDay = ({ label, title }) => {
  return (
    <div aria-label={label} className="weekDayCustom">
      {title}
    </div>
  )
}

export default WeekDay
