import React from 'react'
import './custom-date-picker.css'
import Month from './month'

const CustomDatePicker = ({
  reviewEntryDto,
  dateNow,
  onCloseMainModal,
  setCurrentListTimeEntryDto,
  setDay,
  setMonth,
  setRecordedDay,
}) => {
  return (
    <div className="datepickerContainer">
      <div className="datepickerContainerTitle">
        <Month
          setRecordedDay={setRecordedDay}
          setMonth={setMonth}
          setDay={setDay}
          setCurrentListTimeEntryDto={setCurrentListTimeEntryDto}
          reviewEntryDto={reviewEntryDto}
          dateNow={dateNow}
          onCloseMainModal={onCloseMainModal}
        />
      </div>
    </div>
  )
}

export default CustomDatePicker
