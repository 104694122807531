import ApiService from '../api-service'
import { AuthService } from '../auth-service'
import { serverHost } from '../../config'

const Url = {
  ROOT: `${serverHost}/api/user/navigation`,
  BY_CHAPTER_ID: (chapterId: number) => `chapter/${chapterId}`,
}

const requestNavigationController = <T>(url: string, init: RequestInit = {}): Promise<T> => {
  const options: RequestInit = {
    ...init,
    headers: {
      ...init.headers,
      ...AuthService.authHeaders(),
    } as Headers,
  }

  return ApiService.request(`${Url.ROOT}/${url}`, options)
}

class UserNavigationService {
  static getChapterNavigationInfoById(chapterId: number) {
    return requestNavigationController<ChapterNavigation>(Url.BY_CHAPTER_ID(chapterId))
  }
}

type ChapterNavigation = {
  currentModuleChapterCount: number
  prevModuleChapterCount: number
  moduleCount: number
  taskCountInPrevChapter: number
  nextChapterBlocked: boolean
}

export default UserNavigationService
