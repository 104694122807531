import { StudentGappingAnswerItemDto } from 'src/features/Course/models/service/Task/GappingTaskDto'

const mapGappingValuesToStudentAnswers = (values: Record<number, string>): StudentGappingAnswerItemDto[] => {
  return Object.entries(values).map(([id, value]) => ({
    gappingItemId: +id,
    gappingText: value,
  }))
}

export default mapGappingValuesToStudentAnswers
