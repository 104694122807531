import React, { ChangeEvent, FC, useEffect, useState, Dispatch } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { store } from 'react-notifications-component'
import { Button } from 'react-bootstrap'
import cn from 'classnames'
import { MentorCheckTaskDto } from '../../../../models/service/Task/MentorCheckTaskDto'
import { studentErrorNotyTemplate, studentSuccessNotyTemplate } from '../../../../../../config'
import Spinner from '../../../../../../components/spinner'
import AlertDanger from '../../../../../../components/pages/student/course-page/task-block/alert-danger'
import TaskDescription from '../../../../../../components/pages/student/course-page/task-block/task-description'
import FakePageCheckPredProjectModal from '../../../../../../components/pages/fake-page/fake-page-check-predproject-modal/fake-page-check-predproject-modal'
import { generateAllAvailableTaskStatus } from '../../../../utils/formatterStatusTasks'
import { AnswerStatusDto } from '../../../../models/service/Task/AnswerStatusDto'

interface IProps {
  handleCheckRequest: () => void
  solved: boolean
  checked: boolean
  statusPage: string
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<MentorCheckTaskDto>
  mentorCheckTask: MentorCheckTaskDto
  onSolveTask: any // (answer: string) => Promise<string>
  postSolveInterceptor: any // (
  onCheckTask?: any // используется для фейковой страницы, внутри фейковый сервис - не типизирован
  isPermittedToCheckCallback: () => boolean
  isAnswerAvailable: boolean
  solutionsLink: string
}

const MentorCheckTask: FC<IProps> = ({
  handleCheckRequest,
  solved,
  checked,
  statusPage,
  loadTask,
  mentorCheckTask,
  onSolveTask,
  postSolveInterceptor,
  onCheckTask,
  isPermittedToCheckCallback,
  isAnswerAvailable,
  solutionsLink,
}) => {
  const dispatch = useDispatch()
  // eslint-disable-next-line id-length
  const { t } = useTranslation()
  const [newAnswer, setNewAnswer] = useState<string>(``)
  const [solvingTask, setSolvingTask] = useState<boolean>(false)
  const [valid, setValid] = useState<boolean>(true)
  const [taskLoaded, setTaskLoaded] = useState<boolean>(true)
  const [errorLink, setErrorLink] = useState<boolean>(false)
  const [errorMassage, setErrorMassage] = useState<string>('')
  const [mentorComments, setMentorComments] = useState<string>('')
  const [lastActionIsRight, setLastActionIsRight] = useState<boolean>(false)
  const [lastActionIsWrong, setLastActionIsWrong] = useState<boolean>(false)
  const [right, setRight] = useState<boolean>(false)
  const [lastAnswerId, setLastAnswerId] = useState<number>()
  const [modalShowed, setModalShowed] = useState<boolean>(false)

  const [message, setMessage] = useState<string | null>('')
  const [mentorCheckResult, setMentorCheckResult] = useState<string>('')
  const [solutionSent, setSolutionSent] = useState<boolean>(false)

  let allTaskStatus = generateAllAvailableTaskStatus(mentorCheckTask.answerStatus)
  let isReseated = allTaskStatus[AnswerStatusDto.ANSWER_RESET]
  const answerIsRight = allTaskStatus[AnswerStatusDto.LAST_ANSWER_RIGHT]
  const answerIsWrong = allTaskStatus[AnswerStatusDto.LAST_ANSWER_WRONG]

  useEffect(() => {
    if (mentorCheckTask.answerStatus) {
      setTaskLoaded(false)
      setLastActionIsRight(answerIsRight!)
      setLastActionIsWrong(answerIsWrong!)
    }
  }, [])

  // TODO: loadTask being called when reset or submit actions happened to refresh ui but it used old api
  //  Not sure about support this method in V2 version thus removed logic related with that

  const loadMentorCheckTask = (interceptor?: any) => Promise.resolve(mentorCheckTask)

  const updateComments = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMentorComments(event.target.value)
  }

  const getMentorCheckResult = (checkResult: string) => {
    let mentorCheckResultLocal = checkResult
    if (!mentorCheckResultLocal || mentorCheckResultLocal === '') {
      if (solved) {
        if (checked) {
          mentorCheckResultLocal = t('MentorCheckingSuccess')
        } else {
          mentorCheckResultLocal = t('MentorChecking')
        }
      } else if (checked) {
        mentorCheckResultLocal = t('MentorCheckingFailure')
      }
    }
    return mentorCheckResultLocal
  }

  const solveMentorCheckTask = () => {
    const { isSolved: previousSolveStatus } = mentorCheckTask
    setErrorLink(false)
    onSolveTask(newAnswer)
      .then(() => {
        handleCheckRequest()
        return loadMentorCheckTask()
      })
      .then((task: MentorCheckTaskDto) => {
        postSolveInterceptor(previousSolveStatus, task.isSolved, task.taskPoints, dispatch)
        allTaskStatus = generateAllAvailableTaskStatus(task.answerStatus)
        isReseated = allTaskStatus[AnswerStatusDto.ANSWER_RESET]

        setMentorCheckResult(getMentorCheckResult(task.result))
        setSolutionSent(true)
      })
      .catch((err: any) => {
        const { code, text } = err
        if (code === 400) {
          setSolutionSent(false)
          setErrorLink(true)
          setErrorMassage(text)
          setSolvingTask(false)
        }
      })
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewAnswer(event.target.value)
  }

  const sendSolution = () => {
    onCheckTask(mentorComments, lastAnswerId, right)
      .then(() => {
        setModalShowed(false)
        store.addNotification({
          ...studentSuccessNotyTemplate,
          message: `Результат проверки успешно сохранен`,
        })
        loadMentorCheckTask()
      })
      .catch(() => {
        store.addNotification({
          ...studentErrorNotyTemplate,
          message: `Во время сохранения результата проверки произошла ошибка`,
        })
        loadMentorCheckTask()
      })
  }

  useEffect(() => {
    if (mentorCheckTask) {
      const { answer } = mentorCheckTask
      const chooseAnswer = isReseated ? `` : answer || ``
      setNewAnswer(chooseAnswer)
    }
  }, [mentorCheckTask])

  useEffect(() => {
    if (mentorCheckTask) {
      if (solvingTask) {
        setSolvingTask(false)
      }
      const { answerId, result } = mentorCheckTask
      setTaskLoaded(false)
      if (result === null) {
        setMentorComments('')
      } else {
        setMentorComments(result)
      }
      setLastAnswerId(answerId)
    }
  }, [mentorCheckTask])

  useEffect(() => {
    if (!solvingTask) {
      return
    }
    const match = newAnswer.match(/(http:|https:|ftp:)\/\/[^\s\n]+/g)
    if (!match || match.length !== 1 || match[0] !== newAnswer) {
      setSolvingTask(false)
      setValid(false)
      return
    }
    setValid(true)
    solveMentorCheckTask()
  }, [solvingTask])

  useEffect(() => {
    setMentorCheckResult(getMentorCheckResult(mentorCheckTask.result))
  }, [lastActionIsWrong, lastActionIsRight, solved, checked])

  if (taskLoaded || mentorCheckTask === null) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  const onClose = () => {
    setModalShowed(false)
  }

  const canBeChecked = isPermittedToCheckCallback()
  const submitButtonDisabled = newAnswer.length === 0

  return (
    <div className="task-content">
      <div className="step-content-head">{t('TheTaskVerifiedByTheMentor')}</div>
      {mentorCheckTask && mentorCheckTask.description && <TaskDescription description={mentorCheckTask.description} />}
      {statusPage !== 'fake' && (
        <>
          <input
            type="text"
            className={cn({
              'lesson-input': true,
              'on-check': solved && !checked,
              success: lastActionIsRight,
              error: lastActionIsWrong,
            })}
            style={{ color: `black` }}
            name="lesson-answer"
            onChange={handleChange}
            placeholder={t('EnterYourLink')}
            value={newAnswer || ''}
            disabled={lastActionIsRight || (solved && !checked) || solutionSent}
          />
          {!valid && <AlertDanger description={t('LinkValidationError')} />}
          {errorLink && <AlertDanger description={errorMassage} />}
          {/* eslint-disable-next-line */}
          {(solved && !checked || checked || solutionSent) && (
            <div className="compile-result">
              <div className="compile-title-wrap">
                <div className="compile-title">{t('MentorCheckResult')}</div>
              </div>
              <div className="terminal-result">
                <samp>
                  <p className="mentor-check-task-message">
                    {solved && !checked ? `Решение находится на проверке` : mentorCheckResult}
                  </p>
                </samp>
              </div>
            </div>
          )}
          <div className="lesson-result-row">
            {checked && (
              <div
                className={cn({
                  'lesson-input': true,
                  success: lastActionIsRight,
                  error: lastActionIsWrong,
                })}
              >
                {lastActionIsRight ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />}
                {lastActionIsRight && checked ? (
                  <span>{t('TheRightSolution')}</span>
                ) : (
                  <span>{t('InvalidSolutionCheckAgain')}</span>
                )}
              </div>
            )}
            <div className="lesson-result-right-wrap">
              {solvingTask ? (
                <button type="button" disabled className="send-result-btn" style={{ cursor: 'pointer' }}>
                  {t('SubmittingSolution')}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => setSolvingTask(true)}
                  className="send-result-btn"
                  disabled={submitButtonDisabled || (solved && !checked) || lastActionIsRight || solutionSent}
                >
                  {t('SubmitSolution')}
                </button>
              )}
            </div>
          </div>
        </>
      )}
      {statusPage === 'fake' && newAnswer && (
        <>
          {isAnswerAvailable && (
            <>
              <label htmlFor="mentor-link">Ссылка на репозиторий:</label>
              <div className="fake-page-mentor-link">
                <a href={newAnswer} target="_blank nooffer">
                  {newAnswer}
                </a>
              </div>
            </>
          )}
          <div className="mentor-check-form">
            {canBeChecked && (
              <>
                <textarea
                  id="mentor-comment"
                  className="form-control"
                  placeholder="Комментарий к задаче"
                  rows={15}
                  onChange={updateComments}
                  value={mentorComments}
                />
                <div className="right">
                  <Button className="mt-2" variant="success" onClick={() => setModalShowed(true)} size="lg">
                    Сохранить результат проверки
                  </Button>
                </div>
              </>
            )}
          </div>
          {isAnswerAvailable && (
            <div className="allComments">
              <Link to={solutionsLink} className="history-link">
                {t('AllComments')}
              </Link>
            </div>
          )}
          {canBeChecked && (
            <FakePageCheckPredProjectModal
              modalShowed={modalShowed}
              onClose={onClose}
              sendSolution={sendSolution}
              right={right}
              setRight={setRight}
              lastActionIsRight={lastActionIsRight}
            />
          )}
        </>
      )}
      {canBeChecked && !newAnswer && <div className="no-solution-history">Не было попыток решить задачу</div>}
    </div>
  )
}

export default MentorCheckTask
