import React, { useEffect, useState } from 'react'
import date from 'date-and-time'
import 'date-and-time/locale/ru'
import CalendarHeatmap from 'react-calendar-heatmap'
import { useTranslation, withTranslation } from 'react-i18next'
import { useReactAlert } from 'src/hooks/useReactAlert'
import StudentCourseService from '../../../../services/student-services/student-course-service'
import CourseElement from '../components/course-element/course-element'
import QuoteBlock from '../components/quote-block'
import StatisticModal from './statistic-modal/statistic-modal'
import StudentProfileService from '../../../../services/student-services/student-profile-service'
import FakeOpenStudentCourseService from '../../../../services/fake-page-services/open/fake-open-student-course-service'
import FakeOpenStudentProfileService from '../../../../services/fake-page-services/open/fake-open-student-profile-service'
import { AuthService } from '../../../../services/auth-service'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'
import { convertResponseImageUrl } from '../../../../utils/convertResponseImageUrl'
import 'react-calendar-heatmap/dist/styles.css'

date.locale('ru')
const studentProfileService = new StudentProfileService()
const fakeOpenStudentProfileService = new FakeOpenStudentProfileService()
const fakeOpenStudentCourseService = new FakeOpenStudentCourseService()
const studentCourseService = new StudentCourseService()

const CoursePercentBlock = ({ course: { name, prevCoursePercent, available } }) => (
  <div className={`progress-title progress-${prevCoursePercent} ${available ? 'active' : 'nactive'}`}>
    <span className="num">{prevCoursePercent}%</span>
    <span className="text">{name.toUpperCase()}</span>
  </div>
)

const ProgressBar = () => {
  return (
    <svg width="25px" height="35px" viewBox="0 0 25 35" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Welcome" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="progress-arrow"
          transform="translate(-781.000000, -426.000000)"
          fill="#008B8C"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <polygon
            id="Fill-2"
            points="783 434.125932 792.411645 443.615 783 453.104068 789.07595 459.23 804.566 443.615 789.07595 428"
          />
        </g>
      </g>
    </svg>
  )
}

const StatisticPage = ({ statusPage = '', match: { params: { studentId = -1 } = {} } = {} }) => {
  const { catchErrorAlert } = useReactAlert()
  const [availableCourses, setAvailableCourses] = useState([])
  const [lastCourse, setLastCourse] = useState({})
  const [lastCoursePic, setLastCoursePic] = useState('')
  const [activity, setActivity] = useState([])
  const [days, setDays] = useState({})
  const [topics, setTopics] = useState([])
  const [topicsCount, setTopicsCount] = useState(0)
  const [modalShowed, setModalShowed] = useState(false)
  const [currentUserId, setCurrentUserId] = useState()
  // eslint-disable-next-line id-length
  const { t } = useTranslation()

  useEffect(() => {
    setCurrentUserId(AuthService.currentUserValue().id)
  }, [])

  useEffect(() => {
    if (statusPage === 'fake' && AuthService.currentUserValue().role.name === RoleEnum.PAY_STUDENT) {
      studentCourseService.getAvailableCourses(currentUserId).then(items => {
        const courseOfCurrentUser = items.find(element => element.id === lastCourse.id)
        lastCourse.available = courseOfCurrentUser && courseOfCurrentUser.available
      })
    }
  }, [currentUserId, lastCourse])

  useEffect(() => {
    const currentService = statusPage !== 'fake' ? studentCourseService : fakeOpenStudentCourseService
    const currentProfileService = statusPage !== 'fake' ? studentProfileService : fakeOpenStudentProfileService
    const serviceArgs = statusPage !== 'fake' ? [] : [studentId]
    currentService.getAvailableCourses(...serviceArgs).then(last => setAvailableCourses(last))
    currentService
      .getLastCourse(...serviceArgs)
      .then(newLastCourse => {
        setLastCourse(newLastCourse)
        setLastCoursePic(convertResponseImageUrl(newLastCourse.coursePicUrl))
      })
      .catch(err => catchErrorAlert(err))
    currentProfileService.getActivity(...serviceArgs).then(newActivity => setActivity(newActivity))
    currentProfileService.getDaysWithoutPause(...serviceArgs).then(newDays => setDays(newDays))
    currentProfileService.getTopics(...serviceArgs).then(newTopics => setTopics(newTopics))
    currentProfileService.getTopicsCount(...serviceArgs).then(newTopicsCount => setTopicsCount(newTopicsCount))
  }, [])

  const months = [
    `${t('January')}`,
    `${t('February')}`,
    `${t('March')}`,
    `${t('April')}`,
    `${t('May')}`,
    `${t('June')}`,
    `${t('July')}`,
    `${t('August')}`,
    `${t('September')}`,
    `${t('October')}`,
    `${t('November')}`,
    `${t('December')}`,
  ]

  const { currentDaysWithoutPause, maxDaysWithoutPause } = days
  const courses = [
    ...availableCourses,
    {
      name: `${t('TeamProject')}`,
      prevCoursePercent: 88,
      available: false,
      position: 5,
    },
    {
      name: `${t('JobInterviewSummary')}`,
      prevCoursePercent: 95,
      available: false,
      position: 6,
    },
    {
      name: `${t('WorkInCompany')}`,
      prevCoursePercent: 100,
      available: false,
      position: 7,
    },
  ]
  const { percent = 0, prevCoursePercent = 0, coursePoints = 1, studentPoints = 0 } = lastCourse
  const currentStudentPercent = Math.floor(
    ((percent - prevCoursePercent) / coursePoints) * studentPoints + prevCoursePercent
  )
  // eslint-disable-next-line prefer-spread
  const maxVal = Math.max.apply(
    Math,
    activity.map(({ count }) => count)
  )
  const percentLastCourse = coursePoints === 0 ? 0 : Math.floor((100 * studentPoints) / coursePoints)
  const step = 100 / (courses.length - 1)
  const relative = (step / 100) * percentLastCourse
  const percentByPos = step * (lastCourse.position - 1) + relative
  const prevLastCourse = courses[lastCourse.position - 2]
  const nowDate = new Date()
  const start = `${nowDate.getFullYear() - 1}-${nowDate.getMonth() + 1}-01`
  const end = `${nowDate.getFullYear()}-${nowDate.getMonth() + 1}-${nowDate.getDate()}`
  let progressClass
  if (prevLastCourse) {
    progressClass = `progress-${courses[lastCourse.position - 2].prevCoursePercent}`
  } else {
    progressClass = 'progress-0'
  }
  document.title = t('Statistics')
  return (
    <>
      <div style={{ marginTop: '64px' }} />
      <QuoteBlock />
      <div className="profile-page-wrap">
        <div className="container">
          <div className="profile-container">
            {/*{statusPage !== 'fake' && (*/}
            {/*  <div className="profile-progress-wrap">*/}
            {/*    <div className="profile-progress-head">*/}
            {/*      {t('ProgressToEmployment')}: {currentStudentPercent}%*/}
            {/*    </div>*/}
            {/*    <div className="profile-progress-bar" id="progress-bar">*/}
            {/*      <div className={`progress-fill ${progressClass}`} style={{ width: `${percentByPos}%` }}>*/}
            {/*        <ProgressBar />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div id="progress_titles" className="progress-titles-wrap">*/}
            {/*      {courses.map(course => (*/}
            {/*        <CoursePercentBlock course={course} key={`course_block_position_${course.position}`} />*/}
            {/*      ))}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}
            <div className="profile-bottom-wrap">
              <div className="profile-left-wrap">
                <div className="bottom-stats-wrap">
                  <div className="stats-block">
                    <span id="without_pause" className="num">
                      {currentDaysWithoutPause}
                    </span>
                    {t('DaysWithoutABreak')}
                  </div>

                  <div className="stats-block">
                    <span id="without_pause_max" className="num">
                      {maxDaysWithoutPause}
                    </span>
                    {t('DaysWithoutABreakMax')}
                  </div>

                  <div className="stats-block">
                    <span id="topic_count" className="num">
                      {topicsCount}
                    </span>
                    {t('TopicsMastered')}
                    <br />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="stats-detail-link" onClick={() => setModalShowed(true)}>
                      {t('MoreDetails')}
                    </a>
                  </div>
                </div>

                <div className="profile-activity">
                  <div className="profile-activity-head-wrap">
                    <div className="profile-activity-head">{t('YourActivity')}:</div>
                    <div className="profile-activity-sorter">
                      <div id="year_btn" className="activity-sort active">
                        {t('Year')}
                      </div>
                    </div>
                  </div>
                  <div className="profile-activity-graph-year">
                    <CalendarHeatmap
                      startDate={new Date(start)}
                      endDate={new Date(end)}
                      values={activity}
                      monthLabels={months}
                      titleForValue={value => {
                        return value
                          ? `${t('OfSolutions')} ${value.count} ${date.format(
                              new Date(value.date),
                              'dd., DD MMM YYYY',
                              false
                            )}`
                          : ''
                      }}
                      classForValue={value => {
                        if (!value) {
                          return 'color-github-0'
                        }
                        const nStep = value.count / (maxVal / 3)
                        return `color-gitlab-${Math.floor(nStep) + 1}`
                      }}
                    />
                  </div>
                  <div className="profile-activity-graph-month" />
                  <div className="profile-activity-graph-week" />
                </div>
              </div>
              <div className="profile-right-wrap">
                <div className="current-course">
                  <div className="current-course-head">{t('CurrentCourse')}:</div>
                  <CourseElement
                    course={lastCourse}
                    pic={lastCoursePic}
                    studentId={studentId}
                    statusPage={statusPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StatisticModal topics={topics} modalShowed={modalShowed} onClose={() => setModalShowed(false)} />
    </>
  )
}

export default withTranslation()(StatisticPage)
